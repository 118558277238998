import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { trigger,  state,  style, transition, animate } from '@angular/animations';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { Title } from '@angular/platform-browser';
import { MenuService } from '../menu/menu.service';
import { ActivatedRoute, Router, ActivatedRouteSnapshot, UrlSegment, NavigationEnd } from "@angular/router";
import { UserserviceService } from '../../../../app/pages/Services/userservice.service'
import { GeneralService } from '../../../../app/pages/Services/general.service'

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ MenuService ],
  animations: [
    trigger('showInfo', [
      state('1' , style({ transform: 'rotate(180deg)' })),
      state('0', style({ transform: 'rotate(0deg)' })),
      transition('1 => 0', animate('400ms')),
      transition('0 => 1', animate('400ms'))
    ])
  ]
})
export class HeaderComponent implements OnInit {
  RoleId: any;
  MenuId: string;
  RoleName: any;
  LoggedinUserName: any;
  LoginDetails: any;
  public pageTitle: string; ID: any;
  public breadcrumbs: {
    name: string;
    url: string
  }[] = [];
  public showHorizontalMenu:boolean = true; 
  public showInfoContent: boolean = false;
  public Pinn: boolean;

  public settings: Settings;
  public menuItems:Array<any>;
  constructor(public user: UserserviceService, public appSettings: AppSettings, public menuService: MenuService, public title: Title, public router: Router,
    public activatedRoute: ActivatedRoute, public generalService: GeneralService) {

    this.MenuId = localStorage.getItem('MenuId')


    this.settings = this.appSettings.settings;
    this.LoginDetails = JSON.parse(localStorage.getItem('LoginDetails'))
    this.RoleId = this.LoginDetails.logindetails.RoleId

    this.LoggedinUserName = this.LoginDetails.logindetails.FirstName;
    if (this.LoginDetails.sideMenus != null) {
      if (this.LoginDetails.sideMenus.length > 0) {
        this.RoleName = this.LoginDetails.sideMenus[0].Role;

      }
      else {
        this.RoleName = "Admin"
      }

    }
    else {
     // this.RoleName="Admin"
    }

    this.menuItems = this.menuService.getHorizontalMenuItems();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.breadcrumbs = [];
        this.parseRoute(this.router.routerState.snapshot.root);
        this.pageTitle = "";
        this.breadcrumbs.forEach(breadcrumb => {
          this.pageTitle += ' > ' + breadcrumb.name;
        })
        this.title.setTitle(this.settings.name + this.pageTitle);
      }
    }) 
  }
  
  ngOnInit() {


   
    this.user.cast.subscribe(count => this.ID = count);
    

    if(window.innerWidth <= 768) 
      this.showHorizontalMenu = false;
  }


  public closeSubMenus(){
    let menu = document.querySelector("#menu0"); 
    if(menu){
      for (let i = 0; i < menu.children.length; i++) {
          let child = menu.children[i].children[1];
          if(child){          
              if(child.classList.contains('show')){            
                child.classList.remove('show');
                menu.children[i].children[0].classList.add('collapsed'); 
              }             
          }
      }
    }
  }

  @HostListener('window:resize')
  public onWindowResize():void {
     if(window.innerWidth <= 768){
        this.showHorizontalMenu = false;
     }      
      else{
        this.showHorizontalMenu = true;
      }
  }


  parseRoute(node: ActivatedRouteSnapshot) {
    if (node.data['breadcrumb']) {
      if (node.url.length) {
        let urlSegments: UrlSegment[] = [];
        node.pathFromRoot.forEach(routerState => {
          urlSegments = urlSegments.concat(routerState.url);
        });
        let url = urlSegments.map(urlSegment => {
          return urlSegment.path;
        }).join('/');
        this.breadcrumbs.push({
          name: node.data['breadcrumb'],
          url: '/' + url
        })
      }
    }
    if (node.firstChild) {
      this.parseRoute(node.firstChild);
    }
  }





  



}
