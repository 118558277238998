<div class="container-fluid" style="padding:0">
  <div class="row head">
    <div class="col-lg-4" style="padding:0">
      Quality Audits
      <button type="button" style="" class="btn" *ngIf="!Pinned" (click)="Pined()"><img class="tableheadericons" src="https://img.icons8.com/dotty/80/000000/pin3.png" /> Pin</button>
      <button type="button" style="" class="btn" *ngIf="Pinned" (click)="UnPin()"><img class="tableheadericons" src="https://img.icons8.com/dusk/64/000000/pin3.png" /> Un Pin</button>

    </div>
    <div class="col-lg-2" style="padding:0">
      

      <!--<div *ngIf="!Pinned" (click)="Pined()">
    <img src="https://img.icons8.com/dotty/80/000000/pin3.png" class="pin" />
  </div>
  <div *ngIf="Pinned" (click)="UnPin()">
    <img src="https://img.icons8.com/dusk/64/000000/pin3.png" class="pin" />
  </div>-->
    </div>
    <div class="col-lg-6" style="text-align:right;font-size:12px;letter-spacing:0.2px">
      <!--This Quarter 4-1-2020 To 6-30-2020-->
    </div>
  </div>
  <br />


</div>


<style>
  .card_1 {
    border: 1px solid #c1c1c175;
    /* padding: 35px; */
    height: 153px;
    margin-bottom: 15px;
    font-size: 13px;
    padding: 19% 0% 16% 0%;
    text-align: center;
  }

  .head {
    background: white;
    padding: 18px;
    box-shadow: 0 0px 3px rgba(0,0,0,0.2), 0 1px 0px rgb(0 0 0 / 5%);
  }

  .myc {
    margin: 5% 0%;
    font-size: 13px;
    letter-spacing: 0.2px;
    font-weight: bold;
  }
</style>
