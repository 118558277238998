
import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../Services/general.service'
import { Router, ActivatedRoute } from '@angular/router';
import { MenuService } from '../../../theme/components/menu/menu.service';
import { AppSettings } from '../../../app.settings';
import Swal from 'sweetalert2';
import { Settings } from '../../../app.settings.model';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { formatDate } from '@angular/common';
@Component({
  selector: 'app-inspection-master',
  templateUrl: './inspection-master.component.html',
  styleUrls: ['./inspection-master.component.scss'],
  providers: [DatePipe, MenuService]
})
export class InspectionMasterComponent implements OnInit {
  todaydate: any;
  Status: any;
  Grid: boolean;
  inspectionData: any;
  cols: { field: string; header: string; width: string; }[];
  LoginDetails: any;
  myDate = new Date();
  Pinned: boolean;
  PinId: any;
  MenuId: any;
  public settings: Settings;
  currentPageRoute: any;
  constructor(public generalService: GeneralService, public menuService: MenuService, private datePipe: DatePipe, public toaster: ToastrService, public appSettings: AppSettings, public router: Router) {
    this.todaydate = formatDate(this.myDate, 'yyyy-MM-dd hh:mm:ss a', 'en-US', '+0530');
    this.LoginDetails = JSON.parse(localStorage.getItem('LoginDetails'));
    this.settings = this.appSettings.settings;
    this.MenuId = 0;
    this.router.events.subscribe((val) => {

      this.currentPageRoute = this.router.url; // Current page route

    });
  }

  ngOnInit(): void {
    this.GetPinned();
    this.getAllinspections();
  }
  //=======print====//
  onPrint() {
    window.print();
  }
  //Get Pinned
  Pined() {
    debugger
    this.Pinned = true;

    let Array = [];
    Array.push({
      MenuId: this.MenuId, RouterLink: this.currentPageRoute, UserId: this.LoginDetails.logindetails.Id
    })
    var UploadFile = new FormData();
    UploadFile.append("Pinned", JSON.stringify(Array));
    var url = 'api/Values/AddPinned';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data == "SUCCESS") {
        debugger
        this.GetPinned();
        var UploadFile = new FormData();
        UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
        //UploadFile.append("flag", '1');
        var url = 'api/Values/GetSideMenusWithRecentPinned';
        this.generalService.PostData(url, UploadFile).then((data1: any) => {
          debugger
          var Details = this.generalService.SIdeMenus(data1);
          this.menuService.RefreshMenu(Details, this.settings.theme.menu);
          debugger
        });

      }

    });
  }


  UnPin() {
    debugger

    var UploadFile = new FormData();
    UploadFile.append("PinId", this.PinId);
    UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
    var url = 'api/Values/DeletePinned';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data == "SUCCESS") {
        debugger
        this.Pinned = false;
        var UploadFile = new FormData();
        UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
        //UploadFile.append("flag", '1');
        var url = 'api/Values/GetSideMenusWithRecentPinned';
        this.generalService.PostData(url, UploadFile).then((data1: any) => {
          debugger
          var Details = this.generalService.SIdeMenus(data1);
          this.menuService.RefreshMenu(Details, this.settings.theme.menu);
          debugger
        });
      }
    });
  }

  GetPinned() {
    debugger
    var UploadFile = new FormData();
    UploadFile.append("MenuId", this.MenuId);
    UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
    UploadFile.append("RouterLink", this.currentPageRoute);

    var url = 'api/Values/GetPinned';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data.MSG == "EXISTS") {
        debugger
        this.PinId = data.PinId;
        this.Pinned = true;
      }
      else if (data.MSG == "NOT EXISTS") {
        this.Pinned = false;
      }
    });
  }
  getAllinspections() {
    var url = "api/Master/GetInspectionDetails";
    this.generalService.GetData(url).then((data: any) => {
      if (data != null) {
        debugger
        var obj = Object.keys(data[0])
        this.cols = [
          { field: 'Id', header: 'Id', width: '150px' },
          { field: 'Project', header: 'ProjectName', width: '150px' },
          { field: 'ProjectCode', header: 'ProjectCode', width: '150px' },
          //{ field: 'RequestMethod', header: 'RequestMethod', width: '150px' },
          //{ field: 'RequestType', header: 'RequestType', width: '150px' },
          { field: 'SubProject', header:'SubProject',width:'150px' },
          { field: 'Activity', header: 'Activity', width: '150px' },
          { field: 'Task', header: 'Task', width: '150px' },
          { field: 'TemplateName', header: 'TemplateName', width: '150px' },
          { field: 'TemplateType', header: 'TemplateType', width: '150px' },
          { field: 'Finding', header: 'Finding', width: '150px' },
          { field: 'Requestor', header: 'Requestor', width: '150px' },
          { field: 'inspector', header: 'inspector', width: '150px' },
          { field: 'CreatedDate', header: 'CreatedDate', width: '150px' },
          { field: 'CreateBy', header: 'CreatedBy', width: '150px' },
          { field: 'ModifiedDate', header: 'ModifiedDate', width: '150px' },
          { field: 'ModifiBy', header: 'ModifiedBy', width: '150px' },
        ]
        this.inspectionData = data;
      }
    });
  }

  backform() {
    this.Grid = true;
  }
  refresh() {
    location.reload();
  }

  NewInspectionForm() {
    var i = this.inspectionData.length+1
    localStorage.setItem('Inspectioncount', this.inspectionData.length+1);
    sessionStorage.removeItem('InspectionDetails')
    this.router.navigateByUrl('/Inspection/NewInspection');
  }

  UpdateInspectionForm(rowdata) {
    debugger
    sessionStorage.removeItem('InspectionDetails')
    let arr = [];
    //let index = this.inspectionData.findIndex(a => a.Id == rowdata.Id)
    //var x: number = +this.inspectionData.length;
    //var y: number = +index
    //var z: any = x - (y + 1)
    localStorage.setItem('Inspectioncount', rowdata.Id);

    sessionStorage.setItem('InspectionDetails', JSON.stringify(rowdata));
    this.router.navigateByUrl('/Inspection/NewInspection');
  }
  onDelete(rowData) {
    Swal.fire({
      title: 'Are you sure to Delete  ?',
      //text: "",
      //type: 'warning',
      html: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      debugger
      if (result.value) {

        let Array = [];
        Array.push({ Id: rowData.Id,
        })
        var UploadFile = new FormData();
        UploadFile.append("inspectionId", JSON.stringify(Array));
        var url = 'api/Master/deleteInspections';
        this.generalService.PostData(url, UploadFile).then((data: any) => {

          if (data == "success") {
            this.getAllinspections();
            this.generalService.ShowAlert('SUCCESS', 'Inspection has been Deleted successfully', 'success');
            this.backform();
          }
          else {
            this.generalService.ShowAlert('WARNING', 'You can not delete this record because of it is having relation with another table', 'warning');
            this.backform();
          }
        });
      }
    })
  }
  changeStatus(rowData, status) {
    this.Status = status;
    let Array = [];
    Array.push({
      Id: rowData.Id, Status: this.Status, ModifiedBy: this.LoginDetails.logindetails.Id, ModifiedDate: this.todaydate
    })
    var UploadFile = new FormData();
    UploadFile.append("inspectionstatus", JSON.stringify(Array));
    var url = 'api/Master/Changesinspectionstatus';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      if (data != null) {
        this.getAllinspections();
        if (this.Status == true) {
          this.toaster.success('Status Activated Successfully', 'Success', {
            timeOut: 2000,
          });
        }
        else {
          this.toaster.warning('Status Dectivated Successfully', 'Success', {
            timeOut: 2000,
          });
        }
      }
    });
  }
}
