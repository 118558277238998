import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { MenuService } from '../menu/menu.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Menu } from '../menu/menu.model';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ MenuService ]
})
export class SidebarComponent implements OnInit {  
  open: boolean;
  public settings: Settings;
  public menuItems: Array<any>;
  SideMenus: any;
  LoginDetails: any;
  urlname: any;

  url: any;
  
  LodDetails: any;
  MenuId: any;
 
  SideMenusAry: any = [];
  MainSideMenusAry: any = [];
  constructor(public appSettings: AppSettings, public menuService: MenuService, public http: HttpClient ) {
      this.settings = this.appSettings.settings;
    this.menuItems = this.menuService.getVerticalMenuItems();
   
    this.LoginDetails = JSON.parse(localStorage.getItem('LoginDetails'))
    this.SideMenus = JSON.parse(localStorage.getItem('SideMenus'))
  }

  ngOnInit() {
    this.open = true;
    if(sessionStorage["userMenuItems"]) {
      let ids = JSON.parse(sessionStorage.getItem("userMenuItems"));
      let newArr = [];
      ids.forEach(id => {
        let newMenuItem = this.menuItems.filter(mail => mail.id == id);
        newArr.push(newMenuItem[0]);
      });
      this.menuItems = newArr; 
    }
  }

  public closeSubMenus() {
    this.open = false;
    debugger
    let menu = document.querySelector("#menu0");
    for (let i = 0; i < menu.children.length; i++) {
        let child = menu.children[i].children[1]; 
        if(child){
            if(child.classList.contains('show')){
              child.classList.remove('show');
              menu.children[i].children[0].classList.add('collapsed'); 
            }             
        }
    }
  }

  GetMenu(menuItems) {
   
    var ParentId = localStorage.getItem('ParentId')
    var menuId = localStorage.getItem('MenuId')

    if (menuId != '4') {

    debugger
    let Array = [];
    Array.push({
      MenuId: menuId, UserId: this.LoginDetails.logindetails.Id
    })
    return this.http.get('../assets/WebService.json').subscribe((data: any) => {
      var UploadFile = new FormData();
      UploadFile.append("Recent", JSON.stringify(Array));
      this.urlname = data.Webservice + '/api/Values/AddRecent';
      this.http.post(this.urlname, UploadFile).subscribe((data1: any) => {
        debugger
        if (data1 == "Exists") {
          debugger
          var UploadFile = new FormData();
          UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
          UploadFile.append("flag", '1');
          this.urlname = data.Webservice + '/api/Values/GetSideMenusWithRecentPinned';
          this.http.post(this.urlname, UploadFile).subscribe(data2 => {
            debugger
            this.LodDetails = data2;
            localStorage.removeItem('SideMenus')
            localStorage.setItem("SideMenus", JSON.stringify(this.LodDetails.sideMenus));
            this.SideMenus = JSON.parse(localStorage.getItem('SideMenus'));
            this.SideMenusAry = []
            this.MainSideMenusAry = []
            debugger
            for (var i = 0; i < this.SideMenus.length; i++) {
              this.SideMenusAry.push([new Menu(
                this.SideMenus[i].Id
                , this.SideMenus[i].Title
                , this.SideMenus[i].RouterLink
                , this.SideMenus[i].Href
                , this.SideMenus[i].Icon
                , this.SideMenus[i].Target
                , this.SideMenus[i].HasSubMenu
                , this.SideMenus[i].ParentId
              )])
            }
            debugger
            for (var i = 0; i < this.SideMenusAry.length; i++) {
              this.MainSideMenusAry.push(this.SideMenusAry[i][0])
            }
            debugger
            this.menuService.RefreshMenu(this.MainSideMenusAry, this.settings.theme.menu);
          });
        }

      });
    });
  
    }
  }

}
