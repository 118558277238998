import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserserviceService {
  totalvalue: any;

  private user = new BehaviorSubject<string>('0');

  cast = this.user.asObservable();

  constructor() {
    
   var ID = JSON.parse(localStorage.getItem("InnsceptionID"))
    if (ID != undefined || ID != "" || ID !== null) {
  this.user.next(ID)
}
else {
  this.user.next("0");
    }
  }

edituser(newuser) {
  
  this.user.next(newuser);
}
}
