import { Component, OnInit ,OnDestroy} from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
//import { GeneralService } from '../../pages/Services/general.service';
import { GeneralService } from '../../Services/general.service'
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { formatDate } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PrimeNGConfig } from 'primeng/api';
import { FilterMatchMode } from 'primeng/api';
import { TooltipModule } from 'primeng/tooltip';
import Swal from 'sweetalert2';

import { MenuService } from '../../../theme/components/menu/menu.service';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import {  GlobalConfig } from 'ngx-toastr';
declare var $;
@Component({
  selector: 'app-campany-status-master',
  templateUrl: './campany-status-master.component.html',
  styleUrls: ['./campany-status-master.component.scss'],
  providers: [DatePipe, MenuService]
})
export class CampanyStatusMasterComponent implements OnInit {
  CompanyTypedataforDropdown: any;


  products= [{
    code: '12345600',
    name: 'srikanth',
    inventoryStatus: 'Instock',
    price: 120
  }]
    cols1: { field: string; header: string; width: string; }[];
    editings: boolean=false;
  Product: any;
  LoginDetails: any;
  ModelTitle: string;
  ModelTitleCompanyType: string;
  ModelTitleCompany:string;
  templateoriginal: any;
  templateoriginalCompanyType: any;
  templateoriginalCompanyData: any;
  template: any;
  flag: any;
  CompanyStatusdata: any="";
  cols: any;
  addNew: boolean;
  editForm: boolean;
  activitytype: any;
  Id: any;
  public form: FormGroup;
  Grid: boolean = true;
  myDate = new Date();
  todaydate: any;
  CompanyTypeDropdown:any=0;
 CompanyTypedata:any;
  CompanyData:any;
  State:any;
  Status:any=0;
  CompanyName:any;
  CompanyAddress:any;
  POCName:any;
POCMobile:any;
POCEmail:any;
  CompanyStatusDropdown:any=0;
CompanyStatus:any;
CompanyType:any;
  MenuId:any;
  CompanyId: any;
  companyFlag: boolean = true;
  companyForm: boolean = false;
  products2 = [{
      code: 1,
      name: 'srikanth',
      inventoryStatus: 2,
      price: 123
  },
    {
      code: 2,
      name: 'ffsrikanth',
      inventoryStatus: 123,
      price: 100
    }]

  Pinned: boolean;
  PinId: any;
  MenuIdPin: any;
  public settings: Settings;
  currentPageRoute: any;

  constructor(public config: PrimeNGConfig,
    public menuService: MenuService, public appSettings: AppSettings, public router: Router,public toaster:ToastrService, public fb: FormBuilder,public route: ActivatedRoute,  public generalService: GeneralService, private datePipe: DatePipe)
  {
 this.config.filterMatchModeOptions = {
            text: [
                FilterMatchMode.STARTS_WITH,
                FilterMatchMode.CONTAINS,
                FilterMatchMode.NOT_CONTAINS,
                FilterMatchMode.ENDS_WITH,
                FilterMatchMode.EQUALS,
                FilterMatchMode.NOT_EQUALS,
                FilterMatchMode.LESS_THAN,
                FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
                FilterMatchMode.GREATER_THAN,
                FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
            ],
            numeric: [
                FilterMatchMode.EQUALS,
                FilterMatchMode.NOT_EQUALS,
                FilterMatchMode.LESS_THAN,
                FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
                FilterMatchMode.GREATER_THAN,
                FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
            ],
            date: [
                FilterMatchMode.BEFORE,
                FilterMatchMode.AFTER,
            ]
        }
 this.MenuId = route.snapshot.params['Id'];
 this.todaydate = formatDate(this.myDate, 'yyyy-MM-dd hh:mm:ss a', 'en-US', '+0530');
    this.LoginDetails = JSON.parse(localStorage.getItem('LoginDetails'))
    this.form = this.fb.group({
      activitytype: ['', Validators.required]
    });
    this.settings = this.appSettings.settings;
    this.MenuIdPin = 0;
    this.router.events.subscribe((val) => {

      this.currentPageRoute = this.router.url; // Current page route

    });

  }

  ngOnInit(): void
 {
      
    this.getCompanyType();
    this.getCompanyStatus();
      if(this.MenuId==1)
    {
      this.getCompany();
    }
    if(this.MenuId==2)
    {
    this.getCompanyStatus();
    }
    if(this.MenuId==3)
    {
    this.getCompanyType();
    }

    this.GetPinned();
  }

  onPrint() {
    window.print();
  }
handleChange(e) {
    var index = e.index;
    if(index==0)
    {
      this.getCompany();
    }
    if(index==1)
    {
    this.getCompanyStatus();
    }
    if(index==2)
    {
    this.getCompanyType();

    }
}
  Pined() {
    debugger
    this.Pinned = true;

    let Array = [];
    Array.push({
      MenuId: this.MenuIdPin, RouterLink: this.currentPageRoute, UserId: this.LoginDetails.logindetails.Id
    })
    var UploadFile = new FormData();
    UploadFile.append("Pinned", JSON.stringify(Array));
    var url = 'api/Values/AddPinned';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data == "SUCCESS") {
        debugger
        this.GetPinned();
        var UploadFile = new FormData();
        UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
        //UploadFile.append("flag", '1');
        var url = 'api/Values/GetSideMenusWithRecentPinned';
        this.generalService.PostData(url, UploadFile).then((data1: any) => {
          debugger
          var Details = this.generalService.SIdeMenus(data1);
          this.menuService.RefreshMenu(Details, this.settings.theme.menu);
          debugger
        });

      }

    });
  }


  UnPin() {
    debugger

    var UploadFile = new FormData();
    UploadFile.append("PinId", this.PinId);
    UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
    var url = 'api/Values/DeletePinned';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data == "SUCCESS") {
        debugger
        this.Pinned = false;
        var UploadFile = new FormData();
        UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
        //UploadFile.append("flag", '1');
        var url = 'api/Values/GetSideMenusWithRecentPinned';
        this.generalService.PostData(url, UploadFile).then((data1: any) => {
          debugger
          var Details = this.generalService.SIdeMenus(data1);
          this.menuService.RefreshMenu(Details, this.settings.theme.menu);
          debugger
        });
      }
    });
  }

  GetPinned() {
    debugger
    var UploadFile = new FormData();
    UploadFile.append("MenuId", this.MenuIdPin);
    UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
    UploadFile.append("RouterLink", this.currentPageRoute);

    var url = 'api/Values/GetPinned';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data.MSG == "EXISTS") {
        debugger
        this.PinId = data.PinId;
        this.Pinned = true;
      }
      else if (data.MSG == "NOT EXISTS") {
        this.Pinned = false;
      }
    });
  }
 getCompanyType() {
    var url = "api/Master/GetCompanyType";
    this.generalService.GetData(url).then((data: any) => {
      if (data != null) {
        var obj = Object.keys(data[0])
        this.cols = [
          { field: 'Id', header: 'Id', width: '150px' },
          { field: 'Status', header: 'Status', width: '150px' },
          { field: 'CompanyType', header: 'CompanyType', width: '150px' },
          { field: 'CreatedDate', header: 'CreatedDate', width: '150px' },
          { field: 'CreateBy', header: 'CreatedBy', width: '150px' },
          { field: 'ModifiedDate', header: 'ModifiedDate', width: '150px' },
          { field: 'ModifiBy', header: 'ModifiedBy', width: '150px' },
        ]
      data.forEach(
        companyType => (companyType.CreatedDate = new Date(companyType.CreatedDate))
        );
      //data.forEach(
      //  companyType => (companyType.ModifiedDate = new Date(companyType.ModifiedDate))
      //);
        //this.CompanyTypedata = data;
       this.CompanyTypedata = data.sort((a, b) => b.CreatedDate - a.CreatedDate);
        for (var i = 0; i < data.length; i++) {
          if (data[i].ModifiedDate != null && data[i].ModifiedDate != undefined && data[i].ModifiedDate != '') {
            this.CompanyTypedata[i].ModifiedDate = new Date(this.CompanyTypedata[i].ModifiedDate)
          }
       }       
        this.CompanyTypedataforDropdown = this.CompanyTypedata.filter(d => d.Status == true);       
        this.templateoriginalCompanyType = data;
      }
    }, err => {
      // this.spinner.hide();
      this.generalService.ShowAlert('ERROR', 'Something Went wrong', 'error');
    });
  }
getCompany()
{
  var url = "api/Values/GetCompanies";
    this.generalService.GetData(url).then((data: any) => {
      if (data != null) {
        var obj = Object.keys(data[0])
        this.cols = [
          { field: 'Id', header: 'Id', width: '150px' },
          { field: 'Status', header: 'Status', width: '150px' },
          { field: 'CompanyName', header: 'CompanyName', width: '150px' },
          { field: 'CompanyType', header: 'CompanyType', width: '150px' },
          { field: 'Address', header: 'Address', width: '150px' },
          { field: 'POCName', header: 'POCName', width: '150px' },
          { field: 'POCEmail', header: 'POCEmail', width: '150px' },
          { field: 'POCMobile', header:'POCMobile',width:'150px'},
          { field: 'CompanyStatus', header: 'CompanyStatus', width: '150px'},
          { field: 'CreatedDate', header: 'CreatedDate', width: '150px' },
          { field: 'CreateBy', header: 'CreatedBy', width: '150px' },
          { field: 'ModifiedDate', header: 'ModifiedDate', width: '150px' },
          { field: 'ModifiBy', header: 'ModifiedBy', width: '150px' },
        ]
        data.forEach(
          companyType => (companyType.CreatedDate = new Date(companyType.CreatedDate))
        );
        //data.forEach(
        //  companyType => (companyType.ModifiedDate = new Date(companyType.ModifiedDate))
        //);
        this.CompanyData = data.sort((a, b) => b.CreatedDate - a.CreatedDate)
        for (var i = 0; i < data.length; i++) {
          if (data[i].ModifiedDate != null && data[i].ModifiedDate != undefined && data[i].ModifiedDate != '') {
            this.CompanyData[i].ModifiedDate = new Date(this.CompanyData[i].ModifiedDate)
          }
        }

        this.CompanyData = data;
        this.templateoriginalCompanyData = data;
      }
    }, err => {
      // this.spinner.hide();
      this.generalService.ShowAlert('ERROR', 'Something Went wrong', 'error');
    });
}
  getCompanyStatus() {
    
    var url = "api/Master/GetCompanyStatus";
    this.generalService.GetData(url).then((data: any) => {
      if (data != null) {
        var obj = Object.keys(data[0])
        this.cols1 = [
          { field: 'Id', header: 'Id', width: '150px' },
          { field: 'Status', header: 'Status', width: '150px' },
          { field: 'CompanyStatus', header: 'CompanyStatus', width: '150px' },
          { field: 'CreatedDate', header: 'CreatedDate', width: '150px' },
          { field: 'CreateBy', header: 'CreatedBy', width: '150px' },
          { field: 'ModifiedDate', header: 'ModifiedDate', width: '150px' },
          { field: 'ModifiBy', header: 'ModifiedBy', width: '150px' },
        ]
        data.forEach(
          companyType => (companyType.CreatedDate = new Date(companyType.CreatedDate))
        );
        //data.forEach(
        //  companyType => (companyType.ModifiedDate = new Date(companyType.ModifiedDate))
        //);
        //this.CompanyStatusdata = data;
        this.CompanyStatusdata = data.sort((a, b) => b.CreatedDate - a.CreatedDate)
        for (var i = 0; i < data.length; i++) {
          if (data[i].ModifiedDate != null && data[i].ModifiedDate != undefined && data[i].ModifiedDate != '') {
            this.CompanyStatusdata[i].ModifiedDate = new Date(this.CompanyStatusdata[i].ModifiedDate)
          }
        }
       
        this.templateoriginal = data;

      }
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something Went wrong', 'error');
    });
  }
CompanyEdit(value)
{
      this.ModelTitleCompany="";
      this.CompanyName ="";
      this.CompanyTypeDropdown ="";
      this.CompanyStatusDropdown ="";
      this.CompanyAddress="";
      this.POCName = "";
  this.companyFlag = false;
  this.companyForm = true;
      this.ModelTitleCompany= "Edit Company";
      //$('#myModalCompany').modal('show');
     this.CompanyId=value.Id;
      this.CompanyName =value.CompanyName ;
      this.CompanyTypeDropdown =value.CompanyTypeId;
      this.CompanyStatusDropdown =value.CompanyStatusId;
      this.CompanyAddress=value.Address;
      this.POCName=value.POCName;
      this.POCEmail=value.POCEmail;
      this.POCMobile=value.POCMobile;

      this.Status=value.Status;

}
OpenAddFormCompany()
{
  
  this.CompanyName = "";
  this.CompanyType = "";
  this.CompanyStatus = "";
  this.CompanyAddress = "";
  this.POCName = "";
  this.POCEmail = "";
  this.POCMobile = "";
  this.companyFlag = true;
  this.companyForm = true;
    this.ModelTitleCompany="";
    this.getCompanyType();
    this.getCompanyStatus();
    this.ModelTitleCompany= "Add Company";
    //$('#myModalCompany').modal('show');
   // this.Grid = false;
   // this.CompanyType = "";
    this.flag = "1";//for adding
}
  OpenAddFormType() {
    
    this.ModelTitleCompanyType = "Add CompanyType";
    $('#myModalCompanyType').modal('show');
   // this.Grid = false;
   // this.CompanyType = "";
    this.flag = "1";//for adding
    //this.addNew = true;
    //this.editForm = false;

  }
  back() {
    this.companyForm = false;

  }
  OpenAddForm() {
    
    this.ModelTitle = "Add CompanyStatus";
    $('#myModal').modal('show');
   // this.Grid = false;
   // this.CompanyStatus = "";
    this.flag = "1";//for adding
    //this.addNew = true;
    //this.editForm = false;
  }

  onRowEditInit(product) {
   // this.CompanyStatusdata = this.CompanyStatusdata.filter(a => a.Id == product.Id);
  }


TextBoxChangeCompanyType(item, key)
{
 
    let index: number = this.CompanyTypedata.findIndex(t => t.Id == item.Id);
    let index1: number = this.templateoriginalCompanyType.findIndex(t => t.Id == item.Id);
    if (index > -1) {
      this.CompanyTypedata[index][key] = item[key];
      this.CompanyTypedata = this.CompanyTypedata[index].CompanyType;
      this.Id = item.Id;
    }
    if (index1 > -1) {
      this.templateoriginalCompanyType[index][key] = item[key];
      this.templateoriginalCompanyType[index]['ModifiedBy'] = this.LoginDetails.logindetails.Id;
      this.templateoriginalCompanyType[index]['ModifiedDate'] = this.todaydate;
    }
    // this.onRowUpadte();
    this.flag = "2"//for Update        
    var UploadFile = new FormData();
    UploadFile.append("CompanyType", JSON.stringify(this.templateoriginalCompanyType));
    UploadFile.append("flag", this.flag);
    var url = 'api/Master/AddUpdateCompanyTypeMaster';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      
      if (data != null) {
        this.getCompanyType();
        this.toaster.success('CompanyType has been updated successfully', 'Success', {
          timeOut: 2000,
        });
        this.backform();
      }
    });
}
  TextBoxChange(item, key) {
    
    let index: number = this.CompanyStatusdata.findIndex(t => t.Id == item.Id);
    let index1: number = this.templateoriginal.findIndex(t => t.Id == item.Id);
    if (index > -1) {
      this.CompanyStatusdata[index][key] = item[key];
      this.CompanyStatusdata = this.CompanyStatusdata[index].AddressType;
      this.Id = item.Id;
    }
    if (index1 > -1) {
      this.templateoriginal[index][key] = item[key];
      this.templateoriginal[index]['ModifiedBy'] = this.LoginDetails.logindetails.Id;
      this.templateoriginal[index]['ModifiedDate'] = this.todaydate;
    }
    // this.onRowUpadte();
    this.flag = "2"//for Update        
    var UploadFile = new FormData();
    UploadFile.append("CompanyStatus", JSON.stringify(this.templateoriginal));
    UploadFile.append("flag", this.flag);
    var url = 'api/Master/AddUpdateCompanyStatusMaster';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      
        if (data != null) {
            this.editings = false;
        this.getCompanyStatus();
        this.toaster.success('CompanyStatus has been updated successfully', 'Success', {
            timeOut: 2000,
            
        });
        this.backform();
      }
    });
  }
AddCompany()
{

let CompanyArray={
      CompanyName : this.CompanyName ,
      Status :true,
      CompanyType :this.CompanyTypeDropdown,
      CompanyStatus :this.CompanyStatusDropdown,
      Address:this.CompanyAddress,
      CreatedBy: this.LoginDetails.logindetails.Id,
      POCName:this.POCName,
      POCEmail:this.POCEmail,
      POCMobile:this.POCMobile

    };
    var UploadFile = new FormData();
    UploadFile.append("CompanyDetails", JSON.stringify(CompanyArray));
    var url = 'api/Values/AddCompany';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      
      if (data != null) {
        $('#myModalCompany').modal('hide');
        this.getCompany();
        this.generalService.ShowAlert('SUCCESS', 'Company has been added successfully', 'success');
        this.backform();
        this.companyForm = false;
      }
    });
}


  AddCompanyStatus() {
    
    let Array = [];
    Array.push({
      CompanyStatus: this.CompanyStatus, CreatedBy: this.LoginDetails.Id
    })
    var UploadFile = new FormData();
    UploadFile.append("CompanyStatus", JSON.stringify(Array));
    UploadFile.append("flag", this.flag);
    var url = 'api/Master/AddUpdateCompanyStatusMaster';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      
      if (data != null) {
       $('#myModal').modal('hide');
        this.getCompanyStatus();
        this.toaster.success('CompanyStatus has been added successfully', 'Success', {
          timeOut: 2000,
        });
        this.backform();
      }
    });
  }

  onDelete(rowData) {
    Swal.fire({
      title: 'Are you sure to Delete  ?',
      //text: "",
      //type: 'warning',
      html: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      debugger
      if (result.value) {
        this.flag = "3";

        let Array = [];
        Array.push({
          AddressCategory: rowData.AddressCategory, Id: rowData.Id,
        })
        var UploadFile = new FormData();
        UploadFile.append("CompanyStatus", JSON.stringify(Array));
        UploadFile.append("flag", this.flag);
        var url = 'api/Master/AddUpdateCompanyStatusMaster';
        this.generalService.PostData(url, UploadFile).then((data: any) => {

          if (data == "success") {
            this.getCompanyStatus();

            this.generalService.ShowAlert('SUCCESS', 'CompanyStatus has been Deleted successfully', 'success');
            this.backform();
          }
          else {
            this.generalService.ShowAlert('WARNING', 'You can not delete this record because of it is having relation with another table', 'warning');
            this.backform();
          }
        });
      }
    })
  }
  UpdateStatus(event, rowData) {
    

  }

  backform() {
    this.Grid = true;
  }
 AddCompanyType() {
    
    let Array = [];
    Array.push({
      CompanyType: this.CompanyType, CreatedBy: this.LoginDetails.Id
    })
    var UploadFile = new FormData();
    UploadFile.append("CompanyType", JSON.stringify(Array));
    UploadFile.append("flag", this.flag);
    var url = 'api/Master/AddUpdateCompanyTypeMaster';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      
      if (data != null) {
        this.getCompanyType();
        $('#myModalCompanyType').modal('hide');
        this.toaster.success('CompanyType has been added successfully', 'Success', {
          timeOut: 2000,
        });
        this.backform();
      }
    });
  }
  onDeleteCompany(rowData) {
  Swal.fire({
    title: 'Are you sure to Delete  ?',
    //text: "",
    //type: 'warning',
    html: "",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes'
  }).then((result) => {
    if (result.value) {
      var UploadFile = new FormData();
      UploadFile.append("CompanyId", rowData.Id);
      var url = 'api/Values/DeleteCompany';
      this.generalService.PostData(url, UploadFile).then((data: any) => {
        if (data == "SUCCESS") {
          this.getCompany();
          this.generalService.ShowAlert('SUCCESS', 'Company has been deleted successfully', 'success');
          this.backform();
        }
        else {
          this.generalService.ShowAlert('WARNING', 'You can not delete this record because of it is having relation with another table', 'warning');
          this.backform();
        }
      });
    }
  })
 }
onDeleteCompanyType(rowData) {
  Swal.fire({
    title: 'Are you sure to Delete  ?',
    //text: "",
    //type: 'warning',
    html: "",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes'
  }).then((result) => {
    debugger
    if (result.value) {
      this.flag = "3";
      debugger
      let Array = [];
      Array.push({
        CompanyType: rowData.AddressCategory, Id: rowData.Id,
      })
      var UploadFile = new FormData();
      UploadFile.append("CompanyType", JSON.stringify(Array));
      UploadFile.append("flag", this.flag);
      var url = 'api/Master/AddUpdateCompanyTypeMaster';
      debugger
      this.generalService.PostData(url, UploadFile).then((data: any) => {

        if (data == "success") {
          this.getCompanyType();
          this.generalService.ShowAlert('SUCCESS', 'CompanyType has been Deleted successfully', 'success');
          this.backform();
        }
        else {
          this.generalService.ShowAlert('WARNING', 'You can not delete this record because of it is having relation with another table', 'warning');
          this.backform();
        }
      });
    }
  })
 }

  changeStatus(rowData, status) {
    this.Status = status;
    this.flag = "4";
    
    let Array = [];
    Array.push({
      Id: rowData.Id, Status: this.Status, ModifiedBy: this.LoginDetails.logindetails.Id, ModifiedDate: this.todaydate
    })
    var UploadFile = new FormData();
    UploadFile.append("CompanyType", JSON.stringify(Array));
    UploadFile.append("flag", this.flag);
    var url = 'api/Master/AddUpdateCompanyTypeMaster';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      
      if (data != null) {
        this.getCompanyType();
        if (this.Status == true) {
          this.toaster.success('Status Activated Successfully', 'Success', {
            timeOut: 2000,
          });
        }
        else {
          this.toaster.warning('Status Dectivated Successfully', 'Success', {
            timeOut: 2000,
          });
        }
      }
    });
  }
UpdateCompany()
{
  
  var  Array={
      Id: this.CompanyId ,
      CompanyName :this.CompanyName,
      CompanyType :Number(this.CompanyTypeDropdown),
      CompanyStatus :this.CompanyStatusDropdown,
      Address:this.CompanyAddress,
       Status: this.Status,
       ModifiedBy: this.LoginDetails.logindetails.Id,
    ModifiedDate: this.todaydate,
    POCName: this.POCName,
    POCEmail: this.POCEmail,
    POCMobile: this.POCMobile
    };
    var UploadFile = new FormData();
    UploadFile.append("CompanyDetails", JSON.stringify(Array));
    var url = 'api/Values/UpdateCompany';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      
      if (data != null) {
        $('#myModalCompany').modal('hide');
        this.generalService.ShowAlert('SUCCESS', 'Company updated successfully', 'success');
        this.getCompany();
        this.companyForm = false;
      }
    });
}
  changeCompanyStatus(rowData, status) {
    this.Status = status;
    
   var  Array={
      Id: rowData.Id ,
      CompanyName :rowData.CompanyName ,
      CompanyType :rowData.CompanyTypeId,
      CompanyStatus :rowData.CompanyStatusId,
      Address:rowData.Address,
      CreatedBy: rowData.CreatedBy,
      Status: this.Status,
      ModifiedBy: this.LoginDetails.logindetails.Id,
      ModifiedDate: this.todaydate
    };
    var UploadFile = new FormData();
    UploadFile.append("CompanyDetails", JSON.stringify(Array));
    var url = 'api/Values/UpdateCompany';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      
      if (data != null) {
        this.getCompany();
        if (this.Status == true) {
          this.toaster.success('Status Activated Successfully', 'Success', {
            timeOut: 2000,
          });
        }
        else {
          this.toaster.warning('Status Dectivated Successfully', 'Success', {
            timeOut: 2000,
          });
        }
      }
    });
}
  refresh() {
    location.reload();
  }
}
