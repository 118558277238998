<div class="" style="margin:0;padding:0">
  <div class="row">
    <div class="col-lg-12" style="padding:0">
      <button type="button" style="margin-bottom:10px" class="btn" [routerLink]="['/Projects/Details',1]"><img class="tableheadericons" src="../../../../assets/img/icons/back.png" />Back</button>&nbsp;

      <button type="button" style="margin-bottom:10px" class="btn" (click)="saveProject()"><img class="tableheadericons" src="../../../../assets/img/icons/save.png" /> Save</button> &nbsp;
      <button type="button" style="margin-bottom:10px" class="btn"><img class="tableheadericons" src="../../../../assets/img/icons/cancel.png" /> Cancel</button>
      <!--<button type="button" style="margin-bottom:10px;margin-left:0.8rem;width: 7rem;" class="btn" *ngIf="!Pinned" (click)="Pined()"><img class="tableheadericons" src="https://img.icons8.com/dotty/80/000000/pin3.png" /> Pin</button>
      <button type="button" style="margin-bottom:10px;margin-left:0.8rem;width: 7rem;" class="btn" *ngIf="Pinned" (click)="UnPin()"><img class="tableheadericons" src="https://img.icons8.com/dusk/64/000000/pin3.png" /> Un Pin</button>-->

    </div>
    <!--<div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
      <input type="text" class="global_search" pInputText size="50" placeholder="Global Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
      <div class="btn-group">
        <button type="button" class="btn dropdown-toggle no-caret bg-transparent border-0 box-shadow-none" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i style="font-size: 18px" class="fa fa-ellipsis-v"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-right box-shadow rounded-0">
          <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-refresh"></i> Refresh</a>
          <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-cloud-upload"></i> Import</a>
          <a class="dropdown-item" href="javascript:void(0);" (click)="dt.exportCSV()"><i class="fa fa-file-excel-o"></i> Export</a>
          <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-print"></i> Print</a>
          <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-history"></i> History</a>
        </div>
      </div>
    </div>-->
    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12" style="padding:0">

      <div class="_card">
        <div class="top_head">
          General
        </div>
        <div class="row top_row">
          <div class="col-lg-5">
            <label class="head_txt"> Project ID</label>
          </div>
          <div class="col-lg-7 _padd">
            <input disabled type="text" class="form-control" [value]="ProjectId">
          </div>
        </div>
        <div class="row top_row">
          <div class="col-lg-5">
            <label class="head_txt"> Project#</label>
          </div>
          <div class="col-lg-7 _padd">
            <input type="text" placeholder="Enter # or Code" class="form-control" [(ngModel)]="ProjectCode">
          </div>
        </div>

        <div class="row top_row">
          <div class="col-lg-5" style="padding-right:0">
            <label class="head_txt"> Project Name</label>
          </div>
          <div class="col-lg-7 _padd">
            <input type="text" placeholder="Enter Project Name" class="form-control" [(ngModel)]="ProjectName">
          </div>
        </div>
        <div class="row top_row">
          <div class="col-lg-5" style="padding-right:0">
            <label class="head_txt">Project Manager</label>
          </div>
          <div class="col-lg-7 _padd">
            <input type="text" class="form-control" [(ngModel)]="ProjectManager">
          </div>
        </div>
        <!--<div class="row top_row">
    <div class="col-lg-5">
      <label class="head_txt"> ProjectStatus</label>
    </div>
    <div class="col-lg-7 _padd">
      <div class="head_txt">
        <select class="form-control" [(ngModel)]="ProjectStatus">
          <option value="0" disabled>Select ProjectStatus</option>
          <option *ngFor="let item of ProjectStatusData" [value]="item.Id">{{item.ProjectStatus}}</option>
        </select>
      </div>
    </div>
  </div>-->
        <div class="row top_row">
          <div class="col-lg-5">
            <label class="head_txt">Client</label>
          </div>
          <div class="col-lg-7 _padd">
            <div class="head_txt">
              <!--<select class="form-control" [(ngModel)]="Client" (change)="getOwnerDetails($event.target.value)">
    <option value="0" disabled>Select Client</option>
    <option *ngFor="let item of Companydata" [value]="item.Id">{{item.CompanyName}}</option>
  </select>-->
              <p-autoComplete [suggestions]="autofillClients" [style]="{'height':'27px'}"  (completeMethod)="filterClientSingle($event)" field="CompanyName"[ngModelOptions]="{standalone: true}" [(ngModel)]="Client" [dropdown]="true" [size]="30"
                               [minLength]="1" >
                <ng-template let-autofillCustomers pTemplate="item">
                  <div class="ui-helper-clearfix" style="padding-left:10px ">
                    <div class="row" (click)="getOwnerDetails(autofillCustomers.Id)">
                      <div >
                        {{autofillCustomers.CompanyName}}
                      </div>
                    </div>
                  </div>
                </ng-template>
              </p-autoComplete>
            </div>
          </div>
        </div> 

        <div class="row top_row">
          <div class="col-lg-5">
            <label class="head_txt"> Priority</label>
          </div>
          <div class="col-lg-7 _padd">
            <div class="head_txt">
              <select class="form-control" [(ngModel)]="Priority">
                <option value="1">Low</option>
                <option value="2">High</option>
                <option value="3">Medium</option>
                <!--<option *ngFor="let item of RequestMethod" [value]="item.Id">{{item.RequestMethod}}</option>-->
              </select>
            </div>
          </div>
        </div>

      </div>

    </div>
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
      <div class="_card">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="top_head">
              Project Details
            </div>
            <div class="row top_row">
              <div class="col-lg-5">
                <label class="head_txt">Owner</label>
              </div>
              <div class="col-lg-7 _padd">
                <input type="text" disabled class="form-control" [(ngModel)]="OwnerId" />
              </div>
            </div>
            <div class="row top_row">
              <div class="col-lg-5" style="padding-right:0">
                <label class="head_txt"> Schedule Start</label>
              </div>
              <div class="col-lg-7 _padd">
                <input type="date" class="form-control"  [(ngModel)]="ScheduleStart" (input)="DisablePrevScheduledates(ScheduleStart)">
              </div>
            </div>
            <div class="row top_row" *ngIf="Edit">
              <div class="col-lg-5" style="padding-right:0">
                <label class="head_txt"> Schedule End</label>
              </div>
              <div class="col-lg-7 _padd">
                <input type="date" class="form-control" [(ngModel)]="ScheduleEnd" >
              </div>
            </div>
            <div class="row top_row">
              <div class="col-lg-5" style="padding-right:0">
                <label class="head_txt"> Estimated Hours</label>
              </div>
              <div class="col-lg-7 _padd">
                <input type="text" class="form-control" [(ngModel)]="EstimatedHours">
              </div>
            </div>
            <div class="row top_row">
              <div class="col-lg-5">
                <label class="head_txt">Project Description</label>
              </div>
            </div>
            <!--<div class="row top_row">
        <div class="col-lg-5" style="padding-right:0">
          <label class="head_txt"> Created On</label>
        </div>
        <div class="col-lg-7 _padd">
          <input type="text" class="form-control">
        </div>
      </div>-->
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" style="margin-top: 3%;">
            <div class="row top_row">
              <div class="col-lg-5">
                <label class="head_txt">Contact</label>
              </div>
              <div class="col-lg-7 _padd">
                <input type="text" class="form-control" [(ngModel)]="Contact">
              </div>
            </div>
            <div class="row top_row" *ngIf="!Edit">
              <div class="col-lg-5" style="padding-right:0">
                <label class="head_txt"> Schedule End</label>
              </div>
              <div class="col-lg-7 _padd">
                <input type="date" class="form-control" [min]="mindate"  [(ngModel)]="ScheduleEnd">
              </div>
            </div>
            <div class="row top_row" *ngIf="Edit">
    <div class="col-lg-5" style="padding-right:0">
      <label class="head_txt"> Actual Start</label>
    </div>
    <div class="col-lg-7 _padd">
      <input type="date" class="form-control" [(ngModel)]="ActulaStart">
    </div>
  </div>
  <div class="row top_row" *ngIf="Edit">
    <div class="col-lg-5">
      <label class="head_txt">Actual End</label>
    </div>
    <div class="col-lg-7 _padd">
      <input type="date" class="form-control" [(ngModel)]="ActulaEnd">
    </div>
  </div>
  <div class="row top_row" *ngIf="Edit">
    <div class="col-lg-5">
      <label class="head_txt">Actual Hours</label>
    </div>
    <div class="col-lg-7 _padd">
      <input type="text" class="form-control" [(ngModel)]="ActualHours">
    </div>
  </div>
            <!--<div class="row top_row">
    <div class="col-lg-5">
      <label class="head_txt">Created By</label>
    </div>
    <div class="col-lg-7 _padd">
      <input disabled type="text" class="form-control">
    </div>
  </div>-->
          </div>
        </div>
        <div class="row top_row">
          <div class="col-lg-12 _padd">
            <textarea type="text" style="height:70px !important" class="form-control" [(ngModel)]="pDescription"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding-left:0">
      <div class="_card">
        <div class="top_head">
          Activities
        </div>
        <div>
          <ng-container>
            <div class="container">
              <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12" style=" margin-bottom: 0.5rem;">
                  <p-autoComplete [suggestions]="autofillActivities" (onSelect)="GetActivites(autofillActivities)" (completeMethod)="filterActivityTypeSingle($event)" field="ActivityType" [(ngModel)]="ActivityTypeName" [dropdown]="true" [size]="30"
                                  placeholder="Search with Activities" [minLength]="1"
                                 >
                    <ng-template let-autofillCustomers pTemplate="item" >
                      <div class="ui-helper-clearfix" style="padding-left:10px">
                        <div class="row" (click)="GetActivites(autofillActivities.ActivityType)">
                          <div style="height:15px">
                            {{autofillCustomers.ActivityType}}
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </p-autoComplete>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12" style=" margin-bottom: 0.5rem;">
                  <p-autoComplete [suggestions]="autofillTemplateType" (completeMethod)="filterCategory($event)" field="Category" [(ngModel)]="Category" [dropdown]="true" [size]="30"
                                  placeholder="Search with Template Type" [minLength]="1"
                               >
                    <ng-template let-autofillCustomers pTemplate="item">
                      <div class="ui-helper-clearfix" style="padding-left:10px">
                        <div class="row" (click)="GetCategory(autofillCustomers)">
                          <div style="height:15px">
                            {{autofillCustomers.Category}}
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </p-autoComplete>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12" style=" margin-bottom: 0.5rem;">
                  <p-autoComplete [suggestions]="autofillTemplates" (onSelect)="GetTemplates(autofillTemplates)" (completeMethod)="filterTemplateNameSingle($event)" field="TemplateName" [(ngModel)]="Templatename" [dropdown]="true" [size]="30"
                                  placeholder="Search with Template Name" [minLength]="1"
                                 >
                    <ng-template let-autofillCustomers pTemplate="item">
                      <div class="ui-helper-clearfix" style="padding-left:10px">
                        <div class="row">
                          <div style="height:15px">
                            {{autofillCustomers.TemplateName}}
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </p-autoComplete>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12 " style="margin:0;padding:0">
                  <button type="button" class="btn" (click)="SelectActivities(ActivityTypeName,Templatename)"><img class="tableheadericons" src="../../../../assets/img/icons/new.png" />Add</button> &nbsp;

                  <!--<button class="btn btn-primary" style="margin-left: 1%;height:25px;" (click)="SelectTasks(TaskName)">Add</button>-->
                  <!--&nbsp; <button class="btn btn-primary" *ngIf="removebutton" title="Remove Bill" style="margin-left: 1%;height:35px;width: 19%;"><i class="fa fa-minus"></i></button>-->
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div *ngIf="insertActivities">
          <ng-container>
            <div class="container">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th class="cls_th">SNO</th>
                    <th class="cls_th">Delete</th>
                    <th class="cls_th">Activity Name</th>
                    <th class="cls_th">Templat Type</th>
                    <th class="cls_th">Template Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let col of ActivityData; let i=index">
                    <td>
                      {{i+1}}
                    </td>
                    <td>
                      <img pTooltip="Delete" src="../../../../assets//img/Info/delete.png" (click)="DeleteActivities(ActivityData[i],i)" style="font-size: 15px;color:red" aria-hidden="true">
                    </td>
                    <td>
                      <input title="Description" type="text" class="form-control" [value]="ActivityData[i].ActivityName" />
                    </td>
                    <td>
                      <input type="text" class="form-control" [value]="ActivityData[i].TemplateType" />
                    </td>
                    <td>
                      <input type="text" class="form-control" [value]="ActivityData[i].TemplateName" />
                    </td>
                  </tr>
                </tbody>
              </table>
              <!--<div class="row " style="margin-bottom:1%">
                <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12">
                  SNo
                </div>
                <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12">
                  Delete
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 ">
                  ActivityName
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 ">
                  TemplatType
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 ">
                  TemplateName
                </div>

              </div>-->
            </div>
          </ng-container>
        </div>
        <!--<div *ngFor="let col of ActivityData; let i=index">
                <ng-container>
                  <div class="container">
                    <div class="row " style="margin-bottom:1%">
                      <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12">
                        {{i+1}}
                      </div>
                      <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12">
                        <img pTooltip="Delete" src="../../../../assets//img/Info/delete.png" (click)="DeleteActivities(ActivityData[i],i)" style="font-size: 15px;color:red" aria-hidden="true">

                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 ">
                        <input title="Description" type="text" class="form-control" [value]="ActivityData[i].ActivityName" />
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <input  type="text" class="form-control" [value]="ActivityData[i].TemplateType" />  </div>
                      <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 ">
                        <input type="text" class="form-control" [value]="ActivityData[i].TemplateName" />
                      </div>


                    </div>
                    </div>
                </ng-container>
              </div>-->
        <!--<div class="col-lg-12" style="padding:0">
          <button type="button" style="margin-bottom:10px" class="btn " (click)="OpenJobsPopUp()"><img class="tableheadericons" src="../../../../assets/img/icons/new.png" /> New</button> &nbsp;
          <input type="text" class="global_search" pInputText size="10" placeholder="Global Filter" (input)="dt.filterGlobal($event.target.value, 'startsWith')" style="width:auto">
        </div>
        <p-table #dt [columns]="colsJobs" [value]="SubProjectData" [scrollable]="true" scrollHeight="300px">
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 35px">
                State
              </th>
              <th style="width: 35px">Jobs</th>
              <th style="width: 35px">Id</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-i="rowIndex" let-rowdata>
            <tr>
              <td style="width: 35px">
                <i class="fa fa-trash-o" pTooltip="Delete" style="color:red;font-size:16px;cursor:pointer !important"></i> <span class="icon-name"></span>

              </td>
              <td>{{rowdata.JobNumber}}</td>
              <td style="width: 35px">{{i+1}}</td>
            </tr>
          </ng-template>
        </p-table>-->
      </div>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" style="padding-left:0">
      <div class="_card">
        <div class="top_head">
          Select  Sub Project
        </div>
        <div>
          <ng-container>
            <div class="container">
              <div class="row">
                <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12" style=" margin-bottom: 0.5rem;">
                  <p-autoComplete [suggestions]="autofillCategories" (onSelect)="GetJobs(autofillCategories)" (completeMethod)="filterGroupsSingle($event)" field="JobNumber" [(ngModel)]="SubProjectName" [dropdown]="true" [size]="30"
                                  placeholder="Search with Sub Project" [minLength]="1"
                                >
                    <ng-template let-autofillCustomers pTemplate="item" >
                      <div class="ui-helper-clearfix" style="padding-left:10px">
                        <div class="row" (click)="GetJobs(autofillCategories.jobNumber)">
                          <div style="height:15px">
                            {{autofillCustomers.JobNumber}}
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </p-autoComplete>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 " style="margin:0;padding:0">
                  <button type="button" class="btn" (click)="SelectJob(SubProjectName)"><img class="tableheadericons" src="../../../../assets/img/icons/new.png" />Add</button> &nbsp;

                  <!--<button class="btn btn-primary" style="margin-left: 1%;height:25px;" title="Add new Bill" (click)="SelectJob(SubProjectName)">Add</button>-->
                  <!--&nbsp; <button class="btn btn-primary" *ngIf="removebutton" title="Remove Bill" style="margin-left: 1%;height:35px;width: 19%;"><i class="fa fa-minus"></i></button>-->
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div *ngIf="insertSProject==true">
          <ng-container>
            <div class="container">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th class="cls_th">SNO</th>
                    <th class="cls_th">Delete</th>
                    <th class="cls_th">Sub Project Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let col of SubProjectData; let i=index">
                    <td class="cls_td">   {{i+1}}</td>
                    <td class="cls_td">
                      <img pTooltip="Delete" src="../../../../assets//img/Info/delete.png" (click)="DeleteJobs(SubProjectData[i],i)" style="font-size: 15px;color:red" aria-hidden="true">
                    </td>
                    <td class="cls_td">
                      <input type="text" class="form-control" [value]="SubProjectData[i].JobNumber" />
                    </td>
                  </tr>
                  <tr>
                </tbody>
              </table>

              <!--<div class="row " style="margin-bottom:1%">
                <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12">
                  SNo
                </div>
                <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                  Delete
                </div>
                <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12 ">
                  Sub Project Name
                </div>
              </div>-->
            </div>
          </ng-container>
        </div>
        <!--<div *ngFor="let col of SubProjectData; let i=index">
          <ng-container>
            <div class="container">
              <div class="row " style="margin-bottom:1%">
                <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12">
                 {{i+1}}
                </div>
                <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                  <img pTooltip="Delete" src="../../../../assets//img/Info/delete.png" (click)="DeleteJobs(SubProjectData[i],i)" style="font-size: 15px;color:red" aria-hidden="true">

                </div>
                <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12 ">
                  <input  type="text" class="form-control" [value]="SubProjectData[i].JobNumber" />
                </div>

              </div>
            </div>
          </ng-container>
        </div>-->
        <!--<div class="col-lg-12" style="padding:0">
          <button type="button" style="margin-bottom:10px" class="btn " (click)="OpenJobsPopUp()"><img class="tableheadericons" src="../../../../assets/img/icons/new.png" /> New</button> &nbsp;
          <input type="text" class="global_search" pInputText size="10" placeholder="Global Filter" (input)="dt.filterGlobal($event.target.value, 'startsWith')" style="width:auto">
        </div>
        <p-table #dt [columns]="colsJobs" [value]="SubProjectData" [scrollable]="true" scrollHeight="300px">
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 35px">
                State
              </th>
              <th style="width: 35px">Jobs</th>
              <th style="width: 35px">Id</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-i="rowIndex" let-rowdata>
            <tr>
              <td style="width: 35px">
                <i class="fa fa-trash-o" pTooltip="Delete" style="color:red;font-size:16px;cursor:pointer !important"></i> <span class="icon-name"></span>

              </td>
              <td>{{rowdata.JobNumber}}</td>
              <td style="width: 35px">{{i+1}}</td>
            </tr>
          </ng-template>
        </p-table>-->
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" style="padding-left:0">
      <div class="_card">
        <div class="top_head">
          Tasks
        </div>
        <div>
          <ng-container>
            <div class="container">
              <div class="row">
                <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12" style="margin-bottom: 0.5rem;">
                  <p-autoComplete [suggestions]="autofillTasks" (onSelect)="GetTasks(autofillTasks)" (completeMethod)="filterTaskTypeSingle($event)" field="TaskType" [(ngModel)]="TaskName" [dropdown]="true" [size]="30"
                                  placeholder="Search with Tasks" [minLength]="1"
                              >
                    <ng-template let-autofillCustomers pTemplate="item">
                      <div class="ui-helper-clearfix" style="padding-left:10px">
                        <div class="row" (click)="GetTasks(autofillTasks.TaskType)">
                          <div style="height:15px">
                            {{autofillCustomers.TaskType}}
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </p-autoComplete>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 " style="margin:0;padding:0">
                  <button type="button" class="btn" (click)="SelectTasks(TaskName)"><img class="tableheadericons" src="../../../../assets/img/icons/new.png" />Add</button> &nbsp;

                  <!--<button class="btn btn-primary" style="margin-left: 1%;height:25px;" (click)="SelectTasks(TaskName)">Add</button>-->
                  <!--&nbsp; <button class="btn btn-primary" *ngIf="removebutton" title="Remove Bill" style="margin-left: 1%;height:35px;width: 19%;"><i class="fa fa-minus"></i></button>-->
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div *ngIf="insertTask==true">
          <ng-container>
            <div class="container">

              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th class="cls_th">SNO</th>
                    <th class="cls_th">Delete</th>
                    <th class="cls_th">  Task Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let col of TaskData; let i=index">
                    <td class="cls_td">
                      {{i+1}}
                    </td>
                    <td class="cls_td">
                      <img pTooltip="Delete" src="../../../../assets//img/Info/delete.png" (click)="DeleteTasks(TaskData[i],i)" style="font-size: 15px;color:red" aria-hidden="true">

                    </td>
                    <td class="cls_td">
                      <input placeholder="Enter Jobs" title="Description" type="text" class="form-control" [value]="TaskData[i].TaskType" />

                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ng-container>
        </div>
        <!--<div *ngFor="let col of TaskData; let i=index">

          <ng-container>
            <div class="container">
              <div class="row " style="margin-bottom:1%">
                <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12">
                  {{i+1}}
                </div>
                <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                  <img pTooltip="Delete" src="../../../../assets//img/Info/delete.png" (click)="DeleteTasks(TaskData[i],i)" style="font-size: 15px;color:red" aria-hidden="true">
                </div>
                <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12 ">
                  <input placeholder="Enter Jobs" title="Description" type="text" class="form-control" [value]="TaskData[i].TaskType" />
                </div>
              </div>
            </div>
          </ng-container>
        </div>-->
        <!--<div class="col-lg-12" style="padding:0">
          <button type="button" style="margin-bottom:10px" class="btn " (click)="OpenJobsPopUp()"><img class="tableheadericons" src="../../../../assets/img/icons/new.png" /> New</button> &nbsp;
          <input type="text" class="global_search" pInputText size="10" placeholder="Global Filter" (input)="dt.filterGlobal($event.target.value, 'startsWith')" style="width:auto">
        </div>
        <p-table #dt [columns]="colsJobs" [value]="SubProjectData" [scrollable]="true" scrollHeight="300px">
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 35px">
                State
              </th>
              <th style="width: 35px">Jobs</th>
              <th style="width: 35px">Id</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-i="rowIndex" let-rowdata>
            <tr>
              <td style="width: 35px">
                <i class="fa fa-trash-o" pTooltip="Delete" style="color:red;font-size:16px;cursor:pointer !important"></i> <span class="icon-name"></span>

              </td>
              <td>{{rowdata.JobNumber}}</td>
              <td style="width: 35px">{{i+1}}</td>
            </tr>
          </ng-template>
        </p-table>-->
      </div>
    </div>
    <!--<div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
      <div class="_card">
        <div class="col-lg-12" style="padding:0">
          <button type="button" style="margin-bottom:10px" class="btn " (click)="Open0
        ivityTypePopUp()"><img class="tableheadericons" src="../../../../assets/img/icons/new.png" /> New</button> &nbsp;
          <input type="text" class="global_search" pInputText size="10" placeholder="Global Filter" (input)="dt1.filterGlobal($event.target.value, 'startsWith')" style="width:auto">
        </div>
        <p-table #dt1 [columns]="colsActivities" [value]="ActivityData" [scrollable]="true" scrollHeight="300px">
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 35px">
                State
              </th>
              <th style="width: 35px">ActivityType</th>
              <th style="width: 35px">TemplateName</th>
              <th style="width: 35px">TemplateType</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-i="rowIndex" let-rowdata>
            <tr>
              <td style="width: 35px">
                <i class="fa fa-trash-o" pTooltip="Delete" style="color:red;font-size:16px;cursor:pointer !important"></i> <span class="icon-name"></span>

              </td>
              <td style="width: 35px">{{rowdata.ActivityType}}</td>
              <td style="width: 35px">{{rowdata.Template}}</td>
              <td style="width: 35px">{{rowdata.TemplateType}}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
      <div class="_card">
        <div class="col-lg-12" style="padding:0">
          <button type="button" style="margin-bottom:10px" class="btn " (click)="OpenTasksPopUp()"><img class="tableheadericons" src="../../../../assets/img/icons/new.png" /> New</button> &nbsp;
          <input type="text" class="global_search" pInputText size="10" placeholder="Global Filter" (input)="dt1.filterGlobal($event.target.value, 'startsWith')" style="width:auto">
        </div>
        <p-table #dt1 [columns]="colsTasks" [value]="TaskData" [scrollable]="true" scrollHeight="300px">
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 35px">
                State
              </th>
              <th style="width: 35px">Tasks</th>
              <th style="width: 35px">Id</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-i="rowIndex" let-rowdata>
            <tr>
              <td style="width: 35px">
                <i class="fa fa-trash-o" pTooltip="Delete" style="color:red;font-size:16px;cursor:pointer !important"></i> <span class="icon-name"></span>

              </td>
              <td style="width: 35px">{{rowdata.TaskType}}</td>
              <td style="width: 35px">{{Id}}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>-->
  </div>
  <br />


  <!-- #region Popup for add Jobs names-->
  <div class="modal" id="myModal">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header state modal-primary">
          <h5 style="text-align:left;" class="modal-title" id="modal-primary-label">Add Sub Projects</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" style="color:white">×</span></button>
        </div>
        <div class="modal-body">
          <div class="form-group row">
            <div class="col-lg-12">
              <label>Sub Project</label>
            </div>
            <div class="col-sm-10">
              <p-autoComplete [suggestions]="autofillCategories" (onSelect)="GetJobs(autofillCategories)" (completeMethod)="filterGroupsSingle($event)" field="JobNumber" [(ngModel)]="SubProjectName" [size]="30"
                              placeholder="Search with Sub Project" [minLength]="1"
                              [style]="{'height': '33px','font-size':'15px !important'}">
                <ng-template let-autofillCustomers pTemplate="item">
                  <div class="ui-helper-clearfix" style="padding-left:10px">
                    <div class="row" (click)="GetJobs(autofillCategories.jobNumber)">
                      <div style="height:15px">
                        {{autofillCustomers.JobNumber}}
                      </div>
                    </div>
                  </div>
                </ng-template>
              </p-autoComplete>
            </div>
            <button *ngIf="ButtonText=='Add'" type="button" class="btn btn-primary" (click)="AddJob()">Add</button>
            <button *ngIf="ButtonText=='Select'" type="button" class="btn btn-primary" (click)="SelectJob(SubProjectName)">Select</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- #endregion -->
  <!-- #region Popup for add Activities names-->
  <div class="modal" id="myModal1">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header state modal-primary">
          <h5 style="text-align:left;" class="modal-title" id="modal-primary-label">Add Activities</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" style="color:white">×</span></button>
        </div>
        <div class="modal-body">
          <div class="form-group row">
            <div class="col-lg-12">
              <label>Activities</label>
            </div>
            <div class="col-lg-10">
              <p-autoComplete [suggestions]="autofillActivities" (onSelect)="GetActivites(autofillActivities)" (completeMethod)="filterActivityTypeSingle($event)" field="ActivityType" [(ngModel)]="ActivityTypeName" [size]="30"
                              placeholder="Search with Sub Project" [minLength]="1"
                              [style]="{'height': '33px','font-size':'15px !important'}">
                <ng-template let-autofillCustomers pTemplate="item" style="max-height:500px !important">
                  <div class="ui-helper-clearfix" style="border-bottom:1px solid #D5D5D5;padding:3%;">
                    <div class="row" (click)="GetActivites(autofillActivities.ActivityType)">
                      <div>
                        {{autofillCustomers.ActivityType}}
                      </div>
                    </div>
                  </div>
                </ng-template>
              </p-autoComplete>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-lg-12">
              Templates
            </div>
            <div class="col-sm-10">
              <p-autoComplete [suggestions]="autofillTemplates" (onSelect)="GetTemplates(autofillTemplates)" (completeMethod)="filterTemplateNameSingle($event)" field="TemplateName" [(ngModel)]="Templatename" [size]="30"
                              placeholder="Search with Template Name" [minLength]="1"
                              [style]="{'height': '33px','font-size':'15px !important'}">
                <ng-template let-autofillCustomers pTemplate="item" style="max-height:500px !important">
                  <div class="ui-helper-clearfix" style="border-bottom:1px solid #D5D5D5;padding:3%;">
                    <div class="row" (click)="GetTemplates(autofillTemplates.TemplateName)">
                      <div>
                        {{autofillCustomers.TemplateName}}
                      </div>
                    </div>
                  </div>
                </ng-template>
              </p-autoComplete>
            </div>
          </div>
          <div class="form-group row">
            <button *ngIf="ButtonText=='Add'" style="margin-left:83%" type="button" class="btn btn-primary" (click)="AddJob()">Add</button>
            <button *ngIf="ButtonText=='Select'" style="margin-left:83%" type="button" class="btn btn-primary" (click)="SelectActivities(ActivityTypeName,Templatename)">Select</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- #endregion -->
  <!-- #region Popup for add Activities names-->
  <div class="modal" id="myModal2">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header state modal-primary">
          <h5 style="text-align:left;" class="modal-title" id="modal-primary-label">Add Tasks</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" style="color:white">×</span></button>
        </div>
        <div class="modal-body">
          <div class="form-group row">
            <div class="col-lg-12">
              <label>Tasks</label>
            </div>
            <div class="col-sm-10">
              <p-autoComplete [suggestions]="autofillTasks" (onSelect)="GetTasks(autofillTasks)" (completeMethod)="filterTaskTypeSingle($event)" field="TaskType" [(ngModel)]="TaskName" [size]="30"
                              placeholder="Search with Sub Project" [minLength]="1"
                              [style]="{'height': '33px','font-size':'15px !important'}">
                <ng-template let-autofillCustomers pTemplate="item" style="max-height:500px !important">
                  <div class="ui-helper-clearfix" style="border-bottom:1px solid #D5D5D5;padding:3%;">
                    <div class="row" (click)="GetTasks(autofillTasks.TaskType)">
                      <div>
                        {{autofillCustomers.TaskType}}
                      </div>
                    </div>
                  </div>
                </ng-template>
              </p-autoComplete>
            </div>
            <button *ngIf="ButtonText=='Add'" type="button" class="btn btn-primary" (click)="AddJob()">Add</button>
            <button *ngIf="ButtonText=='Select'" type="button" class="btn btn-primary" (click)="SelectTasks(TaskName)">Select</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- #endregion -->
</div>
  <style>
    .cls_th {
      padding: 4px;
      font-size: 11px;
      letter-spacing: 0.5px;
    }
    .redText {
      background-color: #F00;
    }
    .cls_td{
padding:4px;
    }
    .greenText {
      background-color: #0F0;
    }

    .blueText {
      background-color: #00F;
    }

    .mid_txt {
      font-size: 15px;
      font-weight: 600;
      letter-spacing: 0.2px;
      line-height: 25px;
    }

    .components {
      font-size: 17px;
      line-height: 25px;
      margin-top: 16px;
      font-weight: bold;
      letter-spacing: 1px;
    }

    .back {
      background: white !important;
      height: 20px !important;
      padding: 1px 7px 7px 7px !important;
      font-size: 13px !important;
    }

    .app.combined .breadcrumb {
      padding: 0.5rem 1rem;
      display: none !important;
    }

    ._card {
      border: 0.5px solid #dedede;
      padding: 15px;
      border-radius: 4px;
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    }

    .form-control1 {
      display: block;
      width: 100%;
      height: 53px !important;
      padding: 3px;
      font-size: 13px;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    .head_txt {
      font-size: 12px;
      letter-spacing: 0.2px;
      line-height: 24px;
    }

    .form-control {
      display: block;
      width: 100%;
      height: 27px !important;
      padding: 3px;
      font-size: 13px;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    .top_head {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 5px;
      color: #000;
      letter-spacing: 0.5px;
    }

    .divRPimg {
      height: auto;
      padding: 10px 0;
      display: block;
      margin-right: 3%;
      border-radius: 3px;
      box-shadow: 0 4px 9px 0 rgba(67, 65, 79, 0.1);
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }


    .p-inputtext {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 1rem;
      color: #495057;
      background: #ffffff;
      padding: 0.5rem 0.5rem;
      border: 1px solid #ced4da;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: 3px;
      width: 240rem !important;
    }


    /*#region ramesh alli */



    /*#endregion */


  </style>
