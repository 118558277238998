
<div class="row">
  <p-table #dt [columns]="cols" [value]="RequestMethod" datakey="RequestMethod" editMode="row" selectionMode="multiple">
    <ng-template pTemplate="caption">
      <div class="row">
        <div class="col-lg-6" style="text-align:justify">
          <button type="button" class="btn btn-primary" (click)="OpenAddForm()">Add New</button>
          <i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top"
             aria-label="" (click)="dt.exportCSV()" style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:8px;"></i>
          <!--&nbsp;
          <button type="button" class="btn btn-primary" (click)="onRowUpadte()">Save</button>-->
        </div>
        <div class="col-lg-6" style="margin-bottom: 0;">
          <i class="fa fa-search" style="margin:6px 6px 0 0"></i>
          <input type="text" class="global_search" pInputText size="70" placeholder="Global Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">

        </div>
      </div>

    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th style="width:50px">Delete</th>
        <th style="width:80px">State</th>
        <th>RequestMethod</th>
        <th>Created Date</th>
        <th>Created By</th>
        <th>Modified Date</th>
        <th>Modified By</th>
      </tr>
      <tr>
        <th></th>
        <th></th>
        <th style="width:15px !important;align-items:flex-start">
          <input pInputText type="text" (input)="dt.filter($event.target.value, 'RequestMethod', 'startsWith')" placeholder="Search by Activity Type" class="p-column-filter">
        </th>
        <th>
          <input pInputText type="text" (input)="dt.filter($event.target.value, 'CreatedDate', 'contains')" placeholder="Search by CreatedDate " class="p-column-filter">
        </th>
        <th>
          <input pInputText type="text" (input)="dt.filter($event.target.value, 'CreateBy', 'contains')" placeholder="Search by CreatedBy " class="p-column-filter">
        </th>
        <th>
          <input pInputText type="text" (input)="dt.filter($event.target.value, 'ModifiedDate', 'contains')" placeholder="Search by ModifiedDate" class="p-column-filter">
        </th>
        <th>
          <input pInputText type="text" (input)="dt.filter($event.target.value, 'ModifiBy', 'contains')" placeholder="Search by ModifiedBy " class="p-column-filter">
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-editing="editing" let-rowIndex="rowIndex">
      <tr [pEditableRow]="rowData">

        <td class="col-btn" style="text-align:center">
          <i class="pi pi-trash" (click)="onDelete(rowData)" style="font-size: 15px;color:red" aria-hidden="true"></i>
        </td>
        <td class="col-btn" style="text-align:center">
          <img *ngIf="rowData.Status==true" src="../../../../assets//img/check.png" (click)="changeStatus(rowData,false)" />
          <img *ngIf="rowData.Status==false" src="../../../../assets//img/closed.png" (click)="changeStatus(rowData,true)" />
        </td>
        <td style="width:15px !important" class="rowEditCursor" pEditableColumn>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText [(ngModel)]="rowData.RequestMethod" (change)="TextBoxChange(rowData,RequestMethod)" />
            </ng-template>
            <ng-template pTemplate="output">
              {{rowData.RequestMethod || 0}}
            </ng-template>
          </p-cellEditor>
        </td>
        <td>
          {{rowData.CreatedDate | date:'dd-MMM-yyyy H:m'}}
        </td>
        <td>
          {{rowData.CreateBy}}
        </td>
        <td>
          {{rowData.ModifiedDate | date:'dd-MMM-yyyy H:m'}}
        </td>
        <td>
          {{rowData.ModifiBy}}
        </td>
      </tr>
    </ng-template>
    <!--<button type="button" class="btn btn-primary" (click)="OpenAddForm()" style="margin-top: 35%;">Add</button>-->

  </p-table>

</div>

<!-- #region Popup for view orders-->
<div class="modal" id="myModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header state modal-primary">
        <h5 style="text-align:left;" class="modal-title" id="modal-primary-label">{{ModelTitle}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" style="color:white">×</span></button>
      </div>
      <div class="modal-body">
        <div class="form-group row">
          <label class="col-lg-3 col-md-3 col-sm-12 col-xs-12">RequestMethod</label>
          <div class="col-sm-10">
            <input type="text" class="form-control" [(ngModel)]="RequestMethod" name="RequestMethod" placeholder="Enter RequestMethod">
          </div>
          <button type="button" class="btn btn-primary" (click)="AddRequestMethod()">Add</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- #endregion -->

<style>
  .components {
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 25px;
    margin-top: 16px;
    font-weight: bold;
  }

  .back {
    background: white !important;
    height: 20px !important;
    padding: 1px 7px 7px 7px !important;
    font-size: 13px !important;
  }

  .app.combined .breadcrumb {
    padding: 0.5rem 1rem;
    display: none !important;
  }

  ._card {
    border: 0.5px solid #dedede;
    padding: 15px;
    border-radius: 4px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  }

  .head_txt {
    font-size: 12px;
    letter-spacing: 0.2px;
    line-height: 24px;
  }

  .form-control {
    display: block;
    width: 100%;
    height: 23px !important;
    padding: 3px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .top_head {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 5px;
    color: #000;
    letter-spacing: 0.5px;
  }

  .divRPimg {
    height: auto;
    padding: 10px 0;
    display: block;
    margin-right: 3%;
    border-radius: 3px;
    box-shadow: 0 4px 9px 0 rgba(67, 65, 79, 0.1);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
</style>
