<div class="container-fluid" style="padding:0">
  <div class="row head">
    <div class="col-lg-4" style="padding:0">
      Reports & Queries
      <button type="button" style="" class="btn" *ngIf="!Pinned" (click)="Pined()"><img class="tableheadericons" src="https://img.icons8.com/dotty/80/000000/pin3.png" /> Pin</button>
      <button type="button" style="" class="btn" *ngIf="Pinned" (click)="UnPin()"><img class="tableheadericons" src="https://img.icons8.com/dusk/64/000000/pin3.png" /> Un Pin</button>


    </div>
    <div class="col-lg-2" style="padding:0">
    
      <!--<div *ngIf="!Pinned" (click)="Pined()">
    <img src="https://img.icons8.com/dotty/80/000000/pin3.png" class="pin" />
  </div>
  <div *ngIf="Pinned" (click)="UnPin()">
    <img src="https://img.icons8.com/dusk/64/000000/pin3.png" class="pin" />
  </div>-->
    </div>
    <div class="col-lg-6" style="text-align:right;font-size:12px;letter-spacing:0.2px">
      <!--This Quarter 4-1-2020 To 6-30-2020-->
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-lg-12">
    <p-table #dt [columns]="cols" [value]="homeInspector" [rows]="10" [responsive]="true" [scrollable]="true" scrollHeight="333px">
      <ng-template pTemplate="caption">
        <div class="row">
          <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
            </div>
            <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
              {{dt.filteredValue?dt.filteredValue.length:dt.totalRecords}} of {{dt.totalRecords}}
              <input type="text" class="global_search" pInputText size="50" placeholder="Filter By..." (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
              <div class="btn-group">
                <button type="button" class="btn dropdown-toggle no-caret bg-transparent border-0 box-shadow-none" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i style="font-size: 18px" class="fa fa-ellipsis-v"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right box-shadow rounded-0">
                  <a class="dropdown-item" href="javascript:void(0);" (click)="refresh()"><i class="fa fa-refresh"></i> Refresh</a>
                  <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-cloud-upload"></i> Import</a>
                  <a class="dropdown-item" href="javascript:void(0);" (click)="dt.exportCSV()"><i class="fa fa-file-excel-o"></i> Export</a>
                  <a class="dropdown-item" href="javascript:void(0);" (click)="onPrint()"><i class="fa fa-print"></i> Print</a>
                  <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-history"></i> History</a>
                </div>
              </div>
            </div>
          </div>
      </ng-template>
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col style="width:36px" />
          <col style="width:36px" />
          <col *ngFor="let col of cols" [ngStyle]="{'width': col.width}">
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th></th>
          <th></th>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            <span>{{col.header}}</span>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td class="stateicon">
            <img pTooltip="Delete" (click)="onDelete(rowData)" src="../../../../assets//img/Info/delete.png"  style="font-size: 15px;width:26;color:red" aria-hidden="true">
          </td>
          <td class="stateicon">
            <img pTooltip="Active" (click)="changeStatus(rowData,false)" *ngIf="rowData.Status==true" src="../../../../assets//img/Info/check.png"/>
            <img pTooltip="Deactive" (click)="changeStatus(rowData,true)" *ngIf="rowData.Status==false" src="../../../../assets//img/Info/closed.png"/>
          </td>
          <td *ngFor="let col of cols" [ngSwitch]="col.field">
            <span *ngSwitchDefault>{{rowData[col.field]}}</span>           
            <span *ngSwitchCase="'CreatedDate'">{{rowData[col.field] | date:'dd-MMM-yyyy'}}</span>
            <span *ngSwitchCase="'ModefiedDate'">{{rowData[col.field] | date:'dd-MMM-yyyy'}}</span>
            <span *ngSwitchCase="'Id'">
              <a class="rowEditCursor" (click)="navigateReport(rowData.Id)">
                {{rowData[col.field]}}
              </a>
            </span>
            <span class="rowEditCursor" [pTooltip]="rowData.ReportName" *ngSwitchCase="'ReportName'">
              <a (click)="navigateReport(rowData.Id)">
                {{rowData[col.field]}}
              </a>
            </span>
            <span class="rowEditCursor" *ngSwitchCase="'ReportType'">
              <a (click)="navigateReport(rowData.Id)">
                {{rowData[col.field]}}
              </a>
            </span>
            <span class="rowEditCursor" *ngSwitchCase="'Group'">
              <a (click)="navigateReport(rowData.Id)">
                {{rowData[col.field]}}
              </a>
            </span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  </div>
</div>


<style>
  .card_1 {
    border: 1px solid #c1c1c175;
    /* padding: 35px; */
    height: 153px;
    margin-bottom: 15px;
    font-size: 13px;
    padding: 19% 0% 16% 0%;
    text-align: center;
  }

  .head {
    background: white;
    padding: 18px;
    box-shadow: 0 0px 3px rgba(0,0,0,0.2), 0 1px 0px rgb(0 0 0 / 5%);
  }

  .myc {
    margin: 5% 0%;
    font-size: 13px;
    letter-spacing: 0.2px;
    font-weight: bold;
  }
</style>
