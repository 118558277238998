import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
//import { GeneralService } from '../../pages/Services/general.service';
import { GeneralService } from '../../Services/general.service'
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { formatDate } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { PrimeNGConfig } from 'primeng/api';
import { FilterMatchMode } from 'primeng/api';
import Swal from 'sweetalert2';
import { MenuService } from '../../../theme/components/menu/menu.service';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
declare var $;
@Component({
  selector: 'app-activitytype',
  templateUrl: './activitytype.component.html',
  styleUrls: ['./activitytype.component.scss'],
  providers: [DatePipe, MenuService],
  animations: [
    trigger('rowExpansionTrigger', [
      state('void', style({
        transform: 'translateX(-10%)',
        opacity: 0
      })),
      state('active', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class ActivitytypeComponent implements OnInit {
  Status: any;
  LoginDetails: any;
  ModelTitle: string;
  templateoriginal: any;
  template: any;
  flag: any;
  ActivityType: any;
  cols: any;
  ActivityTypes1: any; 
  addNew: boolean;
  editForm: boolean;
  activitytype: any;
  Id: any;
  public form: FormGroup;
  Grid: boolean = true;
  myDate = new Date();
  todaydate: any;
  Pinned: boolean;
  PinId: any;
  MenuId: any;
  public settings: Settings;
  currentPageRoute: any;
  constructor(public config: PrimeNGConfig,
    public menuService: MenuService, public appSettings: AppSettings,public router: Router,public toaster:ToastrService, public fb: FormBuilder, public generalService: GeneralService) {
    this.config.filterMatchModeOptions = {
      text: [
        FilterMatchMode.STARTS_WITH,
        FilterMatchMode.CONTAINS,
        FilterMatchMode.NOT_CONTAINS,
        FilterMatchMode.ENDS_WITH,
        FilterMatchMode.EQUALS,
        FilterMatchMode.NOT_EQUALS,
        FilterMatchMode.LESS_THAN,
        FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
        FilterMatchMode.GREATER_THAN,
        FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
      ],
      numeric: [
        FilterMatchMode.EQUALS,
        FilterMatchMode.NOT_EQUALS,
        FilterMatchMode.LESS_THAN,
        FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
        FilterMatchMode.GREATER_THAN,
        FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
      ],
      date: [
        FilterMatchMode.BEFORE,
        FilterMatchMode.AFTER,
      ]
    }
    this.todaydate = formatDate(this.myDate, 'yyyy-MM-dd hh:mm:ss a', 'en-US', '+0530');
    this.LoginDetails = JSON.parse(localStorage.getItem('LoginDetails'))
    this.form = this.fb.group({
      activitytype: ['', Validators.required]
    });  
    this.settings = this.appSettings.settings;
    this.MenuId = 0;
    this.router.events.subscribe((val) => {

      this.currentPageRoute = this.router.url; // Current page route

    });
  }

  ngOnInit(): void {

    this.GetPinned();
    this.getActivityTypes();
  }
  //=======print====//
  onPrint() {
    window.print();
  }
  //Get Pinned

  Pined() {
    debugger
    this.Pinned = true;

    let Array = [];
    Array.push({
      MenuId: this.MenuId, RouterLink: this.currentPageRoute, UserId: this.LoginDetails.logindetails.Id
    })
    var UploadFile = new FormData();
    UploadFile.append("Pinned", JSON.stringify(Array));
    var url = 'api/Values/AddPinned';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data == "SUCCESS") {
        debugger
        this.GetPinned();
        var UploadFile = new FormData();
        UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
        //UploadFile.append("flag", '1');
        var url = 'api/Values/GetSideMenusWithRecentPinned';
        this.generalService.PostData(url, UploadFile).then((data1: any) => {
          debugger
          var Details = this.generalService.SIdeMenus(data1);
          this.menuService.RefreshMenu(Details, this.settings.theme.menu);
          debugger
        });

      }

    });
  }


  UnPin() {
    debugger

    var UploadFile = new FormData();
    UploadFile.append("PinId", this.PinId);
    UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
    var url = 'api/Values/DeletePinned';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data == "SUCCESS") {
        debugger
        this.Pinned = false;
        var UploadFile = new FormData();
        UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
        //UploadFile.append("flag", '1');
        var url = 'api/Values/GetSideMenusWithRecentPinned';
        this.generalService.PostData(url, UploadFile).then((data1: any) => {
          debugger
          var Details = this.generalService.SIdeMenus(data1);
          this.menuService.RefreshMenu(Details, this.settings.theme.menu);
          debugger
        });
      }
    });
  }

  GetPinned() {
    debugger
    var UploadFile = new FormData();
    UploadFile.append("MenuId", this.MenuId);
    UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
    UploadFile.append("RouterLink", this.currentPageRoute);

    var url = 'api/Values/GetPinned';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data.MSG == "EXISTS") {
        debugger
        this.PinId = data.PinId;
        this.Pinned = true;
      }
      else if (data.MSG == "NOT EXISTS") {
        this.Pinned = false;
      }
    });
  }
  getActivityTypes() {
    
    var url = "api/Master/GetActivityType";
    this.generalService.GetData(url).then((data: any) => {
      
      if (data != null) {             
        var obj = Object.keys(data[0])
        this.cols = [
          { field: 'Id', header: 'Id', width: '150px' },
          {field:'Status',header:'Status',width:'150px'},
           { field: 'ActivityType', header: 'ActivityType', width: '150px' },
           { field: 'CreatedDate', header: 'CreatedDate', width: '150px' },
           { field: 'CreateBy', header: 'CreatedBy', width: '150px' },
           { field: 'ModifiedDate', header: 'ModifiedDate', width: '150px' },
           { field: 'ModifiBy', header: 'ModifiedBy', width: '150px' },
        ]
        data.forEach(
          companyType => (companyType.CreatedDate = new Date(companyType.CreatedDate))
        );
        data.forEach(
          companyType => (companyType.ModifiedDate = new Date(companyType.ModifiedDate))
        );
        this.ActivityTypes1 = data;
        this.templateoriginal = data;
      }
    }, err => {
      // this.spinner.hide();
      this.generalService.ShowAlert('ERROR', 'Something Went wrong', 'error');
    });
  }

  OpenAddForm() {
    
    this.ModelTitle = "Add ActivityType";
    $('#myModal').modal('show');
    this.Grid = false;
    this.ActivityType = "";
    this.flag = "1";//for adding
    //this.addNew = true;
    //this.editForm = false;
    
  }
  
    TextBoxChange(item, key) {
      
      let index: number = this.ActivityTypes1.findIndex(t => t.Id == item.Id);
      let index1: number = this.templateoriginal.findIndex(t => t.Id == item.Id);
      if (index > -1) {
        this.ActivityTypes1[index][key] = item[key];
        this.ActivityType = this.ActivityTypes1[index].ActivityType;
        this.Id = item.Id;
      }
      if (index1 > -1) {
        this.templateoriginal[index][key] = item[key];
        this.templateoriginal[index]['ModifiedBy'] = this.LoginDetails.logindetails.Id;
        this.templateoriginal[index]['ModifiedDate'] = this.todaydate;
      }
      // this.onRowUpadte();
      this.flag = "2"//for Update        
      var UploadFile = new FormData();
      UploadFile.append("ActivityType", JSON.stringify(this.templateoriginal));
      UploadFile.append("flag", this.flag);
      var url = 'api/Master/AddUpdateActivityTypes';
      this.generalService.PostData(url, UploadFile).then((data: any) => {
        
        if (data != null) {
          this.getActivityTypes();
          this.toaster.success('ActivityType has been updated successfully', 'Success', {
            timeOut: 2000,
          });
          this.backform();
        }
      });
    }
  
  
  AddActivityType() {
    
    let Array = [];
    Array.push({
      ActivityType: this.ActivityType, CreatedBy: this.LoginDetails.logindetails.Id
    })
    var UploadFile = new FormData();
    UploadFile.append("ActivityType", JSON.stringify(Array));
    UploadFile.append("flag", this.flag);
    var url = 'api/Master/AddUpdateActivityTypes';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      
      if (data != null) {
        $('#myModal').modal('hide');
        this.getActivityTypes();
        this.toaster.success('ActivityType has been updated successfully', 'Success', {
          timeOut: 2000,
        });
        this.backform();
      }
    });
  }
 
  onDelete(rowData) {
    Swal.fire({
      title: 'Are you sure to Delete  ?',
      //text: "",
      //type: 'warning',
      html: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      debugger
      if (result.value) {
        this.flag = "3";
        let Array = [];
        Array.push({
          ActivityType: rowData.ActivityType, Id: rowData.Id,
        })
        var UploadFile = new FormData();
        UploadFile.append("ActivityType", JSON.stringify(Array));
        UploadFile.append("flag", this.flag);
        var url = 'api/Master/AddUpdateActivityTypes';
        this.generalService.PostData(url, UploadFile).then((data: any) => {

          if (data == "success") {
            this.getActivityTypes();
            this.generalService.ShowAlert('SUCCESS', 'ActivityType has been Deleted successfully', 'success');
            this.backform();
          }
          else {
            this.generalService.ShowAlert('WARNING', 'You can not delete this record because of it is having relation with another table', 'warning');
            this.backform();
          }
        });
      }
    })   
  }
  changeStatus(rowData, status) {
    this.Status = status;

    this.flag = "4";
    
    let Array = [];
    Array.push({
      Id: rowData.Id, Status: this.Status, ModifiedBy: this.LoginDetails.logindetails.Id, ModifiedDate: this.todaydate
    })
    var UploadFile = new FormData();
    UploadFile.append("ActivityType", JSON.stringify(Array));
    UploadFile.append("flag", this.flag);
    var url = 'api/Master/AddUpdateActivityTypes';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      
      if (data != null) {
        this.getActivityTypes();
        if (this.Status == true) {
          this.toaster.success('Status Activated Successfully', 'Success', {
            timeOut: 2000,
          });
        }
        else {
          this.toaster.warning('Status Dectivated Successfully', 'Success', {
            timeOut: 2000,
          });
        }
      }
    });
  }
  
  backform() {
    this.Grid = true;
  }
  refresh() {
    location.reload();
  }
  Print() {
    window.print();
  }
}
