import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';

import { LoginModule } from '../login/login.module'

import { Menu } from '../../theme/components/menu/menu.model';
//import { MenuService } from '../../theme/components/menu/menu.service';
//import { Settings } from '../../app.settings.model';
//import { AppSettings } from '../../app.settings';

@Injectable({
  providedIn: 'root',
  
})

export class GeneralService {
  //private menuService: MenuService

  url: any;
  urlname: any;
  SideMenus: any;
  LodDetails: any;
  MenuId: any;
  LoginDetails: any;
  SideMenusAry: any = [];
  MainSideMenusAry: any = [];
  public menus: Array<Menu>;
  //public settings: Settings;
  constructor(public http: HttpClient) {
    this.LoginDetails = JSON.parse(localStorage.getItem('LoginDetails'))
    this.MenuId = localStorage.getItem('MenuId')

  } 

  ShowAlert(title, text, type) {
    Swal.fire({
      title: title,
      html: text,
      icon: type,
      confirmButtonText: 'Ok'
    })
  }
  ShowAlert1(title, text, type) {
    Swal.fire({
      title: title,
      html: text,
      icon: type,
      confirmButtonText: 'Print',
       //confirmButtonText1: 'Cancel'
    })
  }
  //Global Method to retrieve data 
  GetData(url) {
    return new Promise((resolve, reject) => {
      this.http.get('../assets/WebService.json').subscribe((data: any) => {
        
        this.urlname = data.Webservice;
        this.url = this.urlname + url
        this.http.get(this.url).subscribe(data => {

          resolve(data)
        }, err => {
          reject(err)
        })
      }, err => {
        reject(err)
      })
    });
  }
  PostData(url, PostParam) {
    
    return new Promise((resolve, reject) => {
      
      this.http.get('../assets/WebService.json').subscribe((data: any) => {
       
        this.urlname = data.Webservice;
        url = data.Webservice + url
        this.http.post(url, PostParam).subscribe(data => {
          
          resolve(data)
        }, err => {
          reject(err)
        })
      }, err => {
        reject(err)
      })
    });
  }
  PostData2(url, PostParam, PostParam2) {
   
    return new Promise((resolve, reject) => {
      this.http.get('../assets/WebService.json').subscribe((data: any) => {
        url = data.Webservice + url
        this.http.post(url, PostParam, PostParam2).subscribe(data => {
          resolve(data)
        }, err => {
          reject(err)
        })
      }, err => {
        reject(err)
      })
    });
  }
  GetDataWithFlag(url, PostParam) {
    return new Promise((resolve, reject) => {
      this.http.get('../assets/WebService.json').subscribe((data: any) => {
        url = data.Webservice + url
        this.http.get(url, PostParam).subscribe(data => {
          resolve(data)
        }, err => {
          reject(err)
        })
      }, err => {
        reject(err)
      })
    });
  }






  //AddRecent() {
  //


  //  let Array = [];
  //  Array.push({
  //    MenuId: this.MenuId, UserId: this.LoginDetails.logindetails.Id
  //  })
  //   return this.http.get('../assets/WebService.json').subscribe((data: any) => {
  //    var UploadFile = new FormData();
  //    UploadFile.append("Recent", JSON.stringify(Array));
  //    this.urlname = data.Webservice + '/api/Values/AddRecent';
  //    this.http.post(this.urlname, UploadFile).subscribe((data1: any) => {
  //      
  //      if (data1 == "Exists") {
  //        
  //        var UploadFile = new FormData();
  //        UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
  //        UploadFile.append("flag", '1');
  //        this.urlname = data.Webservice + '/api/Values/GetSideMenusWithRecentPinned';
  //        this.http.post(this.urlname, UploadFile).subscribe(data2 => {
  //          
  //          this.LodDetails = data2;
  //          localStorage.removeItem('SideMenus')
  //          localStorage.setItem("SideMenus", JSON.stringify(this.LodDetails.sideMenus));
  //          this.SideMenus = JSON.parse(localStorage.getItem('SideMenus'));
  //          this.SideMenusAry = []
  //          this.MainSideMenusAry = []
  //          
  //          for (var i = 0; i < this.SideMenus.length; i++) {
  //            this.SideMenusAry.push([new Menu(
  //              this.SideMenus[i].Id
  //              , this.SideMenus[i].Title
  //              , this.SideMenus[i].RouterLink
  //              , this.SideMenus[i].Href
  //              , this.SideMenus[i].Icon
  //              , this.SideMenus[i].Target
  //              , this.SideMenus[i].HasSubMenu
  //              , this.SideMenus[i].ParentId
  //            )])
  //          }
  //          
  //          for (var i = 0; i < this.SideMenusAry.length; i++) {
  //            this.MainSideMenusAry.push(this.SideMenusAry[i][0])
  //          }
  //          
  //          return this.MainSideMenusAry;
  //          //this.menuService.RefreshMenu(this.MainSideMenusAry, this.settings.theme.menu);
  //        });
  //      }

  //    });
  //  });
  //}



  
  SIdeMenus(SideMenus) {
    
    this.LodDetails = SideMenus;
    this.SideMenusAry = []
    this.MainSideMenusAry = []
    localStorage.removeItem('SideMenus')
    localStorage.setItem("SideMenus", JSON.stringify(this.LodDetails.sideMenus));
    this.SideMenus = JSON.parse(localStorage.getItem('SideMenus'));
   
    //this.SideMenus = SideMenus;
    
    for (var i = 0; i < this.SideMenus.length; i++) {
      var RouterLink = this.SideMenus[i].RouterLink.replace("%20", " ");
      this.SideMenusAry.push([new Menu(
        this.SideMenus[i].Id
        , this.SideMenus[i].Title
        , RouterLink
        , this.SideMenus[i].Href
        , this.SideMenus[i].Icon
        , this.SideMenus[i].Target
        , this.SideMenus[i].HasSubMenu
        , this.SideMenus[i].ParentId
      )])
    }
    
    for (var i = 0; i < this.SideMenusAry.length; i++) {
      this.MainSideMenusAry.push(this.SideMenusAry[i][0])
    }
    
    return this.MainSideMenusAry
  }



}
