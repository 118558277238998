
<p-tabView (onChange)="handleChange($event)" class="op">
  <p-tabPanel header="Companies" [selected]="MenuId== 1">
    <p-table *ngIf="!companyForm" #dt [columns]="cols" [value]="CompanyData" [scrollable]="true" [style]="{width:'100%'}" datakey="CompanyData" editMode="row" selectionMode="multiple" columnResizeMode="expand" [resizableColumns]="true">
      <ng-template pTemplate="caption">
        <div class="row">
          <div class="col-lg-6" style="text-align:justify">
            <button type="button" class="btn" routerLink="/Admin/Main"><img class="tableheadericons" src="../../../../assets/img/icons/back.png" />Back</button>&nbsp;
            <button type="button" class="btn" (click)="OpenAddFormCompany()"><img class="tableheadericons" src="../../../../assets/img/icons/new.png" /> New</button> &nbsp;
            <button type="button" class="btn" pSortableColumn="CompanyName"><img class="tableheadericons" src="../../../../assets/img/icons/sort.png" /> <p-sortIcon hidden field="CompanyName"></p-sortIcon>Sort</button>
            <button type="button" style="" class="btn" *ngIf="!Pinned" (click)="Pined()"><img class="tableheadericons" src="https://img.icons8.com/dotty/80/000000/pin3.png" /> Pin</button>
            <button type="button" style="" class="btn" *ngIf="Pinned" (click)="UnPin()"><img class="tableheadericons" src="https://img.icons8.com/dusk/64/000000/pin3.png" /> Un Pin</button>
            <!--<i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top"
            aria-label="" (click)="dt.exportCSV()" style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:8px;"></i>-->
          </div>
          <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
            <!--<i class="fa fa-search" style="margin:4px 4px 0 0"></i>-->
            {{dt.filteredValue?dt.filteredValue.length:dt.totalRecords}} of {{dt.totalRecords}}
            <input type="text" class="global_search" pInputText size="50" placeholder="Filter By…" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
            <div class="btn-group">
              <button type="button" class="btn dropdown-toggle no-caret bg-transparent border-0 box-shadow-none" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i style="font-size: 18px" class="fa fa-ellipsis-v"></i>
              </button>
              <div class="dropdown-menu dropdown-menu-right box-shadow rounded-0">
                <a class="dropdown-item" href="javascript:void(0);" (click)="refresh()"><i class="fa fa-refresh"></i> Refresh</a>
                <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-cloud-upload"></i> Import</a>
                <a class="dropdown-item" href="javascript:void(0);" (click)="dt.exportCSV()"><i class="fa fa-file-excel-o"></i> Export</a>
                <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-print"></i> Print</a>
                <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-history"></i> History</a>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col style="width:36px">
          <col style="width:35px">
          <col style="width:50px">
          <col style="width:200px">
          <col style="width:170px">
          <col style="width:150px">
          <col style="width:170px">
          <col style="width:170px">
          <col style="width:130px">
          <col style="width:130px">
          <col style="width:130px">
          <col style="width:170px">
          <col style="width:150px">
          <col style="width:170px">
        </colgroup>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th></th>
          <th></th>
          <th>
            <div class="p-d-flex p-jc-between p-ai-center">
              ID
              <p-columnFilter type="numeric" field="Id" display="menu"></p-columnFilter>
            </div>
          </th>
          <th pResizableColumn>
            <div class="p-d-flex p-jc-between p-ai-center">
              Company Name
              <p-columnFilter type="text" field="CompanyName" display="menu"></p-columnFilter>
            </div>
          </th>

          <th pResizableColumn>
            <div class="p-d-flex p-jc-between p-ai-center">
              Status
              <p-columnFilter type="text" field="CompanyStatus" display="menu"></p-columnFilter>
            </div>
          </th>

          <th pResizableColumn>
            Company Type
          </th>
          <th pResizableColumn>Company Address</th>
          <th pResizableColumn>POC Name</th>
          <th pResizableColumn>POC Mobile</th>
          <th pResizableColumn>POC Email</th>
          <th>
            <div class="p-d-flex p-jc-between p-ai-center">
              Created on
              <p-columnFilter type="date" field="CreatedDate" display="menu"></p-columnFilter>
            </div>
          </th>
          <th pResizableColumn>Created By</th>
          <th>
            <div class="p-d-flex p-jc-between p-ai-center">
              Last Modified
              <p-columnFilter type="date" field="ModifiedDate" display="menu"></p-columnFilter>
            </div>
          </th>
          <th pResizableColumn>Modified by</th>
        </tr>

      </ng-template>
      <ng-template pTemplate="body" let-rowData let-editing="editing" let-rowIndex="rowIndex">
        <tr [pEditableRow]="rowData">
          <td class="stateicon">
            <img pTooltip="Delete" src="../../../../assets//img/Info/delete.png" (click)="onDeleteCompany(rowData)" style="font-size: 15px;width:26;color:red" aria-hidden="true">
          </td>
          <!--<td class="stateicon">
    <i class="fa fa-pencil" pTooltip="Edit" style="  font-size: 12px;" (click)="CompanyEdit(rowData)"></i> <span class="icon-name"></span>
  </td>-->
          <td class="stateicon">
            <img pTooltip="Active" *ngIf="rowData.Status==true" src="../../../../assets//img/Info/check.png" (click)="changeCompanyStatus(rowData,false)" />
            <img pTooltip="Deactive" *ngIf="rowData.Status==false" src="../../../../assets//img/Info/closed.png" (click)="changeCompanyStatus(rowData,true)" />
          </td>
          <!--<td *ngIf="rowData.Status==true" style="text-align:center;">
    <img src="../../../../assets/img/check.png" />

  </td>
  <td *ngIf="rowData.Status==false" style="text-align:center;">
    <img src="../../../../assets/img/closed.png" />
  </td>-->
          <td class="rowEditCursor" (click)="CompanyEdit(rowData)" style="margin-left:3px">
            {{rowData.Id }}
          </td>
          <td class="rowEditCursor" [pTooltip]="rowData.CompanyName" tooltipStyleClass="tooltip" (click)="CompanyEdit(rowData)">
            {{rowData.CompanyName}}
          </td>

          <td (click)="CompanyEdit(rowData)">
            {{rowData.CompanyStatus}}
          </td>

          <td (click)="CompanyEdit(rowData)">
            {{rowData.CompanyType}}
          </td>
          <td [pTooltip]="rowData.Address" tooltipStyleClass="tooltip" (click)="CompanyEdit(rowData)">
            {{rowData.Address}}
          </td>
          <td [pTooltip]="rowData.POCName" tooltipStyleClass="tooltip" (click)="CompanyEdit(rowData)">
            {{rowData.POCName}}
          </td>
          <td (click)="CompanyEdit(rowData)">
            {{rowData.POCMobile}}
          </td>
          <td [pTooltip]="rowData.POCEmail" tooltipStyleClass="tooltip" (click)="CompanyEdit(rowData)">
            {{rowData.POCEmail}}
          </td>
          <td>

            {{rowData.CreatedDate | date:'dd/MMM/yyyy'}}
          </td>
          <td>
            {{rowData.CreatedUser}}
          </td>
          <td>
            {{rowData.ModifiedDate | date:'dd/MMM/yyyy'}}
          </td>
          <td>

            {{rowData.ModifiedUser}}
          </td>
        </tr>
      </ng-template>
      <!--<button type="button" class="btn btn-primary" (click)="OpenAddForm()" style="margin-top: 35%;">Add</button>-->

    </p-table>



    <div *ngIf="companyForm">
      <button type="button" class="btn" (click)="back()"><img class="tableheadericons" src="../../../../assets/img/icons/back.png" />Back</button>&nbsp;

      <button type="button" *ngIf="companyFlag==false" class="btn " (click)="UpdateCompany()">Update</button>
      <button type="button" *ngIf="companyFlag==true" class="btn " (click)="AddCompany()">
        <img class="tableheadericons" src="../../../../assets/img/icons/new.png" /> Save
      </button>
      
      <!--<span style="text-align:left;margin-left:4px;font-size: 15px;font-weight: 500;" class="modal-title" id="modal-primary-label">{{ModelTitleCompany}}</span>-->
      <div class="row" style="margin-top: 2%;">
        <div class="col-lg-4">
          <label>Company Name </label>
          <div>
            <input type="text" class="form-control" [(ngModel)]="CompanyName" name="CompanyName " placeholder="Enter Company Name ">
          </div>
        </div>
        <div class="col-lg-4">
          <label>Company Status </label>
          <select class="custom-select" [(ngModel)]="CompanyStatusDropdown">
            <option value="0" disabled>Select Company Status</option>
            <option *ngFor="let item of CompanyStatusdata" [value]="item.Id">{{item.CompanyStatus}}</option>
          </select>
        </div>

        <div class="col-lg-4">
          <label>Company Type  </label>
          <div>
            <select class="custom-select" [(ngModel)]="CompanyTypeDropdown">
              <option value="0" disabled>Select Company Type</option>
              <option *ngFor="let item of CompanyTypedataforDropdown" [value]="item.Id">{{item.CompanyType}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <label>Company Address </label>
          <div>
            <input type="text" class="form-control" [(ngModel)]="CompanyAddress" name="CompanyAddress" placeholder="Enter Company Address">
          </div>
        </div>
        <div class="col-lg-4">
          <label>Point of Contact Name</label>
          <div>
            <input type="text" class="form-control" [(ngModel)]="POCName" name="PointofContact" placeholder="Enter Point of Contact">
          </div>
        </div>
        <div class="col-lg-4">
          <label>Point of Contact Mobile</label>
          <div>
            <input type="text" class="form-control" [(ngModel)]="POCMobile" name="PointofContactMobile" placeholder="Enter Point of Contact Mobile">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <label>Point of Contact Email</label>
          <div>
            <input type="text" class="form-control" [(ngModel)]="POCEmail" name="PointofContactEmail" placeholder="Enter Point of Contact Email">
          </div>
        </div>
        <div class="col-lg-4">

        </div>
        <div class="col-lg-4" style="text-align:right">
          <!--<button type="button" style="margin-top: 9%;" *ngIf="companyFlag==false" class="btn btn-primary" (click)="UpdateCompany()">Update</button>
      <button type="button" style="margin-top: 9%;" *ngIf="companyFlag==true" class="btn btn-primary" (click)="AddCompany()">Add</button>-->

        </div>
      </div>

    </div>





    <!-- #region Popup -->
    <div class="modal" id="myModalCompany">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header state modal-primary">
            <h5 style="text-align:left;" class="modal-title" id="modal-primary-label">{{ModelTitleCompany}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" style="color:white">×</span></button>
          </div>
          <div class="modal-body">
            <!--<div class="form-group row">
              <label class="col-lg-3 col-md-3 col-sm-12 col-xs-12">State </label>
              <div class="col-sm-10">
                <select class="custom-select" [(ngModel)]="State"  >
                  <option value="" selected >Select State</option>
                  <option [value]="1">Active</option>
                  <option [value]="0">Inactive</option>
                </select>
              </div>
            </div>-->
            <div class="form-group row">
              <label class="col-lg-3 col-md-3 col-sm-12 col-xs-12">Company Status </label>
              <div class="col-sm-10">
                <select class="custom-select" [(ngModel)]="CompanyStatusDropdown">
                  <option value="0" disabled>Select Company Status</option>
                  <option *ngFor="let item of CompanyStatusdata" [value]="item.Id">{{item.CompanyStatus}}</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-3 col-md-3 col-sm-12 col-xs-12">Company Name </label>
              <div class="col-sm-10">
                <input type="text" class="form-control" [(ngModel)]="CompanyName" name="CompanyName " placeholder="Enter Company Name ">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-3 col-md-3 col-sm-12 col-xs-12">Company Type  </label>
              <div class="col-sm-10">
                <select class="custom-select" [(ngModel)]="CompanyTypeDropdown">
                  <option value="0" disabled>Select Company Type</option>
                  <option *ngFor="let item of CompanyTypedata" [value]="item.Id">{{item.CompanyType}}</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-md-4 col-sm-12 col-xs-12">Company Address </label>
              <div class="col-sm-10">
                <input type="text" class="form-control" [(ngModel)]="CompanyAddress" name="CompanyAddress" placeholder="Enter Company Address">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-3 col-md-3 col-sm-12 col-xs-12">Point of Contact Name</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" [(ngModel)]="POCName" name="PointofContact" placeholder="Enter Point of Contact">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-3 col-md-3 col-sm-12 col-xs-12">Point of Contact Mobile</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" [(ngModel)]="POCMobile" name="PointofContactMobile" placeholder="Enter Point of Contact Mobile">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-3 col-md-3 col-sm-12 col-xs-12">Point of Contact Email</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" [(ngModel)]="POCEmail" name="PointofContactEmail" placeholder="Enter Point of Contact Email">
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-10">
                <button type="button" *ngIf="companyFlag==false" class="btn btn-primary" (click)="UpdateCompany()">Update</button>
                <button type="button" *ngIf="companyFlag==true" class="btn btn-primary" (click)="AddCompany()">Add</button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- #endregion -->





  </p-tabPanel>




  <!--<div class="card">
    <h5>Row Editing</h5>
    <p-table [value]="products2" dataKey="id" editMode="row">
      <ng-template pTemplate="header">
        <tr>
          <th>Code</th>
          <th>Name</th>
          <th>Category</th>
          <th>Quantity</th>
          <th style="width:8rem"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="product">
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="product.code">
              </ng-template>
              <ng-template pTemplate="output">
                {{product.code}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="product.name" required>
              </ng-template>
              <ng-template pTemplate="output">
                {{product.name}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="product.inventoryStatus" required>
              </ng-template>
              <ng-template pTemplate="output">
                {{product.inventoryStatus}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="product.price">
              </ng-template>
              <ng-template pTemplate="output">
                {{product.price | currency: 'USD'}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td style="text-align:center">
            <div *ngIf="!editing" pInitEditableRow (click)="onRowEditInit(product)">
              <i class="fa fa-pencil" pTooltip="Edit"></i>
            </div>
           <!-- <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(product)" class="p-button-rounded p-button-text"></button>-->
  <!--  <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(product)" class="p-button-rounded p-button-text p-button-success p-mr-2"></button>-->
  <!--  <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(product, ri)" class="p-button-rounded p-button-text p-button-danger"></button>-->
  <!--<span class="" *ngIf="editing">
              <img src="../../../../assets//img/Info/check.png" />
            </span>
            <span class="" *ngIf="editing"> <img pTooltip="Delete" src="../../../../assets//img/Info/delete.png" /></span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>-->






 



  <p-tabPanel header="Company Status" [selected]="MenuId== 2">
    <!--<p-table #dt2 [columns]="cols1" [value]="CompanyStatusdata" datakey="CompanyStatusdata" editMode="row" columnResizeMode="expand" [resizableColumns]="true">
      <ng-template pTemplate="caption">
        <div class="row">
          <div class="col-lg-6" style="text-align:justify">
            <button type="button" class="btn" routerLink="/Admin/Main"><img class="tableheadericons" src="../../../../assets/img/icons/back.png" />Back</button>&nbsp;
            <button type="button" class="btn" (click)="OpenAddForm()"><img class="tableheadericons" src="../../../../assets/img/icons/new.png" /> New</button> &nbsp;
            <button type="button" class="btn" pSortableColumn="CompanyStatus"><img class="tableheadericons" src="../../../../assets/img/icons/sort.png" /><p-sortIcon hidden field="CompanyStatus"></p-sortIcon>Sort</button>
          </div>
          <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
            {{dt2.filteredValue?dt2.filteredValue.length:dt2.totalRecords}} of {{dt2.totalRecords}}
            <input type="text" class="global_search" pInputText size="50" placeholder="Filter By…" (input)="dt2.filterGlobal($event.target.value, 'contains')" style="width:auto">
            <div class="btn-group">
              <button type="button" class="btn dropdown-toggle no-caret bg-transparent border-0 box-shadow-none" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i style="font-size: 18px" class="fa fa-ellipsis-v"></i>
              </button>
              <div class="dropdown-menu dropdown-menu-right box-shadow rounded-0">
                <a class="dropdown-item" href="javascript:void(0);" (click)="refresh()"><i class="fa fa-refresh"></i> Refresh</a>
                <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-cloud-upload"></i> Import</a>
                <a class="dropdown-item" href="javascript:void(0);" (click)="dt2.exportCSV()"><i class="fa fa-file-excel-o"></i> Export</a>
                <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-print"></i> Print</a>
                <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-history"></i> History</a>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th class="stateicon"></th>
          <th class="stateicon"></th>

          <th pResizableColumn>
            <div class="p-d-flex p-jc-between p-ai-center">
              Company Status
              <p-columnFilter type="text" field="CompanyStatus" display="menu"></p-columnFilter>
            </div>
          </th>
          <th>
            <div class="p-d-flex p-jc-between p-ai-center">
              Created Date
              <p-columnFilter type="date" field="CreatedDate" display="menu"></p-columnFilter>
            </div>
          </th>
          <th pResizableColumn>
            <div class="p-d-flex p-jc-between p-ai-center">
              Created By
              <p-columnFilter type="text" field="CreateBy" display="menu"></p-columnFilter>
            </div>
          </th>
          <th>
            <div class="p-d-flex p-jc-between p-ai-center">
              Modified Date
              <p-columnFilter type="date" field="ModifiedDate" display="menu"></p-columnFilter>
            </div>
          </th>
          <th pResizableColumn>
            <div class="p-d-flex p-jc-between p-ai-center">
              Modified By
              <p-columnFilter type="text" field="ModifiedBy" display="menu"></p-columnFilter>
            </div>
          </th>
        </tr>

      </ng-template>
      <ng-template pTemplate="body" let-rowData let-editing="editing" let-rowIndex="rowIndex">
        <tr [pEditableRow]="rowData">
          <td class="stateicon">
            <img pTooltip="Delete" src="../../../../assets//img/Info/delete.png" (click)="onDelete(rowData)" style="font-size: 15px;color:red" aria-hidden="true">
          </td>
          <td class="stateicon">
            <i class="fa fa-pencil" pTooltip="Edit" pInitEditableRow style="  font-size: 15px;" (click)="onRowEditInit(rowData)"></i> <span class="icon-name"></span>

          </td>

          <td style="width:15px !important" pEditableRow>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText [(ngModel)]="rowData.CompanyStatus" (change)="TextBoxChange(rowData,CompanyStatus)" />
              </ng-template>
              <ng-template pTemplate="output">
                {{rowData.CompanyStatus || 0}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            {{rowData.CreatedDate | date:'dd/MMM/yyyy H:m'}}
          </td>
          <td>
            {{rowData.CreateBy}}
          </td>
          <td>
            {{rowData.ModifiedDate | date:'dd/MMM/yyyy H:m'}}
          </td>
          <td>
            {{rowData.ModifiBy}}
          </td>
        </tr>
      </ng-template>
    </p-table>
    <br />
    <br />-->


    <p-table #dt2 [columns]="cols1" [value]="CompanyStatusdata" datakey="CompanyStatusdata" editMode="row" columnResizeMode="expand" [resizableColumns]="true">
      <ng-template pTemplate="caption">
        <div class="row">
          <div class="col-lg-6" style="text-align:justify">
            <button type="button" class="btn" routerLink="/Admin/Main"><img class="tableheadericons" src="../../../../assets/img/icons/back.png" />Back</button>&nbsp;
            <button type="button" class="btn" (click)="OpenAddForm()"><img class="tableheadericons" src="../../../../assets/img/icons/new.png" /> New</button> &nbsp;
            <button type="button" class="btn" pSortableColumn="CompanyStatus"><img class="tableheadericons" src="../../../../assets/img/icons/sort.png" /><p-sortIcon hidden field="CompanyStatus"></p-sortIcon>Sort</button>
            <button type="button" style="" class="btn" *ngIf="!Pinned" (click)="Pined()"><img class="tableheadericons" src="https://img.icons8.com/dotty/80/000000/pin3.png" /> Pin</button>
            <button type="button" style="" class="btn" *ngIf="Pinned" (click)="UnPin()"><img class="tableheadericons" src="https://img.icons8.com/dusk/64/000000/pin3.png" /> Un Pin</button>
          </div>
          <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
            <!--<i class="fa fa-search" style="margin:4px 4px 0 0"></i>-->
            {{dt2.filteredValue?dt2.filteredValue.length:dt2.totalRecords}} of {{dt2.totalRecords}}
            <input type="text" class="global_search" pInputText size="50" placeholder="Filter By…" (input)="dt2.filterGlobal($event.target.value, 'contains')" style="width:auto">
            <div class="btn-group">
              <button type="button" class="btn dropdown-toggle no-caret bg-transparent border-0 box-shadow-none" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i style="font-size: 18px" class="fa fa-ellipsis-v"></i>
              </button>
              <div class="dropdown-menu dropdown-menu-right box-shadow rounded-0">
                <a class="dropdown-item" href="javascript:void(0);" (click)="refresh()"><i class="fa fa-refresh"></i> Refresh</a>
                <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-cloud-upload"></i> Import</a>
                <a class="dropdown-item" href="javascript:void(0);" (click)="dt2.exportCSV()"><i class="fa fa-file-excel-o"></i> Export</a>
                <a class="dropdown-item" href="javascript:void(0);" (click)="onPrint()"><i class="fa fa-print"></i> Print</a>
                <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-history"></i> History</a>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width:55px"></th>
          <th style="width:55px">
            <div class="p-d-flex p-jc-between p-ai-center">
             ID
              <p-columnFilter type="text" field="Id" display="menu"></p-columnFilter>
            </div>
          </th>
          <th pResizableColumn>
            <div class="p-d-flex p-jc-between p-ai-center">
              Company Status
              <p-columnFilter type="text" field="CompanyStatus" display="menu"></p-columnFilter>
            </div>
          </th>
          <th> Created Date</th>
          <th>Created By</th>
          <th>Modified Date</th>
          <th>Modified By</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr>
          <td class="stateicon">
            <img pTooltip="Delete" src="../../../../assets//img/Info/delete.png" (click)="onDelete(product)" style="font-size: 15px;color:red" aria-hidden="true">
          </td>
          <td>{{product.Id}}</td>
          <td class="rowEditCursor" pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="product.CompanyStatus" (change)="TextBoxChange(product,CompanyStatus)">
              </ng-template>
              <ng-template pTemplate="output">
                {{product.CompanyStatus}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            {{product.CreatedDate | date:'dd/MMM/yyyy H:m'}}
          </td>
          <td>
            {{product.CreateBy}}
          </td>
          <td>
            {{product.ModifiedDate | date:'dd/MMM/yyyy H:m'}}
          </td>
          <td>
            {{product.ModifiBy}}
          </td>
        </tr>
      </ng-template>
    </p-table>


























    <!-- #region Popup for view orders-->
    <div class="modal" id="myModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header state modal-primary">
            <h5 style="text-align:left;" class="modal-title" id="modal-primary-label">{{ModelTitle}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" style="color:white">×</span></button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-lg-3 col-md-3 col-sm-12 col-xs-12">CompanyStatus</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" [(ngModel)]="CompanyStatus" name="CompanyStatus" placeholder="Enter Company Status">
              </div>
              <button type="button" class="btn btn-primary" (click)="AddCompanyStatus()">Add</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- #endregion -->
  </p-tabPanel>

  
  <p-tabPanel header="Company Type" [selected]="MenuId== 3">

    <p-table #dt3 [columns]="cols" [value]="CompanyTypedata" datakey="CompanyTypedata" editMode="row" selectionMode="multiple" [resizableColumns]="true" columnResizeMode="expand">
      <ng-template pTemplate="caption">
        <div class="row">
          <div class="col-lg-6" style="text-align:justify">
            <button type="button" class="btn" routerLink="/Admin/Main"><img class="tableheadericons" src="../../../../assets/img/icons/back.png" />Back</button>&nbsp;
            <button type="button" class="btn" (click)="OpenAddFormType()"><img class="tableheadericons" src="../../../../assets/img/icons/new.png" /> New</button> &nbsp;
            <button type="button" class="btn" pSortableColumn="CompanyType"><img class="tableheadericons" src="../../../../assets/img/icons/sort.png" /><p-sortIcon hidden field="CompanyType"></p-sortIcon>Sort</button>
            <button type="button" style="" class="btn" *ngIf="!Pinned" (click)="Pined()"><img class="tableheadericons" src="https://img.icons8.com/dotty/80/000000/pin3.png" /> Pin</button>
            <button type="button" style="" class="btn" *ngIf="Pinned" (click)="UnPin()"><img class="tableheadericons" src="https://img.icons8.com/dusk/64/000000/pin3.png" /> Un Pin</button>
          </div>
          <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
            <!--<i class="fa fa-search" style="margin:4px 4px 0 0"></i>-->
            {{dt3.filteredValue?dt3.filteredValue.length:dt3.totalRecords}} of {{dt3.totalRecords}}
            <input type="text" class="global_search" pInputText size="50" placeholder="Filter By…" (input)="dt3.filterGlobal($event.target.value, 'contains')" style="width:auto">
            <div class="btn-group">
              <button type="button" class="btn dropdown-toggle no-caret bg-transparent border-0 box-shadow-none" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i style="font-size: 18px" class="fa fa-ellipsis-v"></i>
              </button>
              <div class="dropdown-menu dropdown-menu-right box-shadow rounded-0">
                <a class="dropdown-item" href="javascript:void(0);" (click)="refresh()"><i class="fa fa-refresh"></i> Refresh</a>
                <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-cloud-upload"></i> Import</a>
                <a class="dropdown-item" href="javascript:void(0);" (click)="dt3.exportCSV()"><i class="fa fa-file-excel-o"></i> Export</a>
                <a class="dropdown-item" href="javascript:void(0);"  (click)="onPrint()"><i class="fa fa-print"></i> Print</a>
                <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-history"></i> History</a>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th class="stateicon"></th>
          <th class="stateicon"></th>
          <th class="stateicon">ID</th>
          <th pResizableColumn>
            <div class="p-d-flex p-jc-between p-ai-center">
              Company Type
              <p-columnFilter type="text" field="CompanyType" display="menu"></p-columnFilter>
            </div>
          </th>
          <th>
            <div class="p-d-flex p-jc-between p-ai-center">
              Created Date
              <p-columnFilter type="date" field="CreatedDate" display="menu"></p-columnFilter>
            </div>
          </th>
          <th pResizableColumn>
            <div class="p-d-flex p-jc-between p-ai-center">
              Created By
              <p-columnFilter type="text" field="CreateBy" display="menu"></p-columnFilter>
            </div>
          </th>
          <th>
            <div class="p-d-flex p-jc-between p-ai-center">
              Modified Date
              <p-columnFilter type="date" field="ModifiedDate" display="menu"></p-columnFilter>
            </div>
          </th>
          <th pResizableColumn>
            <div class="p-d-flex p-jc-between p-ai-center">
              Modified By
              <p-columnFilter type="text" field="ModifiedBy" display="menu"></p-columnFilter>
            </div>
          </th>
        </tr>

      </ng-template>
      <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="rowData">
          <td class="stateicon">
            <img pTooltip="Delete" src="../../../../assets//img/Info/delete.png" (click)="onDeleteCompanyType(rowData)" style="font-size: 15px;color:red" aria-hidden="true">
          </td>
          <td class="stateicon">
            <img pTooltip="Active" *ngIf="rowData.Status==true" src="../../../../assets//img/Info/check.png" (click)="changeStatus(rowData,false)" />
            <img pTooltip="Delete" *ngIf="rowData.Status==false" src="../../../../assets//img/Info/closed.png" (click)="changeStatus(rowData,true)" />
          </td>
          <td class="stateicon">
            {{rowData.Id}}
            <!--  <i class="fa fa-pencil" pTooltip="Edit" pInitEditableRow style="  font-size: 15px;" (click)="onRowEditInit(rowData)"></i> <span class="icon-name"></span>-->
          </td>

          <td class="rowEditCursor" pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="rowData.CompanyType" (change)="TextBoxChangeCompanyType(rowData,CompanyType)" />
              </ng-template>
              <ng-template pTemplate="output">
                {{rowData.CompanyType}}
              </ng-template>
            </p-cellEditor>
          </td>

          <td>
            {{rowData.CreatedDate | date: 'dd/MMM/yyyy H:m'}}
          </td>
          <td>
            {{rowData.CreateBy}}
          </td>
          <td>
            {{rowData.ModifiedDate | date: 'dd/MMM/yyyy'}}
          </td>
          <td>
            {{rowData.ModifiBy}}
          </td>
        </tr>
      </ng-template>
      <!--<button type="button" class="btn btn-primary" (click)="OpenAddForm()" style="margin-top: 35%;">Add</button>-->

    </p-table>



    <!-- #region Popup for view orders-->
    <div class="modal" id="myModalCompanyType">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header state modal-primary">
            <h5 style="text-align:left;" class="modal-title" id="modal-primary-label">{{ModelTitleCompanyType}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" style="color:white">×</span></button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-lg-3 col-md-3 col-sm-12 col-xs-12">CompanyType</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" [(ngModel)]="CompanyType" name="CompanyType" placeholder="Enter CompanyType">
              </div>
              <button type="button" class="btn btn-primary" (click)="AddCompanyType()">Add</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- #endregion -->

  </p-tabPanel>

</p-tabView>
<style>
  .components {
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 25px;
    margin-top: 16px;
    font-weight: bold;
  }

  .back {
    background: white !important;
    height: 20px !important;
    padding: 1px 7px 7px 7px !important;
    font-size: 13px !important;
  }

  .app.combined .breadcrumb {
    padding: 0.5rem 1rem;
    display: none !important;
  }

  ._card {
    border: 0.5px solid #dedede;
    padding: 15px;
    border-radius: 4px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  }

  .head_txt {
    font-size: 12px;
    letter-spacing: 0.2px;
    line-height: 24px;
  }

  .form-control {
    display: block;
    width: 100%;
    height: 35px !important;
    padding: 3px;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 10px !important;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #787a7b;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .top_head {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 5px;
    color: #000;
    letter-spacing: 0.5px;
  }

  .divRPimg {
    height: auto;
    padding: 10px 0;
    display: block;
    margin-right: 3%;
    border-radius: 3px;
    box-shadow: 0 4px 9px 0 rgba(67, 65, 79, 0.1);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
</style>
<style>
 
</style>
