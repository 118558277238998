import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
//import { GeneralService } from '../../pages/Services/general.service';
import { GeneralService } from '../../Services/general.service'
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { formatDate } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { FilterMatchMode } from 'primeng/api';
import Swal from 'sweetalert2';
import { MenuService } from '../../../theme/components/menu/menu.service';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';

declare var $;
@Component({
  selector: 'app-workability-type',
  templateUrl: './workability-type.component.html',
  styleUrls: ['./workability-type.component.scss'],
  providers: [DatePipe, MessageService, MenuService]
})
export class WorkabilityTypeComponent implements OnInit {
  Status: any;
  LoginDetails: any;
  ModelTitle: string;
  templateoriginal: any;
  template: any;
  flag: any;
  WorkabiltyType: any;
  cols: any;
  addNew: boolean;
  editForm: boolean;
  activitytype: any;
  Id: any;
  public form: FormGroup;
  Grid: boolean = true;
  myDate = new Date();
  todaydate: any;
  Pinned: boolean;
  PinId: any;
  MenuId: any;
  public settings: Settings;
  currentPageRoute: any;
  constructor(public config: PrimeNGConfig, public menuService: MenuService, public appSettings: AppSettings,
public router: Router, public toaster: ToastrService, public messageService: MessageService, public fb: FormBuilder, public generalService: GeneralService, private datePipe: DatePipe) {
    this.config.filterMatchModeOptions = {
      text: [
        FilterMatchMode.STARTS_WITH,
        FilterMatchMode.CONTAINS,
        FilterMatchMode.NOT_CONTAINS,
        FilterMatchMode.ENDS_WITH,
        FilterMatchMode.EQUALS,
        FilterMatchMode.NOT_EQUALS,
        FilterMatchMode.LESS_THAN,
        FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
        FilterMatchMode.GREATER_THAN,
        FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
      ],
      numeric: [
        FilterMatchMode.EQUALS,
        FilterMatchMode.NOT_EQUALS,
        FilterMatchMode.LESS_THAN,
        FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
        FilterMatchMode.GREATER_THAN,
        FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
      ],
      date: [
        FilterMatchMode.BEFORE,
        FilterMatchMode.AFTER,
      ]
    }

    //this.myDate = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    this.todaydate = formatDate(this.myDate, 'yyyy-MM-dd hh:mm:ss', 'en-US', '+0530');
    this.LoginDetails = JSON.parse(localStorage.getItem('LoginDetails'))
    this.form = this.fb.group({
      activitytype: ['', Validators.required]
    });
    this.settings = this.appSettings.settings;
    this.MenuId = 0;
    this.router.events.subscribe((val) => {

      this.currentPageRoute = this.router.url; // Current page route

    });
  }

  ngOnInit(): void {
    this.GetPinned();
    this.getWorkabiltyType();
    /*Scripting code*/
    //debugger
    //$(document).ready(function () {
    //  var table = $('#dt').DataTable();

    //  table.on('draw', function () {
    //    var body = $(table.table().body());

    //    body.unhighlight();
    //    body.highlight(table.search());
    //  });
    //});
  }
  //Get Pinned
  //=======print====//
  onPrint() {
    window.print();
  }
  Pined() {
    debugger
    this.Pinned = true;

    let Array = [];
    Array.push({
      MenuId: this.MenuId, RouterLink: this.currentPageRoute, UserId: this.LoginDetails.logindetails.Id
    })
    var UploadFile = new FormData();
    UploadFile.append("Pinned", JSON.stringify(Array));
    var url = 'api/Values/AddPinned';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data == "SUCCESS") {
        debugger
        this.GetPinned();
        var UploadFile = new FormData();
        UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
        //UploadFile.append("flag", '1');
        var url = 'api/Values/GetSideMenusWithRecentPinned';
        this.generalService.PostData(url, UploadFile).then((data1: any) => {
          debugger
          var Details = this.generalService.SIdeMenus(data1);
          this.menuService.RefreshMenu(Details, this.settings.theme.menu);
          debugger
        });

      }

    });
  }


  UnPin() {
    debugger

    var UploadFile = new FormData();
    UploadFile.append("PinId", this.PinId);
    UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
    var url = 'api/Values/DeletePinned';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data == "SUCCESS") {
        debugger
        this.Pinned = false;
        var UploadFile = new FormData();
        UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
        //UploadFile.append("flag", '1');
        var url = 'api/Values/GetSideMenusWithRecentPinned';
        this.generalService.PostData(url, UploadFile).then((data1: any) => {
          debugger
          var Details = this.generalService.SIdeMenus(data1);
          this.menuService.RefreshMenu(Details, this.settings.theme.menu);
          debugger
        });
      }
    });
  }

  GetPinned() {
    debugger
    var UploadFile = new FormData();
    UploadFile.append("MenuId", this.MenuId);
    UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
    UploadFile.append("RouterLink", this.currentPageRoute);

    var url = 'api/Values/GetPinned';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data.MSG == "EXISTS") {
        debugger
        this.PinId = data.PinId;
        this.Pinned = true;
      }
      else if (data.MSG == "NOT EXISTS") {
        this.Pinned = false;
      }
    });
  }
  getWorkabiltyType() {
    var url = "api/Master/GetWorkabilityType";
    this.generalService.GetData(url).then((data: any) => {
      if (data != null) {
        var obj = Object.keys(data[0])
        this.cols = [
          { field: 'Id', header: 'Id', width: '150px' },
          { field: 'WorkabiltyType', header: 'WorkabiltyType', width: '150px' },
          { field: 'Status', header: 'Status', width: '150px' },
          { field: 'CreatedDate', header: 'CreatedDate', width: '150px' },
          { field: 'CreateBy', header: 'CreatedBy', width: '150px' },
          { field: 'ModifiedDate', header: 'ModifiedDate', width: '150px' },
          { field: 'ModifiBy', header: 'ModifiedBy', width: '150px' },
        ]
        data.forEach(
          dateType => (dateType.CreatedDate = new Date(dateType.CreatedDate))
        );
        data.forEach(
          dateType => (dateType.ModifiedDate = new Date(dateType.ModifiedDate))
        );
        this.WorkabiltyType = data;
        this.templateoriginal = data;
      }
    }, err => {
      // this.spinner.hide();
      this.generalService.ShowAlert('ERROR', 'Something Went wrong', 'error');
    });
  }

  OpenAddForm() {

    this.ModelTitle = "Add WorkabiltyType";
    $('#myModal').modal('show');
    this.Grid = false;
    this.WorkabiltyType = "";
    this.flag = "1";//for adding
    //this.addNew = true;
    //this.editForm = false;

  }

  TextBoxChange(item, key) {

    let index: number = this.WorkabiltyType.findIndex(t => t.Id == item.Id);
    let index1: number = this.templateoriginal.findIndex(t => t.Id == item.Id);
    if (index > -1) {
      this.WorkabiltyType[index][key] = item[key];
      this.WorkabiltyType = this.WorkabiltyType[index].WorkabiltyType;
      this.Id = item.Id;
    }
    if (index1 > -1) {
      this.templateoriginal[index][key] = item[key];
      this.templateoriginal[index]['ModifiedBy'] = this.LoginDetails.logindetails.Id;
      this.templateoriginal[index]['ModifiedDate'] = this.todaydate;
    }
    // this.onRowUpadte();
    this.flag = "2"//for Update        
    var UploadFile = new FormData();
    UploadFile.append("WorkabilityType", JSON.stringify(this.templateoriginal));
    UploadFile.append("flag", this.flag);
    var url = 'api/Master/AddUpdateWorkabilityTypeMaster';
    this.generalService.PostData(url, UploadFile).then((data: any) => {

      if (data != null) {
        this.getWorkabiltyType(); this.toaster.success('Workabilitytype has been updated Successfully', 'Success', {
          timeOut: 2000,
        });
        this.backform();
      }
    });
  }


  AddWorkabiltyType() {

    let Array = [];
    Array.push({
      WorkabiltyType: this.WorkabiltyType, CreatedBy: this.LoginDetails.logindetails.Id
    })
    var UploadFile = new FormData();
    UploadFile.append("WorkabilityType", JSON.stringify(Array));
    UploadFile.append("flag", this.flag);
    var url = 'api/Master/AddUpdateWorkabilityTypeMaster';
    this.generalService.PostData(url, UploadFile).then((data: any) => {

      this.WorkabiltyType = '';
      if (data != null) {
        this.getWorkabiltyType();
        $('#myModal').modal('hide');
        this.toaster.success('Workabilitytype has been added Successfully', 'Success', {
          timeOut: 2000,
        });
        this.backform();
      }
    });
  }

  onDelete(rowData) {
    //this.messageService.clear();
    //this.messageService.add({ key: 'c', sticky: true, severity: 'warn', summary: 'Are you sure?', detail: 'Confirm to proceed' });
    Swal.fire({
      title: 'Are you sure to Delete  ?',
      //text: "",
      //type: 'warning',
      html: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      debugger
      if (result.value) {
        this.flag = "3";

        let Array = [];
        Array.push({
          WorkabiltyType: rowData.WorkabiltyType, Id: rowData.Id,
        })
        var UploadFile = new FormData();
        UploadFile.append("WorkabilityType", JSON.stringify(Array));
        UploadFile.append("flag", this.flag);
        var url = 'api/Master/AddUpdateWorkabilityTypeMaster';
        this.generalService.PostData(url, UploadFile).then((data: any) => {

          if (data == "success") {
            this.getWorkabiltyType();
            this.generalService.ShowAlert('SUCCESS', 'WorkabiltyType has been Deleted successfully', 'success');
            this.backform();
          }
          else {
            this.generalService.ShowAlert('WARNING', 'You can not delete this record because of it is having relation with another table', 'warning');
            this.backform();
          }
        });
      }
    })
  }


  changeStatus(rowData, status) {
    this.Status = status;

    this.flag = "4";

    let Array = [];
    Array.push({
      Id: rowData.Id, Status: this.Status, ModifiedBy: this.LoginDetails.logindetails.Id, ModifiedDate: this.todaydate
    })
    var UploadFile = new FormData();
    UploadFile.append("WorkabilityType", JSON.stringify(Array));
    UploadFile.append("flag", this.flag);
    var url = 'api/Master/AddUpdateWorkabilityTypeMaster';
    this.generalService.PostData(url, UploadFile).then((data: any) => {

      if (data != null) {
        this.getWorkabiltyType();
        this.backform();
        if (this.Status == true) {
          this.toaster.success('Status Activated Successfully', 'Success', {
            timeOut: 2000,
          });
        }
        else {
          this.toaster.warning('Status Dectivated Successfully', 'Success', {
            timeOut: 2000,
          });
        }
      }
    });
  }

  backform() {
    this.Grid = true;
  }
  refresh() {
    location.reload();
  }

  //createTagAndAppendTo(tag, txt, elem) {
  //  let customTag = document.createElement(tag);
  //  customTag.textContent = txt;
  //  elem.append(customTag);
  //}

  //myFunction() {
  //  debugger
  //  let input, filter, table, tr, td, i, txtValue;
  //  let displayTr = [];
  //  filter = 'a';
  //  table = document.getElementById("tm");
  //  tr = table.getElementsByTagName("tr");
  //  let regExp = new RegExp(filter);
  //  if (!filter) {
  //    for (let i = 0; i < tr.length; i++) {
  //      tr[i].style.display = '';
  //    }
  //    return;
  //  }
  //  for (let i = 0; i < tr.length; i++) {
  //    let trStyle = tr[i].style.display;
  //    td = tr[i].getElementsByTagName("td");
  //    for (let j = 0; j < td.length; j++) {

  //      txtValue = td[j].textContent;

  //      let count = (txtValue.match(regExp) || []).length;
  //      displayTr[i] = displayTr[i] ? displayTr[i] : count;
  //      if (count !== 0) {

  //        td[j].innerText = '';
  //        let strArray = txtValue.split(filter);
  //        let loopLength = strArray.length - 1;

  //        for (let i = 0; i < loopLength; i++) {
  //          this.createTagAndAppendTo('span', strArray[i], td[j]);
  //          this.createTagAndAppendTo('mark', filter, td[j]);
  //        }
  //        this.createTagAndAppendTo('span', strArray[loopLength], td[j]);

  //      } else {
  //        let tdStr = td[j].textContent;
  //        td[j].innerText = '';
  //        td[j].textContent = tdStr;

  //      }
  //    }

  //    if (displayTr[i] !== 0) {
  //      tr[i].style.display = '';
  //    } else {
  //      tr[i].style.display = 'yellow';
  //    }
  //  }
  //}

}

