import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { GeneralService } from '../../Services/general.service'
import { Router, ActivatedRoute } from '@angular/router';
import { UserserviceService } from '../../Services/userservice.service';
import { FormsModule, FormGroup, ReactiveFormsModule, FormBuilder, RequiredValidator, FormArray, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
declare const $: any;
@Component({
  selector: 'app-new-project',
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.scss']
})
export class NewProjectComponent implements OnInit {
  mindate: string
  Clientid: any;
  autofillClients: any[];
  TaskId: any;
  ActivityId: any;
  TypeName: any;
  Priority: number=1;
  InspectId: any;
  Inspectiondata: any;
  InspectionId: string;
  Edit: boolean;
  ProjectIdforEdit: any;
  OwnerId: any;
  ScheduleStart: string = '';
  ScheduleEnd: string='';
  EstimatedHours: any;
  ProjectManager: any;
  Contact: any;
  ActulaEnd: any;
  ActulaStart: any;
  ActualHours: any;
  Ttype: any;
  Tname: any;
  Tid: any;
  pDescription: any;
  TemplateData: any = [];
  TempData: any;
  TemplateTypeId: any;
  autofillTemplateType: any[];
  GroupData: any;
  tName: string;
  jId: any;
  Category: any;
  ProjectStatusData: any;
  addbutton: boolean = true;
  insertTask: boolean = false;
  insertActivities: boolean = false;
  insertSProject: boolean = false;
  removebutton: boolean = false;
  RForm: FormGroup;
  ProjectCode: any;
  ProjectName: any;
  Companydata: any;
  ProjectData: any;
  Templates: any;
  Tasks: any;
  Templatename: any;
  colsTasks: { field: string; header: string; width: string; }[];
  TaskName: string;
  autofillTasks: any[];
  autofillActivities: any;
  autofillTemplates: any;
  Activities: any;
  colsActivities: { field: string; header: string; width: string; }[];
  Jobs: any;
  SubProjectData: any = [];
  ActivityData: any = [];
  TaskData: any = [];
  NewProjectData: any = [];
  ActivityTypeName: any;
  ProjectId: any;
  JobNumber: any;
  colsJobs: { field: string; header: string; width: string; }[];
  autofillCategories: any[];
  LoginDetails: any;
  JobData: any[];
  ButtonText: string;
  SubProjectName: any;
  Client: any;
  Status: any;
  ProjectStatus: any;


  RequestBy         : any;
  requsestId        : any;
  SubProjectId      : any;
  TemplateId        : any;
  InspectionStatus  : any;
  Notes             : any;
  RequiredBy        : any;
  RequestOn: any;


  ProjectDetailsDetails: any;






  constructor(public generalService: GeneralService, public toaster: ToastrService, public datePipe: DatePipe, public formbuilder: FormBuilder, public router: Router) {
    this.LoginDetails = JSON.parse(localStorage.getItem('LoginDetails'))  
    this.ProjectId = JSON.parse(localStorage.getItem('ProjectId'))
    this.ProjectIdforEdit = JSON.parse(localStorage.getItem('Pid'))
    this.getGroups()
    this.getCompany()
    if (this.ProjectIdforEdit=="2") {
      this.Edit = true;
      this.InspectionId = localStorage.getItem('Inspectioncount')
     
      if (this.ProjectIdforEdit=="2") {
        this.ProjectId = JSON.parse(localStorage.getItem('ProjectId'))
        this.getProjectdetailsBasedOnId(this.ProjectId)        
        //this.ProjectId = this.ProjectDetailsDetails.Id;
        //this.ProjectCode = this.ProjectDetailsDetails.ProjectCode ;
        //this.ProjectName = this.ProjectDetailsDetails.ProjectName ;
        //this.ProjectManager = this.ProjectDetailsDetails.Id;
       
      }

    }
    else {
      this.Edit = false      
    }

  }

  ngOnInit(): void {    
    this.GetJobDetailsfordropdown();
    this.GetActivityTypeDetailsfordropdown();
    this.GetTaskDetailsfordropdown();
    this.GetTemplateDetailsForDropdowns();
    this.getCompany();
    this.getProjectStatus();
    this.getGroups();
  }

  //get formData() { return <FormArray>this.RForm.get('details'); }
  //addCreds() {
  //  return this.formbuilder.group({
  //    Desc: [''],
  //  });
  //}
  //addnewRow() {
  //  
  //  this.removebutton = true;
  //  const control = <FormArray>this.RForm.controls['details'];
  //  control.push(this.addCreds());
  //  if (control.length > 0) {
  //    this.addbutton = true;
  //  }
  //}

  OpenJobsPopUp() {
    this.SubProjectName = '';
    this.autofillCategories = [];
    this.ButtonText = 'Add';
    $('#myModal').modal('show');
  }
  OpenActivityTypePopUp() {
    
    this.ActivityTypeName = '';
    this.autofillActivities = [];
    this.ButtonText = 'Add';
    $('#myModal1').modal('show');
  }
  OpenTasksPopUp() {
    this.TaskName = '';
    this.autofillTasks = [];
    this.ButtonText = 'Add';
    $('#myModal2').modal('show');
  }
  getProjectStatus() {
    var url = "api/Master/GetProjectStatus";
    this.generalService.GetData(url).then((data: any) => {
      if (data != null) {
        this.ProjectStatusData = data;
      }
    }, err => {
      // this.spinner.hide();
      this.generalService.ShowAlert('ERROR', 'Something Went wrong', 'error');
    });
  }
  getProjects() {
    var url = "api/Master/GetProjects";
    this.generalService.GetData(url).then((data: any) => {
      if (data != null) {
        var obj = Object.keys(data[0])
        this.ProjectData = data;
      }
    }, err => {
      // this.spinner.hide();
      this.generalService.ShowAlert('ERROR', 'Something Went wrong', 'error');
      });

  }

  getCompany() {
    var url = "api/Values/GetCompanies";
    this.generalService.GetData(url).then((data: any) => {
      if (data != null) {
        var obj = Object.keys(data[0])
        this.Companydata = data;
        //this.filterClientSingle("");
      }
    }, err => {
      // this.spinner.hide();
      this.generalService.ShowAlert('ERROR', 'Something Went wrong', 'error');
    });
  }

  getOwnerDetails(ev) {
    debugger
    this.Client
    const data = this.Companydata.filter(a => a.Id == ev)
    if (data.length != 0) {
      this.OwnerId = data[0].POCName
    }
  }
  /* getting all job names for dropdown*/
  GetJobDetailsfordropdown() {
    var url = "api/Master/GetJobDetails";
    this.generalService.GetData(url).then((data: any) => {
      
      if (data != null) {
        this.Jobs = data;
        this.colsJobs = [
          { field: 'Id', header: 'Id', width: '150px' },
        ]
        //console.log(this.GropuData)
      }
    }, err => {
      // this.spinner.hide();
      this.generalService.ShowAlert('ERROR', 'Something Went wrong', 'error');
    });
  }
  /*end*/
  /* getting all Activity types names for dropdown*/
  GetActivityTypeDetailsfordropdown() {    
    var url = "api/Master/GetActivityType";
    this.generalService.GetData(url).then((data: any) => {
      
      if (data != null) {
        this.Activities = data.filter(A=>A.Status==true);
        this.colsActivities = [
          { field: 'Id', header: 'Id', width: '150px' },
          { field: 'Status', header: 'Status', width: '150px' },
          { field: 'ActivityType', header: 'ActivityType', width: '150px' },
        ]
        //console.log(this.GropuData)
      }
    }, err => {
      // this.spinner.hide();
      this.generalService.ShowAlert('ERROR', 'Something Went wrong', 'error');
    });
  }
  /*end*/
  /* getting all Task names for dropdown*/
  GetTaskDetailsfordropdown() {
    
    var url = "api/Master/GetTaskType";
    this.generalService.GetData(url).then((data: any) => {
      
      if (data != null) {
        this.Tasks = data.filter(A => A.status == true);
        this.colsTasks = [
          { field: 'Id', header: 'Id', width: '150px' },
          { field: 'TaskType', header: 'TaskType', width: '150px' },
          { field: 'Status', header: 'Status', width: '150px' },
        ]
        //console.log(this.GropuData)
      }
    }, err => {
      // this.spinner.hide();
      this.generalService.ShowAlert('ERROR', 'Something Went wrong', 'error');
    });
  }
  /*end*/
  GetTemplateDetailsForDropdowns() {
    var url = "api/Master/GetTemplateDetails";
    this.generalService.GetData(url).then((data: any) => {
      if (data != null) {
        this.Templates = data.filter(A => A.Status == true);
      }
    }, err => {
      // this.spinner.hide();
      this.generalService.ShowAlert('ERROR', 'Something Went wrong', 'error');
    });
  }
  getGroups() {
    var url = "api/Values/Getcategories";
    this.generalService.GetData(url).then((data: any) => {
      if (data != null) {
        this.GroupData = data.filter(A => A.Status == true);
      }
    }, err => {
      // this.spinner.hide();
      this.generalService.ShowAlert('ERROR', 'Something Went wrong', 'error');
    });
  }
  GetJobs(autofillCategories) {
    
  }
  GetActivites(autofillActivities) {
    
  }
  GetTasks(autofillTasks) {
  }
  GetTemplates(autofillTemplates) {

  }

  GetCategory(autofillCategories) {    
    this.TemplateData = [];
    this.TemplateTypeId = autofillCategories.Id
    this.TemplateData = this.Templates.filter(p => p.TemplateTypeId == this.TemplateTypeId);    
  }
  GetAClient(e) {

  }
  /*filter Jobs*/
  filterGroupsSingle(event) {
    
    let query = event.query;
    const result = this.Jobs.filter(p => p.JobNumber.includes(query));
    if (result.length == 0) {
      this.ButtonText = 'Add';
    }
    else {
      this.ButtonText = 'Select';
      this.autofillCategories = [];
      this.autofillCategories = result;
    }
  }
  /*end ode*/

  /*filter ActivityType*/
  filterActivityTypeSingle(event) {
    
    let query = event.query;
    const result = this.Activities.filter(p => p.ActivityType.includes(query));
    if (result.length == 0) {
      this.ButtonText = 'Add';
    }
    else {
      this.ButtonText = 'Select';
      this.autofillActivities = [];
      this.autofillActivities = result;
    }
  }
  /*end ode*/

  /*filter TaskType*/
  filterTaskTypeSingle(event) {
    
    let query = event.query;
    const result = this.Tasks.filter(p => p.TaskType.includes(query));
    if (result.length == 0) {
      this.ButtonText = 'Add';
    }
    else {
      this.ButtonText = 'Select';
      this.autofillTasks = [];
      this.autofillTasks = result;
    }
  }
  filterClientSingle(event) {
    debugger
    if (event == "") {
      const result = this.Companydata.find(p => p.Id ==this.Clientid);
      this.Client = result
    }
    else {
      let query = event.query;
      const result = this.Companydata.filter(p => p.CompanyName.includes(query));
      if (result.length == 0) {
        this.ButtonText = 'Add';
      }
      else {
        this.ButtonText = 'Select';
        this.autofillClients = [];
        this.autofillClients = result;
      }
    }
   
  }
  /*end ode*/

  /*filter TemplateName*/
  filterTemplateNameSingle(event) {
    
    let query = event.query;
    const result = this.TemplateData.filter(p => p.TemplateName.includes(query));
    if (result.length == 0) {
      this.ButtonText = 'Add';
    }
    else {
      this.ButtonText = 'Select';
      this.autofillTemplates = [];
      this.autofillTemplates = result;
    }
  }
  /*end ode*/
  /*filter TemplateName*/
  filterCategory(event) {
    
    let query = event.query;
    const result = this.GroupData.filter(p => p.Category.includes(query));
    if (result.length == 0) {
      this.ButtonText = 'Add';
    }
    else {
      this.ButtonText = 'Select';
      this.autofillTemplateType = [];
      this.autofillTemplateType = result;
    }
  }
  /*end ode*/

  AddJob() {
    const result = this.Jobs.filter(p => p.JobNumber.includes('A'));
    let arr = [];
    let jname = this.SubProjectName
    arr = [{ JobNumber: this.SubProjectName, ProjectId: '1', RouteId: '1', CreatedBy: this.LoginDetails.logindetails.Id }]
    var UploadFile = new FormData();
    UploadFile.append("Jobs", JSON.stringify(arr));
    var url = 'api/Master/AddJobs';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      if (data != null) {
        this.SubProjectData.push({ JobNumber: jname, JobId: data, ProjectId: this.ProjectId })
      }
    });
    this.GetJobDetailsfordropdown()
  }
  AddTaskType() {

    let Array = [];
    Array.push({
      TaskType: this.TaskName, CreatedBy: this.LoginDetails.logindetails.Id
    })
    this.tName = this.TaskName
    var UploadFile = new FormData();
    UploadFile.append("TaskType", JSON.stringify(Array));
    var url = 'api/Master/AddTaskType';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      if (data != null) {
        this.TaskData.push({ TaskId: data, TaskType: this.tName, ProjectId: this.ProjectId })
      }
    });
    this.GetTaskDetailsfordropdown()
  }
  AddActivityType() {

    let Array = [];
    Array.push({
      ActivityType: this.ActivityTypeName, CreatedBy: this.LoginDetails.logindetails.Id
    })
    this.tName = this.ActivityTypeName
    var UploadFile = new FormData();
    UploadFile.append("ActivityType", JSON.stringify(Array));
    var url = 'api/Master/AddActivityType';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      if (data != null) {
        this.ActivityData.push({ ActivityName: this.tName, ActivityId: data, ProjectId: this.ProjectId, TemplateId: this.Tid, TemplateName: this.tName, TemplateType: this.Ttype })
        console.log(this.ActivityData)
      }
    });
    this.GetActivityTypeDetailsfordropdown()
  }
  /*Delete SubProjects*/
  DeleteJobs(rowdata, i) {
    
    this.SubProjectData.splice(i, 1)
    this.toaster.success('Sub Project Delete', 'success', {
      timeOut: 2000,
    });
  }
  /*end*/
  /*Delete Activities*/
  DeleteActivities(rowdata, i) {
    
    this.ActivityData.splice(i, 1)
    this.toaster.success('Activities Delete', 'success', {
      timeOut: 2000,
    });
  }
  /*end*/
  /*Delete Tasks*/
  DeleteTasks(rowdata, i) {
    
    this.TaskData.splice(i, 1)
    this.toaster.success('Tasks Delete', 'success', {
      timeOut: 2000,
    });
  }
  /*end*/


  SelectJob(name) {
    
    //const result1 = this.Jobs.filter(p => p.JobNumber.includes(name))
    //if (result1 == 0) {
    let query = name.JobNumber;
    if (query == undefined) {
      this.AddJob();
      this.SubProjectName = '';
    } else {
      const result = this.SubProjectData.filter(p => p.JobNumber.includes(name.JobNumber));
      if (result == 0) {
        this.toaster.success('Sub Project Added', 'success', {
          timeOut: 2000,
        });
        this.SubProjectData.push({ JobNumber: name.JobNumber, JobId: name.Id, ProjectId: this.ProjectId })
        this.SubProjectName = '';
      }
      else {

      }
    }
    this.insertSProject = true;
  }

  SelectActivities(name, Template) {
    
    let query = name.ActivityType;
    this.Tid = Template.Id;
    this.Tname = Template.TemplateName;
    this.Ttype = Template.TemplateType;
    if (query == undefined) {
      this.AddActivityType();
      this.ActivityTypeName = '';
      this.Templatename = '';
      this.Category = '';
    }
    else {
      const result = this.ActivityData.filter(p => p.ActivityName.includes(name.ActivityType));
      if (result == 0) {
        this.toaster.success('Activity Added', 'success', {
          timeOut: 2000,
        });
        this.ActivityData.push({ ActivityName: name.ActivityType, ActivityId: name.Id, ProjectId: this.ProjectId, TemplateId: Template.Id, TemplateName: Template.TemplateName, TemplateType: Template.TemplateType })
        this.ActivityTypeName = '';
        this.Templatename = '';
        this.Category = '';
      }
      else {

      }
    }
    this.insertActivities = true;
  }

  SelectTasks(name) {
    
    let query = name.TaskType;
    if (query == undefined) {
      this.AddTaskType();
      this.TaskName = '';
    } else {
      const result = this.TaskData.filter(p => p.TaskType.includes(name.TaskType));
      if (result == 0) {
        this.toaster.success('Task Added', 'success', {
          timeOut: 2000,
        });
        this.TaskData.push({ TaskId: name.Id, TaskType: name.TaskType, ProjectId: this.ProjectId })
        this.TaskName = '';
      }
      else {

      }
    }
    this.insertTask = true;
  }

  saveProject() {
    debugger
    if (this.ProjectName == undefined || this.ProjectName == '' || this.Client == undefined || this.Client == '') {
      this.toaster.info('Please select all fields', 'Info', {
        timeOut: 4000,
      });
    }
    else {
      if (this.ActivityData.length > 0) {
        this.NewProjectData.push({
          Id: this.ProjectId, ProjectName: this.ProjectName, ProjectStatus: 1, CompanyId: this.Client.Id,
          ProjectCode: this.ProjectCode, Description: this.pDescription,
          ProjectManager: this.ProjectManager, Contact: this.Contact, ScheduleStart: this.ScheduleStart,
          ScheduleEnd: this.ScheduleEnd, ActualStart: this.ActulaStart, ActualEnd: this.ActulaEnd,
          ActualHours: this.ActualHours, EstimatedHours: this.EstimatedHours, Priority: this.Priority
        })
        var UploadFile = new FormData();
        UploadFile.append("Projectjson", JSON.stringify(this.NewProjectData));
        UploadFile.append("Jobsjson", JSON.stringify(this.SubProjectData));
        UploadFile.append("Tasksjson", JSON.stringify(this.TaskData));
        UploadFile.append("Activitiesjson", JSON.stringify(this.ActivityData));
        if (this.ProjectIdforEdit == "1") {
          var msg = 'Project has been added successfully';
          var url = 'api/Values/Add_ProjectsTasksandJobsandActivities';

        }
        else {
          var msg = 'Project has been updated successfully';
          var url = 'api/Master/Update_ProjectsTasksActivities';
        }
        this.generalService.PostData(url, UploadFile).then((data: any) => {
          if (data == 'success') {
            this.toaster.success('Project has been added successfully', 'Success', {
              timeOut: 2000,
            });
          }
          else {
            this.generalService.ShowAlert("Warning", "Something went wrong please try again", 'warning');
          }
          this.router.navigateByUrl('/Projects/Details/1');
        });
      }
      else {
        this.toaster.info('Please select at least one activity', 'Info', {
          timeOut: 4000,
        });
      }
    }
  }

  /*get Project details for editing*/
  getProjectdetailsBasedOnId(pid) {
    debugger
    this.SubProjectData = [];
    this.TaskData = [];
    this.ActivityData = [];
    this.insertSProject = true
    this.insertTask = true
    this.insertActivities=true
    var UploadFile = new FormData();
    UploadFile.append("ProjectId",pid);
    var url = 'api/Master/GetProjectDetailsBasedOnId';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data != null) {
        this.ProjectDetailsDetails = data
        this.ProjectCode = data.projectdetails1[0].ProjectCode;
        this.ProjectName = data.projectdetails1[0].ProjectName;
        this.ProjectManager = data.projectdetails2[0].ProjectManager
        this.ActulaStart = this.datePipe.transform(data.projectdetails2[0].ActualStart, 'yyyy-MM-dd');          
        this.ActulaEnd = this.datePipe.transform(data.projectdetails2[0].ActualEnd, 'yyyy-MM-dd');
        this.ScheduleStart = this.datePipe.transform(data.projectdetails2[0].ScheduleStart, 'yyyy-MM-dd');
        this.ScheduleEnd = this.datePipe.transform(data.projectdetails2[0].ScheduleEnd, 'yyyy-MM-dd')
        this.ActualHours = data.projectdetails2[0].ActualHours;
        this.EstimatedHours = data.projectdetails2[0].EstimatedHours;
        this.pDescription = data.projectdetails1[0].Description;
        this.OwnerId = data.projectdetails1[0].Owner
        this.Clientid = data.projectdetails1[0].CompanyId
        this.Priority = data.projectdetails2[0].Priority
        this.Contact = data.projectdetails2[0].Contact
        for (var i = 0; i < data.subprojectdetails.length; i++) {
          this.SubProjectData.push({ JobNumber: data.subprojectdetails[i].JobNumber, JobId: data.subprojectdetails[i].JobId, ProjectId: this.ProjectId })
        }
        for (var i = 0; i < data.taskdetails.length; i++) {
          this.TaskData.push({ TaskId: data.taskdetails[i].TaskId, TaskType: data.taskdetails[0].TaskType, ProjectId: this.ProjectId })
        }
        for (var i = 0; i < data.projectactivites.length; i++) {
          var typeid = data.projectactivites[i].TemplateTypeId - 1
          var TempType = this.GroupData[typeid].Category
          
          this.ActivityData.push({ ActivityName: data.projectactivites[i].ActivityName, ActivityId: data.projectactivites[i].ActivityId, ProjectId: this.ProjectId, TemplateId: data.projectactivites[i].TemplateId, TemplateName: data.projectactivites[i].TemplateName, TemplateType:TempType})
        }
        this.getCompany()
        this.filterClientSingle("")
      }
    });
  }
  /*end*/

  DisablePrevScheduledates(ScheduleStart) {
    debugger
    if (this.ProjectIdforEdit == "1") {
      this.ScheduleEnd = ''
      this.mindate = ScheduleStart;
    }  
  }
}
