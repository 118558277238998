import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
//import { GeneralService } from '../../pages/Services/general.service';
import { GeneralService } from '../../Services/general.service'
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { formatDate } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { PrimeNGConfig } from 'primeng/api';
import { FilterMatchMode } from 'primeng/api';
import Swal from 'sweetalert2';
import { MenuService } from '../../../theme/components/menu/menu.service';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';

declare var $;
@Component({
  selector: 'app-template-management',
  templateUrl: './template-management.component.html',
  styleUrls: ['./template-management.component.scss'],
  providers: [DatePipe, MenuService]
})
export class TemplateManagementComponent implements OnInit {
  filteredValuesLength: any;
  Status: any;
  LoginDetails: any;
  ModelTitle: string;
  templateoriginal: any;
  template: any;
  flag: any;
  templateData: any;
  cols: any;
  addNew: boolean;
  editForm: boolean;
  activitytype: any;
  Id: any;
  public form: FormGroup;
  Grid: boolean = true;
  myDate = new Date();
  todaydate: any;
  Pinned: boolean;
  PinId: any;
  MenuId: any;
  public settings: Settings;
  currentPageRoute: any;

  constructor(public config: PrimeNGConfig, public menuService: MenuService, public appSettings: AppSettings, public router: Router, public toaster: ToastrService, public fb: FormBuilder, public generalService: GeneralService, private datePipe: DatePipe) {
    //this.myDate = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    this.config.filterMatchModeOptions = {
      text: [
        FilterMatchMode.STARTS_WITH,
        FilterMatchMode.CONTAINS,
        FilterMatchMode.NOT_CONTAINS,
        FilterMatchMode.ENDS_WITH,
        FilterMatchMode.EQUALS,
        FilterMatchMode.NOT_EQUALS,
        FilterMatchMode.LESS_THAN,
        FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
        FilterMatchMode.GREATER_THAN,
        FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
      ],
      numeric: [
        FilterMatchMode.EQUALS,
        FilterMatchMode.NOT_EQUALS,
        FilterMatchMode.LESS_THAN,
        FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
        FilterMatchMode.GREATER_THAN,
        FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
      ],
      date: [
        FilterMatchMode.BEFORE,
        FilterMatchMode.AFTER,
      ]
    }
    this.todaydate = formatDate(this.myDate, 'yyyy-MM-dd hh:mm:ss a', 'en-US', '+0530');
    this.LoginDetails = JSON.parse(localStorage.getItem('LoginDetails'))
    this.form = this.fb.group({
      activitytype: ['', Validators.required]
    });
    this.settings = this.appSettings.settings;
    this.MenuId = 0;
    this.router.events.subscribe((val) => {

      this.currentPageRoute = this.router.url; // Current page route

    });
  }

  ngOnInit(): void {
    this.getTemplateDetails();
    this.GetPinned();
  }
  //Get Pinned

  Pined() {
    debugger
    this.Pinned = true;

    let Array = [];
    Array.push({
      MenuId: this.MenuId, RouterLink: this.currentPageRoute, UserId: this.LoginDetails.logindetails.Id
    })
    var UploadFile = new FormData();
    UploadFile.append("Pinned", JSON.stringify(Array));
    var url = 'api/Values/AddPinned';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data == "SUCCESS") {
        debugger
        this.GetPinned();
        var UploadFile = new FormData();
        UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
        //UploadFile.append("flag", '1');
        var url = 'api/Values/GetSideMenusWithRecentPinned';
        this.generalService.PostData(url, UploadFile).then((data1: any) => {
          debugger
          var Details = this.generalService.SIdeMenus(data1);
          this.menuService.RefreshMenu(Details, this.settings.theme.menu);
          debugger
        });

      }

    });
  }
  //=======print====//
  onPrint() {
    window.print();
  }

  UnPin() {
    debugger

    var UploadFile = new FormData();
    UploadFile.append("PinId", this.PinId);
    UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
    var url = 'api/Values/DeletePinned';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data == "SUCCESS") {
        debugger
        this.Pinned = false;
        var UploadFile = new FormData();
        UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
        //UploadFile.append("flag", '1');
        var url = 'api/Values/GetSideMenusWithRecentPinned';
        this.generalService.PostData(url, UploadFile).then((data1: any) => {
          debugger
          var Details = this.generalService.SIdeMenus(data1);
          this.menuService.RefreshMenu(Details, this.settings.theme.menu);
          debugger
        });
      }
    });
  }

  GetPinned() {
    debugger
    var UploadFile = new FormData();
    UploadFile.append("MenuId", this.MenuId);
    UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
    UploadFile.append("RouterLink", this.currentPageRoute);

    var url = 'api/Values/GetPinned';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data.MSG == "EXISTS") {
        debugger
        this.PinId = data.PinId;
        this.Pinned = true;
      }
      else if (data.MSG == "NOT EXISTS") {
        this.Pinned = false;
      }
    });
  }
  getTemplateDetailsOnId() {    
    this.flag = "78"//for Update        
    var UploadFile = new FormData();
    UploadFile.append("TemplateId", this.flag);
    var url = "api/Master/TemplateDetailsBasedOnId";
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      if (data != null) {
      }
    }, err => {
      // this.spinner.hide();
      this.generalService.ShowAlert('ERROR', 'Something Went wrong', 'error');
    });
  }
  getTemplateDetails() {
    var url = "api/Master/GetTemplateDetails";
    this.generalService.GetData(url).then((data: any) => {
      if (data != null) {
       
        data.forEach(
          companyType => (companyType.CreatedDate = new Date(companyType.CreatedDate))
        );
        //data.forEach(
        //  companyType => (companyType.ModifiedDate = new Date(companyType.ModifiedDate))
        //);
        this.templateData = data;
        for (var i = 0; i < data.length; i++) {
          if (data[i].ModifiedDate != null && data[i].ModifiedDate != undefined && data[i].ModifiedDate != '') {
            this.templateData[i].ModifiedDate = new Date(this.templateData[i].ModifiedDate)
          }
        }
        
        
        //this.templateData = this.templateData.map(item => {
        //  item.ModifiedDate = item.ModifiedDate ? '' || 'XXX' : 'MyVAL'
        //  return item;
        //});
        debugger
        this.templateoriginal = data;
 var obj = Object.keys(this.templateData[0])
        this.cols = [
          { field: 'Id', header: 'Id', width: '50px' },
          { field: 'TemplateType', header: 'TemplateType', width: '250px' },
          { field: 'TemplateName', header: 'TemplateName', width: '150px' },
          { field: 'ProjectName', header: 'ProjectName', width: '150px' },
          {field:'WorkabilityType',header:'WorkabilityType',width:'150px'},
          { field: 'Status', header: 'Status', width: '150px' },
          { field: 'CreatedDate', header: 'CreatedDate', width: '150px' },
          { field: 'CreateBy', header: 'CreatedBy', width: '150px' },
          { field: 'ModifiedDate', header: 'ModifiedDate', width: '150px' },
          //{ field: 'StrModifiedDate', header: 'ModifiedDate', width: '150px' },

          { field: 'ModifiBy', header: 'ModifiedBy', width: '150px' },
        ]
      }
    }, err => {
      // this.spinner.hide();
      this.generalService.ShowAlert('ERROR', 'Something Went wrong', 'error');
    });
  }

  Templa(id) {
    debugger
    localStorage.setItem("id", id)
    this.router.navigateByUrl('/Inspection/Inspectiontemplate');
  }

  OpenAddForm() {
    this.router.navigateByUrl('/Inspection/Inspectiontemplate');
  }

  TextBoxChange(item, key) {
    debugger
    let index: number = this.templateData.findIndex(t => t.Id == item.Id);
    let index1: number = this.templateoriginal.findIndex(t => t.Id == item.Id);
    if (index > -1) {
      this.templateData[index][key] = item[key];
      this.templateData = this.templateData[index].Template;
      this.Id = item.Id;
    }
    if (index1 > -1) {
      this.templateoriginal[index][key] = item[key];
      this.templateoriginal[index]['ModifiedBy'] = this.LoginDetails.   QAW222Q112;
      this.templateoriginal[index]['ModifiedDate'] = this.todaydate;
    }
    // this.onRowUpadte();
    this.flag = "2"//for Update        
    var UploadFile = new FormData();
    UploadFile.append("Template", JSON.stringify(this.templateoriginal));
    UploadFile.append("flag", this.flag);
    var url = 'api/Master/AddUpdateTemplate';
    this.generalService.PostData(url, UploadFile).then((data: any) => {

      if (data != null) {
        this.getTemplateDetails();
        this.toaster.success('Template has been updated successfully', 'Success', {
          timeOut: 2000,
        });
        this.backform();
      }
    });
  }

  
  onDelete(rowData) {
    Swal.fire({
      title: 'Are you sure to Delete  ?',
      //text: "",
      //type: 'warning',
      html: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      debugger
      if (result.value) {
        this.flag = "3";

        let Array = [];
        Array.push({
          TemplateName: rowData.TemplateName, Id: rowData.Id,
        })
        var UploadFile = new FormData();
        UploadFile.append("Template", JSON.stringify(Array));
        UploadFile.append("flag", this.flag);
        var url = 'api/Master/AddUpdateTemplate';
        this.generalService.PostData(url, UploadFile).then((data: any) => {

          if (data == "success") {
            this.getTemplateDetails();
            this.generalService.ShowAlert('SUCCESS', 'Template has been Deleted successfully', 'success');
            this.backform();
          }
          else {
            this.generalService.ShowAlert('WARNING', 'You can not delete this record because of it is having relation with another table', 'warning');
            this.backform();
          }
        });
      }
    })
  }

  changeStatus(rowData, status) {
    this.Status = status;

    this.flag = "4";

    let Array = [];
    Array.push({
      Id: rowData.Id, Status: this.Status, ModifiedBy: this.LoginDetails.logindetails.Id, ModifiedDate: this.todaydate
    })
    var UploadFile = new FormData();
    UploadFile.append("Template", JSON.stringify(Array));
    UploadFile.append("flag", this.flag);
    var url = 'api/Master/AddUpdateTemplate';
    this.generalService.PostData(url, UploadFile).then((data: any) => {

      if (data != null) {
        this.getTemplateDetails();
        if (this.Status == true) {
          this.toaster.success('Status Activated Successfully', 'Success', {
            timeOut: 2000,
          });
        }
        else {
          this.toaster.warning('Status Dectivated Successfully', 'Success', {
            timeOut: 2000,
          });
        }
      }
    });
  }

  backform() {
    ;
    this.Grid = true;
  }
  refresh() {
    location.reload();
  }

}
