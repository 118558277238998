import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../Services/general.service';
import { Menu } from '../../theme/components/menu/menu.model';
import { MenuService } from '../../theme/components/menu/menu.service';
import { Settings } from '../../app.settings.model';
import { AppSettings } from '../../app.settings';
import { DatePipe } from '@angular/common';
import { formatDate } from '@angular/common';
import { ToastrService, GlobalConfig } from 'ngx-toastr';
import { PrimeNGConfig } from 'primeng/api';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-reports-and-queries',
  templateUrl: './reports-and-queries.component.html',
  styleUrls: ['./reports-and-queries.component.scss'],
  providers: [MenuService, DatePipe]

})
export class ReportsAndQueriesComponent implements OnInit {
  Status: any;
  InspectorList: any;
  autofillUsers: any[];
  Id: any;
  cols: { field: string; header: string; width: string; }[];
  homeInspector: any;
  PinId: any;
  InspectorName: any;
  RoleId: any;
  Pinned: boolean;
  MenuId: any;
  myDate = new Date();
  todaydate: any;
  public menus: Array<Menu>;
  public settings: Settings;
  LoginDetails: any;
  options: GlobalConfig;
  constructor(private generalService: GeneralService, public router: Router,
    public appSettings: AppSettings, public menuService: MenuService,
    public toastrService: ToastrService) {
    this.options = this.toastrService.toastrConfig;
    debugger
    this.todaydate = formatDate(this.myDate, 'yyyy-MM-dd hh:mm:ss a', 'en-US', '+0530');
    this.LoginDetails = JSON.parse(localStorage.getItem('LoginDetails'));
    this.settings = this.appSettings.settings;
    this.MenuId = localStorage.getItem('MenuId')
    this.RoleId = this.LoginDetails.logindetails.RoleId
    this.AddRecents();
    this.GetPinned();
  }

  ngOnInit(): void {
    this.cols = [
      { field: 'Id', header: 'Id', width: '30px' },
      { field: 'ReportType', header: 'ReportType', width: '80px' },
      { field: 'Group', header: 'Group', width: '150px' },
      { field: 'ReportName', header: 'ReportName', width: '200px' },     
      { field: 'Accessibility', header: 'Accessibility', width: '80px' },
      { field: 'CreatedDate', header: 'CreatedDate', width: '100px' },
      { field: 'CreateBy', header: 'CreatedBy', width: '100px' },
      { field: 'ModifiedDate', header: 'ModifiedDate', width: '100px' },
      { field: 'ModifiBy', header: 'ModifiedBy', width: '100px' },
    ]
    this.getReportMaster();
  }



  AddRecents() {
    debugger
    let Array = [];
    Array.push({
      MenuId: this.MenuId, UserId: this.LoginDetails.logindetails.Id
    })
    var UploadFile = new FormData();
    UploadFile.append("Recent", JSON.stringify(Array));
    var url = 'api/Values/AddRecent';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data == "SUCCESS") {
        debugger
        var UploadFile = new FormData();
        UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
        UploadFile.append("flag", '1');
        var url = 'api/Values/GetSideMenusWithRecentPinned';
        this.generalService.PostData(url, UploadFile).then((data1: any) => {
          debugger
          var Details = this.generalService.SIdeMenus(data1);
          this.menuService.RefreshMenu(Details, this.settings.theme.menu);
          debugger
        });
      }

    });
  }

  Pined() {
    debugger
    this.Pinned = true;

    let Array = [];
    Array.push({
      MenuId: this.MenuId, UserId: this.LoginDetails.logindetails.Id
    })
    var UploadFile = new FormData();
    UploadFile.append("Pinned", JSON.stringify(Array));
    var url = 'api/Values/AddPinned';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data == "SUCCESS") {
        debugger
        this.GetPinned();
        this.toastrService.clear();
        this.toastrService.success('Pinned..!', '');
        var UploadFile = new FormData();
        UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
        //UploadFile.append("flag", '1');
        var url = 'api/Values/GetSideMenusWithRecentPinned';
        this.generalService.PostData(url, UploadFile).then((data1: any) => {
          debugger
          var Details = this.generalService.SIdeMenus(data1);
          this.menuService.RefreshMenu(Details, this.settings.theme.menu);
          debugger
        });

      }

    });
  }

  UnPin() {
    debugger

    var UploadFile = new FormData();
    UploadFile.append("PinId", this.PinId);
    UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
    var url = 'api/Values/DeletePinned';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data == "SUCCESS") {
        debugger
        this.Pinned = false;
        this.toastrService.clear();
        this.toastrService.info('Un Pinned..!', '');
        var UploadFile = new FormData();
        UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
        var url = 'api/Values/GetSideMenusWithRecentPinned';
        this.generalService.PostData(url, UploadFile).then((data1: any) => {
          debugger
          var Details = this.generalService.SIdeMenus(data1);
          this.menuService.RefreshMenu(Details, this.settings.theme.menu);
          debugger
        });
      }
    });
  }

  GetPinned() {
    debugger
    var UploadFile = new FormData();
    UploadFile.append("MenuId", this.MenuId);
    UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
    UploadFile.append("RouterLink", '');
    var url = 'api/Values/GetPinned';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data.MSG == "EXISTS") {
        debugger
        this.PinId = data.PinId;
        this.Pinned = true;
      }
      else if (data.MSG == "NOT EXISTS") {
        this.Pinned = false;
      }
    });
  }

  getReportMaster() {
    var url = "api/Master/GetReportMaster";
    this.generalService.GetData(url).then((data: any) => {
      if (data != null) {
        var obj = Object.keys(data[0])
        
        data.forEach(
          companyType => (companyType.CreatedDate = new Date(companyType.CreatedDate))
        );       
        this.homeInspector = data;
      }
    }, err => {
      // this.spinner.hide();
      this.generalService.ShowAlert('ERROR', 'Something Went wrong', 'error');
    });
  }

  navigateReport(Id) {
    debugger
    if (Id > 5) {
      this.router.navigateByUrl('/Commingsoon');
    }
    else {
      this.router.navigateByUrl('/ReportMaster/' + Id);
    }
  }

  changeStatus(rowData, status) {
    this.Status = status;
    let Array = [];
    Array.push({
      Id: rowData.Id, Status: this.Status, ModifiedBy: this.LoginDetails.logindetails.Id, ModefiedDate: this.todaydate
    })
    var UploadFile = new FormData();
    UploadFile.append("reportMasterStatus", JSON.stringify(Array));
    UploadFile.append("flag",'1');
    var url = 'api/Master/changeReportmasterStatus';
    this.generalService.PostData(url, UploadFile).then((data: any) => {

      if (data != null) {
        this.getReportMaster()
        if (this.Status == true) {
          this.toastrService.success('Status Activated Successfully', 'Success', {
            timeOut: 2000,
          });
        }
        else {
          this.toastrService.warning('Status Dectivated Successfully', 'Success', {
            timeOut: 2000,
          });
        }
      }
    });
  }

  onDelete(rowData) {
    Swal.fire({
      title: 'Are you sure to Delete  ?',
      //text: "",
      //type: 'warning',
      html: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      debugger
      if (result.value) {
        let Array = [];
        Array.push({
          Id: rowData.Id, Status: this.Status, ModifiedBy: this.LoginDetails.logindetails.Id, ModefiedDate: this.todaydate
        })
        var UploadFile = new FormData();
        UploadFile.append("reportMasterStatus", JSON.stringify(Array));
        UploadFile.append("flag", "1");
        var url = 'api/Master/changeReportmasterStatus';
        this.generalService.PostData(url, UploadFile).then((data: any) => {
          if (data == "success") {
            this.getReportMaster();
            this.generalService.ShowAlert('SUCCESS', 'Report has been Deleted successfully', 'success');         
          }
          else {
            this.generalService.ShowAlert('WARNING', 'You can not delete this record because of it is having relation with another table', 'warning');           
          }
        });
      }
    })
  }
}




