<div class="sidebar-outer">
  <a class="logo d-flex align-items-center" (click)="closeSubMenus()" *ngIf="settings.theme.sidebarIsFixed">
    <img src="../../../../assets/img/icons/OriginalTransparent.png"  style="    width: 40% !important;"/>
    <!--<span class="start" style="color:#fff !important;font-family:poppins">i2i
    &nbsp;</span>
    <span style="color:#fff !important;font-family:poppins" class="ng"><b>CMP</b></span>-->
  </a>
  <!--<div class="card" id="menu3" style="padding:5px" >
    <a class="menu-item-link transition" data-toggle="tooltip" data-placement="right" data-animation="false" data-container=".vertical-menu-tooltip-place" data-original-title="Home" id="link3">

      <span  class="menu-title" style="font-size:12px;font-weight:bold;">Quality Management</span>
    </a>
  </div>-->
  <!--<nav>
    <ul class="nav">
      <li><a href="#">Link 1</a></li>
      <li>
        <a href="#" id="btn-1" data-toggle="collapse" data-target="#submenu1" aria-expanded="false">Link 2 (toggle)</a>
        <ul class="nav collapse" id="submenu1" role="menu" aria-labelledby="btn-1">
          <li><a href="#">Link 2.1</a></li>
          <li><a href="#">Link 2.2</a></li>
          <li><a href="#">Link 2.3</a></li>
        </ul>
      </li>
      <li><a href="#">Link 3</a></li>
      <li><a href="#">Link 4</a></li>
    </ul>
  </nav>-->
  <!--<div id="navbar-menu" *ngFor="let item of SideMenus">
    <ul class="nav navbar-nav " id="navbar-menu" style="text-transform:uppercase">
      <li >
        <a *ngIf="item.ParentId!=0" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"
           style="margin-top:0px;cursor:pointer">{{item.Title}}<span class="caret"></span></a>
        <a  class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"
           routerLink="{{item.RouterLink}}" style="margin-top:0px;cursor:pointer">{{item.Title}}</a>

      </li>
    </ul>
  </div>-->
  <app-vertical-menu [menuItems]="menuItems"></app-vertical-menu>

  <!--(click)="GetMenu(menuItems)"-->












</div>
