import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
//import { GeneralService } from '../../pages/Services/general.service';
import { GeneralService } from '../../Services/general.service'
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { formatDate } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { PrimeNGConfig } from 'primeng/api';
import { FilterMatchMode } from 'primeng/api';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';

import { MenuService } from '../../../theme/components/menu/menu.service';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
declare var $;
@Component({
  selector: 'app-project-status',
  templateUrl: './project-status.component.html',
  styleUrls: ['./project-status.component.scss'],
  providers: [DatePipe, MenuService]
})
export class ProjectStatusComponent implements OnInit {
  newProjectId: any;
  ProjectCode: any;
  Description: any;
  Companydata: any;
  Prodata: any = [];
  CompanyDropdown: any;
  ProjectState: any;
  cols1: { field: string; header: string; width: string; }[];
  ProjectName: string;
  ProjectData: any;
  ModelTitle1: string;
  MenuId: any;
  filteredValuesLength: any;
  Status: any;
  LoginDetails: any;
  ModelTitle: string;
  templateoriginal: any;
  template: any;
  flag: any;
  ProjectStatus: any;
  ProjectStatusEdit: any;
  cols: any;
  addNew: boolean;
  editForm: boolean;
  activitytype: any;
  Id: any;
  public form: FormGroup;
  Grid: boolean = true;
  myDate = new Date();
  todaydate: any;
  Pinned: boolean;
  PinId: any;
  MenuIdPin: any;
  public settings: Settings;
  currentPageRoute: any;
  constructor(public config: PrimeNGConfig, public menuService: MenuService, public appSettings: AppSettings, public router: Router, public toaster: ToastrService, public route: ActivatedRoute, public fb: FormBuilder, public generalService: GeneralService, private datePipe: DatePipe) {
    //this.myDate = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    this.config.filterMatchModeOptions = {
      text: [
        FilterMatchMode.STARTS_WITH,
        FilterMatchMode.CONTAINS,
        FilterMatchMode.NOT_CONTAINS,
        FilterMatchMode.ENDS_WITH,
        FilterMatchMode.EQUALS,
        FilterMatchMode.NOT_EQUALS,
        FilterMatchMode.LESS_THAN,
        FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
        FilterMatchMode.GREATER_THAN,
        FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
      ],
      numeric: [
        FilterMatchMode.EQUALS,
        FilterMatchMode.NOT_EQUALS,
        FilterMatchMode.LESS_THAN,
        FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
        FilterMatchMode.GREATER_THAN,
        FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
      ],
      date: [
        FilterMatchMode.BEFORE,
        FilterMatchMode.AFTER,
      ]
    }
    this.MenuId = route.snapshot.params['Id'];
    this.todaydate = formatDate(this.myDate, 'yyyy-MM-dd hh:mm:ss a', 'en-US', '+0530');
    this.LoginDetails = JSON.parse(localStorage.getItem('LoginDetails'))
    this.form = this.fb.group({
      activitytype: ['', Validators.required]
    });
    this.settings = this.appSettings.settings;
    this.MenuIdPin = 0;
    this.router.events.subscribe((val) => {

      this.currentPageRoute = this.router.url; // Current page route

    });

  }

  ngOnInit(): void {

    this.GetPinned();
    this.getProjects();
    this.getFidings();
    this.getCompany();
    if (this.MenuId == 1) {
      this.getProjects();      
    }
    if (this.MenuId == 2) {
      this.getFidings();
    }
  }
  handleChange(e) {
    var index = e.index;
    if (index == 0) {
      this.getProjects();
    }
    if (index == 1) {
      this.getFidings();
    }
  }

  //Get pinned
  Pined() {
    debugger
    this.Pinned = true;

    let Array = [];
    Array.push({
      MenuId: this.MenuIdPin, RouterLink: this.currentPageRoute, UserId: this.LoginDetails.logindetails.Id
    })
    var UploadFile = new FormData();
    UploadFile.append("Pinned", JSON.stringify(Array));
    var url = 'api/Values/AddPinned';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data == "SUCCESS") {
        debugger
        this.GetPinned();
        var UploadFile = new FormData();
        UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
        //UploadFile.append("flag", '1');
        var url = 'api/Values/GetSideMenusWithRecentPinned';
        this.generalService.PostData(url, UploadFile).then((data1: any) => {
          debugger
          var Details = this.generalService.SIdeMenus(data1);
          this.menuService.RefreshMenu(Details, this.settings.theme.menu);
          debugger
        });

      }

    });
  }
  //=======print====//
  onPrint() {
    window.print();
  }

  UnPin() {
    debugger

    var UploadFile = new FormData();
    UploadFile.append("PinId", this.PinId);
    UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
    var url = 'api/Values/DeletePinned';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data == "SUCCESS") {
        debugger
        this.Pinned = false;
        var UploadFile = new FormData();
        UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
        //UploadFile.append("flag", '1');
        var url = 'api/Values/GetSideMenusWithRecentPinned';
        this.generalService.PostData(url, UploadFile).then((data1: any) => {
          debugger
          var Details = this.generalService.SIdeMenus(data1);
          this.menuService.RefreshMenu(Details, this.settings.theme.menu);
          debugger
        });
      }
    });
  }

  GetPinned() {
    debugger
    var UploadFile = new FormData();
    UploadFile.append("MenuId", this.MenuIdPin);
    UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
    UploadFile.append("RouterLink", this.currentPageRoute);

    var url = 'api/Values/GetPinned';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data.MSG == "EXISTS") {
        debugger
        this.PinId = data.PinId;
        this.Pinned = true;
      }
      else if (data.MSG == "NOT EXISTS") {
        this.Pinned = false;
      }
    });
  }
  /*Project Status*/
  getFidings() {
    
    var url = "api/Master/GetProjectStatus";
    this.generalService.GetData(url).then((data: any) => {
      if (data != null) {
        var obj = Object.keys(data[0])
        this.cols = [
          { field: 'Id', header: 'Id', width: '150px' },
          { field: 'ProjectStatus', header: 'ProjectStatus', width: '150px' },
          { field: 'Status', header: 'Status', width: '150px' },
          { field: 'CreatedDate', header: 'CreatedDate', width: '150px' },
          { field: 'CreateBy', header: 'CreatedBy', width: '150px' },
          { field: 'ModifiedDate', header: 'ModifiedDate', width: '150px' },
          { field: 'ModifiBy', header: 'ModifiedBy', width: '150px' },
        ]
        data.forEach(
          companyType => (companyType.CreatedDate = new Date(companyType.CreatedDate))
        );
        data.forEach(
          companyType => (companyType.ModifiedDate = new Date(companyType.ModifiedDate))
        );
        this.ProjectStatus = data;

        for (var i = 0; i < data.length; i++) {
          if (data[i].ModifiedDate != null && data[i].ModifiedDate != undefined && data[i].ModifiedDate != '') {
            this.ProjectStatus[i].ModifiedDate = new Date(this.ProjectStatus[i].ModifiedDate)
          }
        }
        this.templateoriginal = data;
      }
    }, err => {
      // this.spinner.hide();
      this.generalService.ShowAlert('ERROR', 'Something Went wrong', 'error');
    });
  }

  OpenAddForm() {
    debugger
    this.ModelTitle = "Add ProjectStatus";
    $('#myModal').modal('show');
    this.Grid = false;
    this.ProjectStatus = "";
    this.flag = "1";//for adding
    //this.addNew = true;
    //this.editForm = false;

  }

  TextBoxChange(item, key) {
    debugger
    let index: number = this.ProjectStatus.findIndex(t => t.Id == item.Id);
    let index1: number = this.templateoriginal.findIndex(t => t.Id == item.Id);
    if (index > -1) {
      this.ProjectStatus[index][key] = item[key];
      this.ProjectStatus = this.ProjectStatus[index].ProjectStatus;
      this.Id = item.Id;
    }
    if (index1 > -1) {
      this.templateoriginal[index][key] = item[key];
      this.templateoriginal[index]['ModifiedBy'] = this.LoginDetails.logindetails.Id;
      this.templateoriginal[index]['ModifiedDate'] = this.todaydate;
    }
    // this.onRowUpadte();
    this.flag = "2"//for Update        
    var UploadFile = new FormData();
    UploadFile.append("ProjectStatus", JSON.stringify(this.templateoriginal));
    UploadFile.append("flag", this.flag);
    var url = 'api/Master/AddUpdateProjectStatusMaster';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      
      if (data != null) {
        this.getFidings();
        this.toaster.success('ProjectStatus has been updated successfully', 'Success', {
          timeOut: 2000,
        });
        this.backform();
      }
    });
  }


  AddProjectStatus() {
    
    let Array = [];
    Array.push({
      ProjectStatus: this.ProjectStatusEdit, CreatedBy: this.LoginDetails.logindetails.Id
    })
    var UploadFile = new FormData();
    UploadFile.append("ProjectStatus", JSON.stringify(Array));
    UploadFile.append("flag", this.flag);
    var url = 'api/Master/AddUpdateProjectStatusMaster';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      
      $('#myModal').modal('hide');
      this.getFidings();
      this.toaster.success('Project Status has been added successfully', 'Success', {
        timeOut: 2000,
      });
    });
  }

  onDelete(rowData) {
    Swal.fire({
      title: 'Are you sure to Delete  ?',
      //text: "",
      //type: 'warning',
      html: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      debugger
      if (result.value) {
        this.flag = "3";

        let Array = [];
        Array.push({
          ProjectStatus: rowData.ProjectStatus, Id: rowData.Id,
        })
        var UploadFile = new FormData();
        UploadFile.append("ProjectStatus", JSON.stringify(Array));
        UploadFile.append("flag", this.flag);
        var url = 'api/Master/AddUpdateProjectStatusMaster';
        this.generalService.PostData(url, UploadFile).then((data: any) => {

          if (data == "success") {
            this.getFidings();
            this.generalService.ShowAlert('SUCCESS', 'Project Status has been Deleted successfully', 'success');
            this.backform();
          }
          else {
            this.generalService.ShowAlert('WARNING', 'You can not delete this record because of it is having relation with another table', 'warning');
            this.backform();
          }
        });
      }
    })
  }
  changeStatus(rowData, status) {
    this.Status = status;

    this.flag = "4";
    
    let Array = [];
    Array.push({
      Id: rowData.Id, Status: this.Status, ModifiedBy: this.LoginDetails.logindetails.Id, ModifiedDate: this.todaydate
    })
    var UploadFile = new FormData();
    UploadFile.append("ProjectStatus", JSON.stringify(Array));
    UploadFile.append("flag", this.flag);
    var url = 'api/Master/AddUpdateProjectStatusMaster';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      
      if (data != null) {
        this.getFidings();
        if (this.Status == true) {
          this.toaster.success('Status Activated Successfully', 'Success', {
            timeOut: 2000,
          });
        }
        else {
          this.toaster.warning('Status Dectivated Successfully', 'Success', {
            timeOut: 2000,
          });
        }
      }
    });
  }

  backform() {
    this.Grid = true;
  }
  refresh() {
    location.reload();
  }
  /*Projects*/
  getCompany() {
    var url = "api/Values/GetCompanies";
    this.generalService.GetData(url).then((data: any) => {
      if (data != null) {
        var obj = Object.keys(data[0])
       
        data.forEach(
          companyType => (companyType.CreatedDate = new Date(companyType.CreatedDate))
        );
        data.forEach(
          companyType => (companyType.ModifiedDate = new Date(companyType.ModifiedDate))
        );
        this.Companydata = data;
      }
    }, err => {
      // this.spinner.hide();
      this.generalService.ShowAlert('ERROR', 'Something Went wrong', 'error');
    });
  }

  getProjects() {
    debugger
    var url = "api/Master/GetProjects";
    this.generalService.GetData(url).then((data: any) => {
      debugger
      if (data != null) {
        var obj = Object.keys(data[0])
        this.cols1 = [
          { field: 'Id', header: 'Id', width: '150px' },
          { field: 'ProjectName', header: 'ProjectName', width: '150px' },
          { field: 'ProjectState', header: 'ProjectState', width: '150px' },
          { field: 'Company', header: 'Company', width: '150px' },
          {field:'Description',header:'Description',width:'150px'},
          { field: 'Status', header: 'Status', width: '150px' },
          { field: 'CreatedDate', header: 'CreatedDate', width: '150px' },
          { field: 'CreateBy', header: 'CreatedBy', width: '150px' },
          { field: 'ModifiedDate', header: 'ModifiedDate', width: '150px' },
          { field: 'ModifiBy', header: 'ModifiedBy', width: '150px' },
        ]
        data.forEach(
          companyType => (companyType.CreatedDate = new Date(companyType.CreatedDate))
        );
        //data.forEach(
        //  companyType => (companyType.ModifiedDate = new Date(companyType.ModifiedDate))
        //);
        this.ProjectData = data;

       // this.CompanyStatusdata = data.sort((a, b) => b.CreatedDate - a.CreatedDate)
        for (var i = 0; i < data.length; i++) {
          if (data[i].ModifiedDate != null && data[i].ModifiedDate != undefined && data[i].ModifiedDate != '') {
            this.ProjectData[i].ModifiedDate = new Date(this.ProjectData[i].ModifiedDate)
          }
        }


        this.templateoriginal = data;
        this.newProjectId = data[0].Id + 1;
        localStorage.setItem("ProjectId", this.newProjectId);
      }
    }, err => {
      // this.spinner.hide();
      this.generalService.ShowAlert('ERROR', 'Something Went wrong', 'error');
      });
    
  }

  OpenAddForm1() {

    this.ModelTitle1 = "Add Projects";
    $('#myModal1').modal('show');
    this.Grid = false;
    this.ProjectName = "";
    this.ProjectCode = '';
    this.Description = '';
    this.flag = "1";//for adding
    //this.addNew = true;
    //this.editForm = false;

  }

  //TextBoxChange1(item, key) {
  //  debugger
  //  let index: number = this.ProjectData.findIndex(t => t.Id == item.Id);
  //  let index1: number = this.templateoriginal.findIndex(t => t.Id == item.Id);
  //  if (index > -1) {
  //    this.ProjectData[index][key] = item[key];
  //    this.ProjectData = this.ProjectData[index].ProjectData;
  //    this.Id = item.Id;
  //  }
  //  if (index1 > -1) {
  //    this.templateoriginal[index][key] = item[key];
  //    this.templateoriginal[index]['ModifiedBy'] = this.LoginDetails.logindetails.Id;
  //    this.templateoriginal[index]['ModifiedDate'] = this.todaydate;
  //  }
  //  // this.onRowUpadte();
  //  this.flag = "2"//for Update        
  //  var UploadFile = new FormData();
  //  UploadFile.append("ProjectStatus", JSON.stringify(this.templateoriginal));
  //  UploadFile.append("flag", this.flag);
  //  var url = 'api/Master/AddUpdateProjectStatusMaster';
  //  this.generalService.PostData(url, UploadFile).then((data: any) => {

  //    if (data != null) {
  //      this.getProjects();
  //      this.toaster.success('ProjectStatu has been updated successfully', 'Success', {
  //        timeOut: 2000,
  //      });
  //      this.backform();
  //    }
  //  });
  //}


  AddProjects() {
    debugger
    let Array = [];
    Array.push({
      ProjectName: this.ProjectName, ProjectCode: this.ProjectCode, Description: this.Description, CompanyId: this.CompanyDropdown, ProjectStatus: this.ProjectState, CreatedBy: this.LoginDetails.logindetails.Id
    })
    var UploadFile = new FormData();
    UploadFile.append("Projects", JSON.stringify(Array));
    UploadFile.append("flag", this.flag);
    var url = 'api/Master/AddUpdateProjects';
    this.generalService.PostData(url, UploadFile).then((data: any) => {

      $('#myModal1').modal('hide');
      this.getProjects();
      this.toaster.success('ProjectStatus has been added successfully', 'Success', {
        timeOut: 2000,
      });
    });
  }

  onDelete1(rowData) {
    Swal.fire({
      title: 'Are you sure to Delete  ?',
      //text: "",
      //type: 'warning',
      html: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      debugger
      if (result.value) {
        this.flag = "3";

        let Array = [];
        Array.push({ Id: rowData.Id,
        })
        var UploadFile = new FormData();
        UploadFile.append("Projects", JSON.stringify(Array));
        UploadFile.append("flag", this.flag);
        var url = 'api/Master/AddUpdateProjects';
        this.generalService.PostData(url, UploadFile).then((data: any) => {

          if (data == "success") {
            this.getProjects();
            this.generalService.ShowAlert('SUCCESS', 'Projects has been Deleted successfully', 'success');
            this.backform();
          }
          else {
            this.generalService.ShowAlert('WARNING', 'You can not delete this record because of it is having relation with another table', 'warning');
            this.backform();
          }
        });
      }
    })
  }
  changeStatus1(rowData, status) {
    this.Status = status;

    this.flag = "4";

    let Array = [];
    Array.push({
      Id: rowData.Id, Status: this.Status, ModifiedBy: this.LoginDetails.logindetails.Id, ModifiedDate: this.todaydate
    })
    var UploadFile = new FormData();
    UploadFile.append("Projects", JSON.stringify(Array));
    UploadFile.append("flag", this.flag);
    var url = 'api/Master/AddUpdateProjects';
    this.generalService.PostData(url, UploadFile).then((data: any) => {

      if (data != null) {
        this.getProjects();
        if (this.Status == true) {
          this.toaster.success('Status Activated Successfully', 'Success', {
            timeOut: 2000,
          });
        }
        else {
          this.toaster.warning('Status Dectivated Successfully', 'Success', {
            timeOut: 2000,
          });
        }
      }
    });
  }
  //projectEdit(pId) {
  //  debugger
  //  let index = this.templateoriginal.findIndex(a => a.Id == pId)
  //  localStorage.removeItem('pId');
  //  sessionStorage.removeItem('ProjectDetailsDetails')
  //  localStorage.setItem("pId", pId)
  //  sessionStorage.setItem('ProjectDetailsDetails', JSON.stringify(this.templateoriginal[index]));
  //  this.router.navigateByUrl('/NewProject');

  //}
  projectEdit(pId) {
    localStorage.removeItem('Pid')
    localStorage.setItem("Pid", '2')
    localStorage.removeItem('ProjectId')
    localStorage.setItem("ProjectId", pId)
    this.router.navigateByUrl('/NewProject')
  }
  NewPro() {
    debugger
    localStorage.removeItem('pId');
    localStorage.setItem("Pid", '1')
    this.router.navigateByUrl('/NewProject');
  }
}
