<div class="container-fluid" style="padding:0">
  <div class="row head">
    <div class="col-lg-4" style="padding:0">
      Assignments
      <button type="button" style="" class="btn" *ngIf="!Pinned" (click)="Pined()"><img class="tableheadericons" src="https://img.icons8.com/dotty/80/000000/pin3.png" /> Pin</button>
      <button type="button" style="" class="btn" *ngIf="Pinned" (click)="UnPin()"><img class="tableheadericons" src="https://img.icons8.com/dusk/64/000000/pin3.png" /> Un Pin</button>

      <!--My Quality Dashboard-->
    </div>






    <div class="col-lg-2" style="padding:0">

    </div>


    <div class="col-lg-6" style="text-align:right;font-size:12px;letter-spacing:0.2px">
      <!--This Quarter 4-1-2020 To 6-30-2020-->
    </div>
  </div>
  <br />
  <div class="row">
    
    <div *ngIf="MenuId==3||MenuId==5" class="col-lg-12">
      <p-tabView>
        <p-tabPanel [header]="'Inspection Assigned to Me (' +homeInspector.length+')'">

          <p-table #dt [columns]="cols" [value]="homeInspector" [rows]="10" [responsive]="true" [scrollable]="true" scrollHeight="333px">
            <ng-template pTemplate="caption">
              <div class="row">
                <div class="col-lg-6" style="text-align: left;margin-bottom: 0;">
                  <div>Inspection Assigned to Me</div>
                </div>
                <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
                  <input type="search" class="form-control" placeholder="Search for records" style="width: 40%;float: right;height: 30px;font-size: 12px;">
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="colgroup" let-columns>
              <colgroup>
                <col *ngFor="let col of cols" [ngStyle]="{'width': col.width}">
              </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                  <span>{{col.header}}</span>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr>
                <td *ngFor="let col of cols" [ngSwitch]="col.field">
                  <span *ngSwitchDefault>{{rowData[col.field]}}</span>
                  <span *ngSwitchCase="'PercentageOfCompletion'">{{rowData[col.field] | number}}%</span>
                  <span *ngSwitchCase="'CreatedDate'">{{rowData[col.field] | date:'d-M-y'}}</span>
                  <span *ngSwitchCase="'EndDate'">{{rowData[col.field] | date:'d-M-y'}}</span>
                  <span *ngSwitchCase="'EarliestStartDate'">{{rowData[col.field] | date:'d-M-y'}}</span>
                  <span *ngSwitchCase="'Id'">
                    <a [routerLink]="['/Inspection/Details', rowData.Id]">
                      {{rowData[col.field]}}
                    </a>
                  </span>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </p-tabPanel>
        <p-tabPanel [header]="'My completed inspections (' +completedInspector.length+')'">

          <p-table #dt1 [columns]="cols1" [value]="completedInspector" [rows]="10" [responsive]="true" [scrollable]="true" scrollHeight="333px">
            <ng-template pTemplate="caption">
              <div class="row">
                <div class="col-lg-6" style="text-align: left;margin-bottom: 0;">
                  <div>My completed inspections</div>
                </div>
                <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
                  <input type="search" class="form-control" placeholder="Search for records" style="      width: 40%;
    float: right;
    height: 30px;
    font-size: 12px;">
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="colgroup" let-columns>
              <colgroup>
                <col *ngFor="let col of cols1" [ngStyle]="{'width': col.width}">
              </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                  <span>{{col.header}}</span>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr>
                <td *ngFor="let col of cols1" [ngSwitch]="col.field">
                  <span *ngSwitchDefault>{{rowData[col.field]}}</span>
                  <span *ngSwitchCase="'PercentageOfCompletion'">{{rowData[col.field] | number}}%</span>
                  <span *ngSwitchCase="'CreatedDate'">{{rowData[col.field] | date:'d-M-y'}}</span>
                  <span *ngSwitchCase="'EndDate'">{{rowData[col.field] | date:'d-M-y'}}</span>
                  <span *ngSwitchCase="'EarliestStartDate'">{{rowData[col.field] | date:'d-M-y'}}</span>
                  <span *ngSwitchCase="'Id'">
                    <a [routerLink]="['/Inspection/Details', rowData.Id]">
                      {{rowData[col.field]}}
                    </a>
                  </span>

                </td>
              </tr>
            </ng-template>
          </p-table>
        </p-tabPanel>
        <p-tabPanel [header]="'Inspections returned to Me (' +returnedInspector.length+')'">
          <p-table #dt2 [columns]="cols2" [value]="returnedInspector" [rows]="10" [responsive]="true" [scrollable]="true" scrollHeight="333px">
            <ng-template pTemplate="caption">
              <div class="row">
                <div class="col-lg-6" style="text-align: left;margin-bottom: 0;">
                  <div> Inspections returned to Me</div>
                </div>
                <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
                  <input type="search" class="form-control" placeholder="Search for records" style="width: 40%;float: right;height: 30px;font-size: 12px;">
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="colgroup" let-columns>
              <colgroup>
                <col *ngFor="let col of cols2" [ngStyle]="{'width': col.width}">
              </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                  <span>{{col.header}}</span>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr>
                <td *ngFor="let col of cols2" [ngSwitch]="col.field">
                  <span *ngSwitchDefault>{{rowData[col.field]}}</span>
                  <span *ngSwitchCase="'PercentageOfCompletion'">{{rowData[col.field] | number}}%</span>
                  <span *ngSwitchCase="'CreatedDate'">{{rowData[col.field] | date:'d-M-y'}}</span>
                  <span *ngSwitchCase="'EndDate'">{{rowData[col.field] | date:'d-M-y'}}</span>
                  <span *ngSwitchCase="'EarliestStartDate'">{{rowData[col.field] | date:'d-M-y'}}</span>
                  <span *ngSwitchCase="'Id'">
                    <a [routerLink]="['/Inspection/Details', rowData.Id]">
                      {{rowData[col.field]}}
                    </a>
                  </span>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </p-tabPanel>
        <p-tabPanel header="My Audit Results">
          <p-table #dt3 [columns]="cols" [value]="arr" [rows]="10" [responsive]="true" [scrollable]="true" scrollHeight="333px">
            <ng-template pTemplate="caption">
              <div class="row">
                <div class="col-lg-6" style="text-align: left;margin-bottom: 0;">
                  <div>My Audit Results</div>
                </div>
                <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
                  <input type="search" class="form-control" placeholder="Search for records" style="      width: 40%;
    float: right;
    height: 30px;
    font-size: 12px;">
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="colgroup" let-columns>
              <colgroup>
                <col *ngFor="let col of cols" [ngStyle]="{'width': col.width}">
              </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                  <span>{{col.header}}</span>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr>
                <td *ngFor="let col of cols" [ngSwitch]="col.field">
                  <span *ngSwitchDefault>{{rowData[col.field]}}</span>
                  <!--<span *ngSwitchCase="'InspectionScore'">{{rowData[col.field] | number}}%</span>
                  <span *ngSwitchCase="'CreatedDate'">{{rowData[col.field] | date:'d-M-y'}}</span>-->
                  e=
                  <span *ngSwitchCase="'Id'">
                    /I
                    <a [routerLink]="['/Inspection',  rowData.Id]"></a>{{rowData[col.field] }}
                  </span>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </p-tabPanel>
      </p-tabView>

      <br /><br /><br />

      <!---------  My completed inspections-->
      <!----------- Inspections returned to Me-->

    </div>
  </div>
  <div></div>
  
</div>


<style>
  .card_1 {
    border: 1px solid #c1c1c175;
    /* padding: 35px; */
    height: 153px;
    margin-bottom: 15px;
    font-size: 13px;
    padding: 30% 1% 5% 5%;
    text-align: center;
  }

  .head {
    background: white;
    padding: 18px;
    box-shadow: 0 0px 3px rgba(0,0,0,0.2), 0 1px 0px rgb(0 0 0 / 5%);
  }

  .myc {
    margin: 5% 0%;
    font-size: 13px;
    letter-spacing: 0.2px;
    font-weight: bold;
  }
</style>




