<div class="container-fluid" style="padding:0">
  <div class="row head">
    <div class="col-lg-7" style="padding:0">
      Reports & Queries:&nbsp;{{header}}
      <!--<button type="button" style="" class="btn" *ngIf="!Pinned" (click)="Pined()"><img class="tableheadericons" src="https://img.icons8.com/dotty/80/000000/pin3.png" /> Pin</button>
  <button type="button" style="" class="btn" *ngIf="Pinned" (click)="UnPin()"><img class="tableheadericons" src="https://img.icons8.com/dusk/64/000000/pin3.png" /> Un Pin</button>-->
    </div>
    <div class="col-lg-2" style="padding:0">

      <!--<div *ngIf="!Pinned" (click)="Pined()">
        <img src="https://img.icons8.com/dotty/80/000000/pin3.png" class="pin" />
      </div>
      <div *ngIf="Pinned" (click)="UnPin()">
        <img src="https://img.icons8.com/dusk/64/000000/pin3.png" class="pin" />
      </div>-->
    </div>
    <!--<div class="col-lg-6" style="text-align:right;font-size:12px;letter-spacing:0.2px">
      This Quarter 4-1-2020 To 6-30-2020
    </div>-->
  </div>
  <br />
  <div class="row">
    <div *ngIf="menuId==2" class="col-lg-12">
      <p-table #dt1 [columns]="cols1" [value]="homeInspector" [rows]="10" [responsive]="true" [scrollable]="true" scrollHeight="333px">
        <ng-template pTemplate="caption">
          <div class="row">
            <div class="col-lg-3" style="text-align:justify">
              <button type="button" class="btn" routerLink="/ReportsAndQueries"><img class="tableheadericons" src="../../../../assets/img/icons/back.png" />Back</button>&nbsp;
              <button type="button" class="btn" pSortableColumn="ProjectName"><img class="tableheadericons" src="../../../../assets/img/icons/sort.png" /><p-sortIcon hidden field="ActivityType"></p-sortIcon>Sort</button>
              <button type="button" style="" class="btn" *ngIf="!Pinned" (click)="Pined()"><img class="tableheadericons" src="https://img.icons8.com/dotty/80/000000/pin3.png" /> Pin</button>
              <button type="button" style="" class="btn" *ngIf="Pinned" (click)="UnPin()"><img class="tableheadericons" src="https://img.icons8.com/dusk/64/000000/pin3.png" /> Un Pin</button>
              <!--<i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top"
            aria-label="" (click)="dt.exportCSV()" style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:8px;"></i>-->
            </div>
            <div class="col-lg-3" style="margin-bottom: 0;">
              <p-autoComplete [style]="{'height':'27px'}" [suggestions]="autofillProjects" (completeMethod)="filterProjects($event)" [ngModelOptions]="{standalone: true}" field="ProjectCode" [(ngModel)]="ProjectCode" [dropdown]="true" [size]="30"
                              placeholder="Search with project code" [minLength]="1">
                <ng-template style="height:2rem;" let-autofillProjects pTemplate="item">
                  <div class="ui-helper-clearfix" style="padding-left:10px ">
                    <div class="row" (click)="GetProjectData(autofillProjects.Id)">
                      <div style="height:15px">
                        {{autofillProjects.ProjectCode}}
                      </div>
                    </div>
                  </div>
                </ng-template>
              </p-autoComplete>
            </div>
            <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
              <!--<i class="fa fa-search" style="margin:4px 4px 0 0"></i>-->
              {{dt1.filteredValue?dt1.filteredValue.length:dt1.totalRecords}} of {{dt1.totalRecords}}
              <input type="text" class="global_search" pInputText size="50" placeholder="Filter By..." (input)="dt1.filterGlobal($event.target.value, 'contains')" style="width:auto">
              <div class="btn-group">
                <button type="button" class="btn dropdown-toggle no-caret bg-transparent border-0 box-shadow-none" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i style="font-size: 18px" class="fa fa-ellipsis-v"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right box-shadow rounded-0">
                  <a class="dropdown-item" href="javascript:void(0);" (click)="refresh()"><i class="fa fa-refresh"></i> Refresh</a>
                  <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-cloud-upload"></i> Import</a>
                  <a class="dropdown-item" href="javascript:void(0);" (click)="dt1.exportCSV()"><i class="fa fa-file-excel-o"></i> Export</a>
                  <a class="dropdown-item" href="javascript:void(0);" (click)="Print()"><i class="fa fa-print"></i> Print</a>
                  <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-history"></i> History</a>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col *ngFor="let col of cols1" [ngStyle]="{'width': col.width}">
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              <span>{{col.header}}</span>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td *ngFor="let col of cols1" [ngSwitch]="col.field">
              <span *ngSwitchDefault>{{rowData[col.field]}}</span>
              <span *ngSwitchCase="'StartDate'">{{rowData[col.field] | date:'dd-MMM-yyyy'}}</span>
              <span *ngSwitchCase="'EndDate'">{{rowData[col.field] | date:'dd-MMM-yyyy'}}</span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div *ngIf="menuId==1" class="col-lg-12">
      <p-table #dt [columns]="cols" [value]="homeInspector" [rows]="10" [responsive]="true" [scrollable]="true" scrollHeight="333px">
        <ng-template pTemplate="caption">
          <div class="row">
            <div class="col-lg-3" style="text-align:justify">
              <button type="button" class="btn" routerLink="/ReportsAndQueries"><img class="tableheadericons" src="../../../../assets/img/icons/back.png" />Back</button>&nbsp;
              <button type="button" class="btn" pSortableColumn="ProjectName"><img class="tableheadericons" src="../../../../assets/img/icons/sort.png" /><p-sortIcon hidden field="ActivityType"></p-sortIcon>Sort</button>
              <button type="button" style="" class="btn" *ngIf="!Pinned" (click)="Pined()"><img class="tableheadericons" src="https://img.icons8.com/dotty/80/000000/pin3.png" /> Pin</button>
              <button type="button" style="" class="btn" *ngIf="Pinned" (click)="UnPin()"><img class="tableheadericons" src="https://img.icons8.com/dusk/64/000000/pin3.png" /> Un Pin</button>
              <!--<i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top"
            aria-label="" (click)="dt.exportCSV()" style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:8px;"></i>-->
            </div>
            <div class="col-lg-3" style="margin-bottom: 0;">
              <p-autoComplete [suggestions]="autofillUsers" (completeMethod)="filterUsersSingle($event)" [ngModelOptions]="{standalone: true}" field="FirstName" [dropdown]="true" [(ngModel)]="InspectorName" [size]="30"
                              placeholder="Search Inspector" [minLength]="1"
                              [style]="{'height': '33px','font-size':'15px !important'}">
                <ng-template let-autofillUsers pTemplate="item" style="max-height:500px !important">
                  <div class="ui-helper-clearfix" style="border-bottom:1px solid #D5D5D5;padding:3%;">
                    <div class="row" (click)="AssignInspection(autofillUsers)">
                      <div>
                        {{autofillUsers.FirstName}}
                      </div>
                    </div>
                  </div>
                </ng-template>
              </p-autoComplete>
            </div>
            <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
              <!--<i class="fa fa-search" style="margin:4px 4px 0 0"></i>-->
              {{dt.filteredValue?dt.filteredValue.length:dt.totalRecords}} of {{dt.totalRecords}}
              <input type="text" class="global_search" pInputText size="50" placeholder="Filter By..." (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
              <div class="btn-group">
                <button type="button" class="btn dropdown-toggle no-caret bg-transparent border-0 box-shadow-none" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i style="font-size: 18px" class="fa fa-ellipsis-v"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right box-shadow rounded-0">
                  <a class="dropdown-item" href="javascript:void(0);" (click)="refresh()"><i class="fa fa-refresh"></i> Refresh</a>
                  <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-cloud-upload"></i> Import</a>
                  <a class="dropdown-item" href="javascript:void(0);" (click)="dt.exportCSV()"><i class="fa fa-file-excel-o"></i> Export</a>
                  <a class="dropdown-item" href="javascript:void(0);" (click)="Print()"><i class="fa fa-print"></i> Print</a>
                  <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-history"></i> History</a>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col *ngFor="let col of cols" [ngStyle]="{'width': col.width}">
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              <span>{{col.header}}</span>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td *ngFor="let col of cols" [ngSwitch]="col.field">
              <span *ngSwitchDefault>{{rowData[col.field]}}</span>
              <span *ngSwitchCase="'PercentageOfCompletion'">{{rowData[col.field] | number}}%</span>
              <span *ngSwitchCase="'CreatedDate'">{{rowData[col.field] | date:'dd-MMM-yyyy'}}</span>
              <span *ngSwitchCase="'EndDate'">{{rowData[col.field] | date:'dd-MMM-yyyy'}}</span>
              <span *ngSwitchCase="'EarliestStartDate'">{{rowData[col.field] | date:'dd-MMM-yyyy'}}</span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <!--Table for Matrix Report in Inspectors vs Objectives-->
    <div *ngIf="menuId==4" class="col-lg-12">
      <p-table #dt5 [columns]="cols4" [value]="inspectionReport" [rows]="10" [responsive]="true" [scrollable]="true" scrollHeight="333px">
        <ng-template pTemplate="caption">
          <div class="row">
            <div class="col-lg-3" style="text-align:justify">
              <button type="button" class="btn" routerLink="/ReportsAndQueries"><img class="tableheadericons" src="../../../../assets/img/icons/back.png" />Back</button>&nbsp;
              <button type="button" class="btn" pSortableColumn="Objective"><img class="tableheadericons" src="../../../../assets/img/icons/sort.png" /><p-sortIcon hidden field="ActivityType"></p-sortIcon>Sort</button>
              <button type="button" style="" class="btn" *ngIf="!Pinned" (click)="Pined()"><img class="tableheadericons" src="https://img.icons8.com/dotty/80/000000/pin3.png" /> Pin</button>
              <button type="button" style="" class="btn" *ngIf="Pinned" (click)="UnPin()"><img class="tableheadericons" src="https://img.icons8.com/dusk/64/000000/pin3.png" /> Un Pin</button>
              <!--<i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top"
        aria-label="" (click)="dt.exportCSV()" style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:8px;"></i>-->
            </div>
            <div class="col-lg-4" style="margin-bottom: 0;">
              <select class="custom-select" (change)="onChange($event.target.value)" style="    width: 35%;">
                <option value="0">All </option>
                <option *ngFor="let item of Category" [value]="item.Id">{{item.GroupName}}</option>
              </select>
              &nbsp;
              <!--<button type="button" class="btn btn-primary" (click)="isTransactionSaved ? onRowUpdate() : onRowEditSave()">Save</button> &nbsp;
        <button type="button" class="btn btn-danger" (click)="inspectionCancel()">Cancel</button>-->

            </div>
            <div class="col-lg-5" style="text-align: right;margin-bottom: 0;">
              <!--<i class="fa fa-search" style="margin:4px 4px 0 0"></i>-->
              {{dt5.filteredValue?dt5.filteredValue.length:dt5.totalRecords}} of {{dt5.totalRecords}}
              <input type="text" class="global_search" pInputText size="50" placeholder="Filter By..." (input)="dt5.filterGlobal($event.target.value, 'contains')" style="width:auto">
              <div class="btn-group">
                <button type="button" class="btn dropdown-toggle no-caret bg-transparent border-0 box-shadow-none" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i style="font-size: 18px" class="fa fa-ellipsis-v"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right box-shadow rounded-0">
                  <a class="dropdown-item" href="javascript:void(0);" (click)="refresh()"><i class="fa fa-refresh"></i> Refresh</a>
                  <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-cloud-upload"></i> Import</a>
                  <a class="dropdown-item" href="javascript:void(0);" (click)="dt.exportCSV()"><i class="fa fa-file-excel-o"></i> Export</a>
                  <a class="dropdown-item" href="javascript:void(0);" (click)="Print()"><i class="fa fa-print"></i> Print</a>
                  <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-history"></i> History</a>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col *ngFor="let col of cols4" [ngStyle]="{'width': col.width}">
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              <span>{{col.header}}</span>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td *ngFor="let col of cols4" [ngSwitch]="col.field">
              <span *ngSwitchDefault>{{rowData[col.field]}}</span>
            </td>
          </tr>
        </ng-template>
      </p-table>
      
    </div>

    <div *ngIf="menuId==5" class="col-lg-12">
      <p-table #dt6 [columns]="cols" [value]="Category" [rows]="10" [responsive]="true" [scrollable]="true" scrollHeight="333px">
        <ng-template pTemplate="caption">
          <div class="row">
            <div class="col-lg-7" style="text-align:justify">
              <button type="button" class="btn" routerLink="/ReportsAndQueries"><img class="tableheadericons" src="../../../../assets/img/icons/back.png" />Back</button>&nbsp;
              <button type="button" style="" class="btn" *ngIf="!Pinned" (click)="Pined()"><img class="tableheadericons" src="https://img.icons8.com/dotty/80/000000/pin3.png" /> Pin</button>&nbsp;
              <button type="button" style="" class="btn" *ngIf="Pinned" (click)="UnPin()"><img class="tableheadericons" src="https://img.icons8.com/dusk/64/000000/pin3.png" /> Un Pin</button>&nbsp;
              <!--<i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top"
  aria-label="" (click)="dt.exportCSV()" style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:8px;"></i>-->
              <!--<span class="btn">Number of Inspections per Inspectors</span>-->
            </div>            
            <!--<div class="col-lg-5" style="text-align:justify">
            <span class="btn">Number of Inspections per Inspectors</span>
            </div>-->
          </div>
        </ng-template>
      </p-table>
      <div>
        <ngx-charts-pie-chart [results]="data" [labels]="true" [scheme]="colorScheme">
        </ngx-charts-pie-chart>
        <!--<ngx-charts-pie-chart [scheme]="colorScheme"
                        [results]="data"
                        [legend]="showLegend"
                        [explodeSlices]="explodeSlices"
                        [labels]="showLabels"
                        [doughnut]="doughnut"
                        [gradient]="gradient">
  </ngx-charts-pie-chart>-->
      </div>
    </div>

    <div *ngIf="menuId==3" class="col-lg-12">
      <p-table #dt3 [columns]="cols2" [value]="homeInspector" dataKey="FirstName" [rows]="10" [responsive]="true" [scrollable]="true" scrollHeight="333px">
        <ng-template pTemplate="caption">
          <div class="row">
            <div class="col-lg-3" style="text-align:justify">
              <button type="button" class="btn" routerLink="/ReportsAndQueries"><img class="tableheadericons" src="../../../../assets/img/icons/back.png" />Back</button>&nbsp;
              <button type="button" class="btn" pSortableColumn="ProjectName"><img class="tableheadericons" src="../../../../assets/img/icons/sort.png" /><p-sortIcon hidden field="ActivityType"></p-sortIcon>Sort</button>
              <button type="button" style="" class="btn" *ngIf="!Pinned" (click)="Pined()"><img class="tableheadericons" src="https://img.icons8.com/dotty/80/000000/pin3.png" /> Pin</button>
              <button type="button" style="" class="btn" *ngIf="Pinned" (click)="UnPin()"><img class="tableheadericons" src="https://img.icons8.com/dusk/64/000000/pin3.png" /> Un Pin</button>
              <!--<i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top"
            aria-label="" (click)="dt.exportCSV()" style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:8px;"></i>-->
            </div>
            <div class="col-lg-3" style="margin-bottom: 0;">
            </div>
            <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
              <!--<i class="fa fa-search" style="margin:4px 4px 0 0"></i>-->
              {{dt3.filteredValue?dt3.filteredValue.length:dt3.totalRecords}} of {{dt3.totalRecords}}
              <input type="text" class="global_search" pInputText size="50" placeholder="Filter By..." (input)="dt3.filterGlobal($event.target.value, 'contains')" style="width:auto">
              <div class="btn-group">
                <button type="button" class="btn dropdown-toggle no-caret bg-transparent border-0 box-shadow-none" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i style="font-size: 18px" class="fa fa-ellipsis-v"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right box-shadow rounded-0">
                  <a class="dropdown-item" href="javascript:void(0);" (click)="refresh()"><i class="fa fa-refresh"></i> Refresh</a>
                  <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-cloud-upload"></i> Import</a>
                  <a class="dropdown-item" href="javascript:void(0);" (click)="dt.exportCSV()"><i class="fa fa-file-excel-o"></i> Export</a>
                  <a class="dropdown-item" href="javascript:void(0);" (click)="Print()"><i class="fa fa-print"></i> Print</a>
                  <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-history"></i> History</a>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col style="width:36px">
            <col style="width:36px">
            <col style="width:100px">
            <col style="width:100px">
            <col style="width:100px">
            <col style="width:100px">
            <col style="width:100px">
            <col style="width:100px">
            <col style="width:100px" />
          </colgroup>
        </ng-template>
        <!--<ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col *ngFor="let col of cols2" [ngStyle]="{'width': col.width}">
        </colgroup>
      </ng-template>-->
        <ng-template pTemplate="header">
          <tr>
            <th></th>
            <th style="width:55px">Id</th>
            <th pResizableColumn>Inspector Name</th>
            <th>Client Name</th>
            <th pResizableColumn>ProjectName</th>
            <th>Inspection Id</th>
            <th>%OfCompletion</th>
            <th>SpentHours</th>
            <th>Inspections Count</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-expanded="expanded">
          <tr>
            <td>
              <button type="button" pButton pRipple (click)="viewClients(rowData,expanded)" [pRowToggler]="rowData" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
            </td>
            <td>{{rowData.Id}}</td>
            <td style="width:15px !important" class="rowEditCursor" pEditableColumn>
              {{rowData.FirstName}}
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{rowData.spentHours}}</td>
            <td>{{rowData.Count}}</td>
          </tr>
        </ng-template>

        <!---------Client expanded row---------->
        <ng-template pTemplate="rowexpansion" let-rowdata>
          <tr>
            <td colspan="9">
              <div class="p-p-3">
                <p-table [value]="clientlist" dataKey="CompanyName">
                  <ng-template pTemplate="colgroup" let-columns>
                    <colgroup>
                      <col style="width:36px">
                      <col style="width:100px">
                      <col style="width:36px">
                      <col style="width:100px">
                      <col style="width:100px">
                      <col style="width:100px">
                      <col style="width:100px">
                      <col style="width:100px">
                      <col style="width:100px" />
                    </colgroup>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowdata1 let-expanded="expanded1">
          <tr>
            <td></td>
            <td></td>
            <td style="width: 6.5%;">
              <button type="button" (click)="viewProjects(rowdata1)" pButton pRipple [pRowToggler]="rowdata1" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded1 ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
            </td>
            <td>{{rowdata1.CompanyName}}</td>
            <td></td>
            <!--<td>{{rowdata1.ProjectCount}}</td>-->
            <td></td>
            <td></td>
            <td>{{rowdata1.spentHours}}</td>
            <td>{{rowdata1.ProjectCount}}</td>
          </tr>
        </ng-template>
        <!-----------------------project expanded row---------------------->
        <ng-template pTemplate="rowexpansion" let-rowdata>
          <tr>
            <td colspan="9">
              <div class="p-p-3">
                <p-table [value]="projectlist" dataKey="ProjectName">
                  <ng-template pTemplate="colgroup" let-columns>
                    <colgroup>
                      <col style="width:36px">
                      <col style="width:100px">
                      <col style="width:100px">
                      <col style="width:36px">
                      <col style="width:100px">
                      <col style="width:100px">
                      <col style="width:100px">
                      <col style="width:100px">
                      <col style="width:100px" />
                    </colgroup>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowdata1 let-expanded="expanded2">
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td style="width: 6.5%;">
              <button type="button" (click)="viewInspections(rowdata1)" pButton pRipple [pRowToggler]="rowdata1" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded2 ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
            </td>
            <td>{{rowdata1.ProjectName}}</td>
            <td></td>
            <td></td>
            <td>{{rowdata1.spentHours}}</td>
            <td>{{rowdata1.inspectionscount}}</td>
          </tr>
        </ng-template>
        <!-----------inspections expanded row------------->
        <ng-template pTemplate="rowexpansion" let-rowdata>
          <tr>
            <td colspan="9">
              <div class="p-p-3">
                <p-table [value]="inspectionList" dataKey="id">
                  <ng-template pTemplate="colgroup" let-columns>
                    <colgroup>
                      <col style="width:36px">
                      <col style="width:36px">
                      <col style="width:100px">
                      <col style="width:100px">
                      <col style="width:100px">
                      <col style="width:100px">
                      <col style="width:100px">
                      <col style="width:100px">
                    </colgroup>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowdata1 let-expanded="expanded">
          <tr>
            <td style="width: 6.5%;"></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{rowdata1.id}}</td>
            <td>{{rowdata1.PercentageOfCompletion}}</td>
            <td>{{rowdata1.SpentHours}}</td>
            <td>1</td>
          </tr>
        </ng-template>

      </p-table>
    </div>
    </td>
    </tr>
    </ng-template>
    </p-table>
  </div>
      </td>
      </tr>
      </ng-template>
      </p-table>
    </div>
      </td>
      </tr>
      </ng-template>
      </p-table>
    </div>
    </div>
    </div>



    <style>
      .card_1 {
        border: 1px solid #c1c1c175;
        /* padding: 35px; */
        height: 153px;
        margin-bottom: 15px;
        font-size: 13px;
        padding: 19% 0% 16% 0%;
        text-align: center;
      }

      .head {
        background: white;
        padding: 18px;
        box-shadow: 0 0px 3px rgba(0,0,0,0.2), 0 1px 0px rgb(0 0 0 / 5%);
      }

      .myc {
        margin: 5% 0%;
        font-size: 13px;
        letter-spacing: 0.2px;
        font-weight: bold;
      }
    </style>
