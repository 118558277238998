import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../Services/general.service';
import { Menu } from '../../theme/components/menu/menu.model';
import { MenuService } from '../../theme/components/menu/menu.service';
import { Settings } from '../../app.settings.model';
import { AppSettings } from '../../app.settings';
import { ActivatedRoute } from '@angular/router';
import { ToastrService, GlobalConfig } from 'ngx-toastr';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-report-master',
  templateUrl: './report-master.component.html',
  styleUrls: ['./report-master.component.scss'],
  providers: [MenuService],
  animations: [
    trigger('rowExpansionTrigger', [
      state('void', style({
        transform: 'translateX(-10%)',
        opacity: 0
      })),
      state('active', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class ReportMasterComponent implements OnInit {
  header: any;
  menuId: any;
  cols4: { field: string; header: string; width: string; }[];
  inspectionReport: any;
  Category: any;
  expanded: boolean;
  inspectionList: any;
  clientlist: any;
  InspectorId: any;
  projectlist: any;
  cols2: { field: string; header: string; width: string; }[];
  cols1: { field: string; header: string; width: string; }[];
  autofillProjects: any[];
  ProjectData: any;
  InspectorList: any;
  autofillUsers: any[];
  Id: any;
  cols: { field: string; header: string; width: string; }[];
  homeInspector: any;
  PinId: any;
  InspectorName: any;
  RoleId: any;
  ProjectCode: any;
  Pinned: boolean;
  MenuId: any;
  public menus: Array<Menu>;
  public settings: Settings;
  LoginDetails: any;
  options: GlobalConfig;
  data: any;

  public showLegend = false;
  public gradient = true;
  public colorScheme = {
    domain: ['#2F3E9E', '#D22E2E', '#378D3B']
  };
  public showLabels = true;
  public explodeSlices = true;
  public doughnut = false;
  constructor(private generalService: GeneralService,
    public appSettings: AppSettings, public menuService: MenuService, public route: ActivatedRoute,
    public toastrService: ToastrService) {
    this.options = this.toastrService.toastrConfig;
    debugger

    this.LoginDetails = JSON.parse(localStorage.getItem('LoginDetails'));
    this.settings = this.appSettings.settings;
    this.menuId = route.snapshot.params['Id'];
    this.RoleId = this.LoginDetails.logindetails.RoleId
    this.MenuId = localStorage.getItem('MenuId')
    this.AddRecents();
    this.GetPinned();
  }

  ngOnInit(): void {
    if (this.menuId == 1) {
      this.cols = [
        { field: 'Id', header: 'Id', width: '30px' },
        { field: 'ProjectName', header: 'ProjectName', width: '100px' },
        { field: 'InspectionStatus', header: 'InspectionStatus', width: '100px' },
        { field: 'FirstName', header: 'InspectorName', width: '100px' },
        { field: 'CreatedDate', header: 'Start Date', width: '70px' },
        { field: 'EndDate', header: 'Finish Date', width: '80px' },
        { field: 'EarliestStartDate', header: 'Earliest Start Date', width: '80px' },
        { field: 'EstimatedHours', header: 'Effort (Hours)', width: '70px' },
        { field: 'SpentHours', header: 'Effort Completed (Hours)', width: '80px' },
        { field: 'PercentageOfCompletion', header: '% Complete', width: '60px' },
      ]
      this.header = 'Inspector wise Project details' 
      this.GetInspectors();
    }
    else if (this.menuId == 2) {
      this.cols1 = [
        { field: 'Id', header: 'Inspector Id', width: '60px' },
        { field: 'InspectorName', header: 'InspectorName', width: '100px' },
        { field: 'ProjectCode', header: 'ProjectCode', width: '100px' },
        { field: 'ProjectName', header: 'ProjectName', width: '100px' },
        { field: 'InspectionStatus', header: 'InspectionStatus', width: '100px' },
        { field: 'StartDate', header: 'StartDate', width: '70px' },
        { field: 'EndDate', header: 'EndDate', width: '70px' },
        { field: 'CreateBy', header: 'CreatedBy', width: '70px' },
        { field: 'ModifiBy', header: 'ModifiedBy', width: '70px' },
      ]
      this.header='Inspectors, Inspection status details based on Projectcode'
      this.getProjects();
    }
    else if (this.menuId == 3) {
      this.cols2 = [
        { field: 'Id', header: 'Inspector Id', width: '60px' },
        { field: 'FirstName', header: 'FirstName', width: '100px' },
        { field: 'Count', header: 'Total No Clients', width: '100px' },
        { field: 'CreatedDate', header: 'CreatedDate', width: '100px' },
        { field: 'CreateBy', header: 'CreatedBy', width: '100px' },
        { field: 'ModifiedDate', header: 'ModifiedDate', width: '100px' },
        { field: 'ModifiBy', header: 'ModifiedBy', width: '100px' },
      ]
      this.header='Nuber of clients vs Projects vs Inspections per a Inspectors Report'
      this.getHomeInspector()
    }
    else if (this.menuId == 4) {
      this.cols4 = [
        { field: 'Objective', header: 'Objective', width:'100px' },
        { field: 'Anand', header: 'Anand Kumar', width: '100px' },
        { field: 'Mahesh', header: 'Mahesh', width: '100px' },
        { field: 'Mamatha', header: 'Mamatha', width: '100px' },
        { field: 'Subramanyam', header: 'Subramanyan', width: '100px' },
        { field: 'Padmasri', header: 'Padmasri', width: '100px' },
      ]
      this.header='Objectives vs Inspectors Report'
      this.getGroups()
      this.GetInspectElementsReportBasedOnGroup()
    }
    else if (this.menuId == 5) {
      this.cols = [
        { field: 'Id', header: 'Id', width: '30px' },
        { field: 'Objective', header: 'Objective', width: '100px' },
        { field: 'ProjectName', header: 'Anand Kumar', width: '100px' },
        { field: 'InspectionStatus', header: 'Mahesh', width: '100px' },
        { field: 'FirstName', header: 'Mamatha', width: '100px' },
        { field: 'CreatedDate', header: 'Subramanyan', width: '100px' },
        { field: 'EndDate', header: 'Abhijith', width: '100px' },
      ]
      this.header ='Number of Inspections per Inspectors'
      this.getGroups()
      this.GetInspectorsInspectionCount()
    }
  }



  AddRecents() {
    debugger
    let Array = [];
    Array.push({
      MenuId: this.MenuId, UserId: this.LoginDetails.logindetails.Id
    })
    var UploadFile = new FormData();
    UploadFile.append("Recent", JSON.stringify(Array));
    var url = 'api/Values/AddRecent';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data == "SUCCESS") {
        debugger
        var UploadFile = new FormData();
        UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
        UploadFile.append("flag", '1');
        var url = 'api/Values/GetSideMenusWithRecentPinned';
        this.generalService.PostData(url, UploadFile).then((data1: any) => {
          debugger
          var Details = this.generalService.SIdeMenus(data1);
          this.menuService.RefreshMenu(Details, this.settings.theme.menu);
          debugger
        });
      }

    });
  }

  Pined() {
    debugger
    this.Pinned = true;

    let Array = [];
    Array.push({
      MenuId: this.MenuId, UserId: this.LoginDetails.logindetails.Id
    })
    var UploadFile = new FormData();
    UploadFile.append("Pinned", JSON.stringify(Array));
    var url = 'api/Values/AddPinned';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data == "SUCCESS") {
        debugger
        this.GetPinned();
        this.toastrService.clear();
        this.toastrService.success('Pinned..!', '');
        var UploadFile = new FormData();
        UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
        //UploadFile.append("flag", '1');
        var url = 'api/Values/GetSideMenusWithRecentPinned';
        this.generalService.PostData(url, UploadFile).then((data1: any) => {
          debugger
          var Details = this.generalService.SIdeMenus(data1);
          this.menuService.RefreshMenu(Details, this.settings.theme.menu);
          debugger
        });

      }

    });
  }

  UnPin() {
    debugger

    var UploadFile = new FormData();
    UploadFile.append("PinId", this.PinId);
    UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
    var url = 'api/Values/DeletePinned';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data == "SUCCESS") {
        debugger
        this.Pinned = false;
        this.toastrService.clear();
        this.toastrService.info('Un Pinned..!', '');
        var UploadFile = new FormData();
        UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
        var url = 'api/Values/GetSideMenusWithRecentPinned';
        this.generalService.PostData(url, UploadFile).then((data1: any) => {
          debugger
          var Details = this.generalService.SIdeMenus(data1);
          this.menuService.RefreshMenu(Details, this.settings.theme.menu);
          debugger
        });
      }
    });
  }

  GetPinned() {
    debugger
    var UploadFile = new FormData();
    UploadFile.append("MenuId", this.MenuId);
    UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
    UploadFile.append("RouterLink", '');
    var url = 'api/Values/GetPinned';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data.MSG == "EXISTS") {
        debugger
        this.PinId = data.PinId;
        this.Pinned = true;
      }
      else if (data.MSG == "NOT EXISTS") {
        this.Pinned = false;
      }
    });
  }

  getHomeInspector() {     
    var url = 'api/Master/GetInspectorDetails';
    this.generalService.GetData(url).then((data: any) => {
      this.homeInspector = data;
    });
  }

  /*filter inspetors*/
  filterUsersSingle(event) {
    debugger


    let filtered: any[] = [];
    let query = event.query;
    this.autofillUsers = [];

    for (let i = 0; i < this.InspectorList.length; i++) {
      let country = this.InspectorList[i];
      if (country.FirstName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
      }
    }

    this.autofillUsers = filtered;
  }
  /*end*/
  GetInspectors() {
    var url = '/api/Master/GetInspectorList';
    this.generalService.GetData(url).then((data: any) => {
      debugger
      this.InspectorList = data;
    });
  }

  AssignInspection(InspectorName) {
    debugger
    this.Id = InspectorName.Id;
    var UploadFile = new FormData();
    UploadFile.append('StartDate', '');
    UploadFile.append('EndDate', '');
    UploadFile.append('InspectorId', this.Id);
    UploadFile.append('Flag', '5');
    var url = 'api/Values/GetInspectorInspections';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      this.homeInspector = data;
      this.homeInspector.forEach(hrs => (hrs.SpentHours = (hrs.SpentHours / 60).toFixed(2)));
      this.homeInspector.forEach(hrs => (hrs.EstimatedHours = (hrs.EstimatedHours / 60).toFixed(2)));

    });
  }

  getProjects() {
    var url = "api/Master/GetProjects";
    this.generalService.GetData(url).then((data: any) => {
      if (data != null) {
        var obj = Object.keys(data[0])
        this.ProjectData = data;
      }
    }, err => {
      // this.spinner.hide();
      this.generalService.ShowAlert('ERROR', 'Something Went wrong', 'error');
    });
  }

  filterProjects(event) {
    debugger
    let query = event.query;
    const result = this.ProjectData.filter(p => p.ProjectCode.includes(query));
    // const result = this.Companydata.filter(p => p.CompanyName.includes(query));
    if (result.length == 0) {
    }
    else {
      this.autofillProjects = [];
      this.autofillProjects = result;
    }
  }

  GetProjectData(id) {
    debugger
    this.Id=id
    var UploadFile = new FormData();
    UploadFile.append('ProjectId', this.Id);
    var url = 'api/Master/GeInspectionDetailsBasedOnProjectId';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      this.homeInspector = data;
    });
  }


  viewClients(rowData,expanded) {
    debugger
    this.clientlist = null;
    this.InspectorId = rowData.Id
    var UploadFile = new FormData();
    UploadFile.append('Inspectorid', this.InspectorId);
    var url = 'api/Values/GetCompanyDetailsBasedOnInspectorId';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data != '') {
        this.clientlist = data;
      }
      else {
        this.clientlist = null;
      }
    });
  }
  viewProjects(rowData){
    debugger
    this.projectlist=null
    this.Id = rowData.Id
    var UploadFile = new FormData();
    UploadFile.append('Inspectorid', this.InspectorId);
    UploadFile.append('ClientId',this.Id)
    var url = 'api/Values/GetProjectDetailsBasedInspectorandClientId';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      this.projectlist = data;
    });
  }

  viewInspections(rowData) {
    debugger
    this.Id = rowData.Id
    var UploadFile = new FormData();
    UploadFile.append('Inspectorid', this.InspectorId);
    UploadFile.append('ProId', this.Id)
    var url = 'api/Values/GetInsPecDetailsBasedInspectorandProId';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      this.inspectionList = data;
    });
  }

  getGroups() {
    var url = "api/Master/GetGroupDetails";
    this.generalService.GetData(url).then((data: any) => {
      if (data != null) {        
        this.Category = data;
      }
    }, err => {
      // this.spinner.hide();
      this.generalService.ShowAlert('ERROR', 'Something Went wrong', 'error');
    });
  }

  GetInspectorsInspectionCount() {
    var url = 'api/Master/GetInspectorsInspectionCount';
    this.generalService.GetData(url).then((data: any) => {
      this.data = data;
    });
  }

  GetInspectElementsReportBasedOnGroup() {
    debugger
    this.Id = '0';
    var UploadFile = new FormData();
    UploadFile.append('GroupID', this.Id)
    var url = 'api/Values/GetInspectElementsReportBasedOnGroup';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      this.inspectionReport = data;
    });
  }
  onChange(item) {
    debugger
    this.Id = item;
    var UploadFile = new FormData();
    UploadFile.append('GroupID', this.Id)
    var url = 'api/Values/GetInspectElementsReportBasedOnGroup';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      this.inspectionReport = data;
    });
  }
}
