import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../Services/general.service';
import { Menu } from '../../theme/components/menu/menu.model';
import { MenuService } from '../../theme/components/menu/menu.service';
import { Settings } from '../../app.settings.model';
import { AppSettings } from '../../app.settings';
import { ToastrService, GlobalConfig } from 'ngx-toastr';

@Component({
  selector: 'app-quality-audits',
  templateUrl: './quality-audits.component.html',
  styleUrls: ['./quality-audits.component.scss'],
  providers: [MenuService]
})
export class QualityAuditsComponent implements OnInit {
  PinId: any;


  RoleId: any;
  Pinned: boolean;
  MenuId: any;
  public menus: Array<Menu>;
  public settings: Settings;
  LoginDetails: any;
  options: GlobalConfig;
  constructor(private generalService: GeneralService,
    public appSettings: AppSettings, public menuService: MenuService,
    public toastrService: ToastrService) {
    this.options = this.toastrService.toastrConfig;
    debugger

    this.LoginDetails = JSON.parse(localStorage.getItem('LoginDetails'));
    this.settings = this.appSettings.settings;
    this.MenuId = localStorage.getItem('MenuId')
    this.RoleId = this.LoginDetails.logindetails.RoleId
    this.AddRecents();
    this.GetPinned();
  }

  ngOnInit(): void {
  }



  AddRecents() {
    debugger
    let Array = [];
    Array.push({
      MenuId: this.MenuId, UserId: this.LoginDetails.logindetails.Id
    })
    var UploadFile = new FormData();
    UploadFile.append("Recent", JSON.stringify(Array));
    var url = 'api/Values/AddRecent';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data == "SUCCESS") {
        debugger
        var UploadFile = new FormData();
        UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
        UploadFile.append("flag", '1');
        var url = 'api/Values/GetSideMenusWithRecentPinned';
        this.generalService.PostData(url, UploadFile).then((data1: any) => {
          debugger
          var Details = this.generalService.SIdeMenus(data1);
          this.menuService.RefreshMenu(Details, this.settings.theme.menu);
          debugger
        });
      }

    });
  }

  Pined() {
    debugger
    this.Pinned = true;

    let Array = [];
    Array.push({
      MenuId: this.MenuId, UserId: this.LoginDetails.logindetails.Id
    })
    var UploadFile = new FormData();
    UploadFile.append("Pinned", JSON.stringify(Array));
    var url = 'api/Values/AddPinned';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data == "SUCCESS") {
        debugger
        this.GetPinned();
        this.toastrService.clear();
        this.toastrService.success('Pinned..!', '');
        var UploadFile = new FormData();
        UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
        //UploadFile.append("flag", '1');
        var url = 'api/Values/GetSideMenusWithRecentPinned';
        this.generalService.PostData(url, UploadFile).then((data1: any) => {
          debugger
          var Details = this.generalService.SIdeMenus(data1);
          this.menuService.RefreshMenu(Details, this.settings.theme.menu);
          debugger
        });

      }

    });
  }

  UnPin() {
    debugger

    var UploadFile = new FormData();
    UploadFile.append("PinId", this.PinId);
    UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
    var url = 'api/Values/DeletePinned';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data == "SUCCESS") {
        debugger
        this.Pinned = false;
        this.toastrService.clear();
        this.toastrService.info('Un Pinned..!', '');
        var UploadFile = new FormData();
        UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
        var url = 'api/Values/GetSideMenusWithRecentPinned';
        this.generalService.PostData(url, UploadFile).then((data1: any) => {
          debugger
          var Details = this.generalService.SIdeMenus(data1);
          this.menuService.RefreshMenu(Details, this.settings.theme.menu);
          debugger
        });
      }
    });
  }

  GetPinned() {
    debugger
    var UploadFile = new FormData();
    UploadFile.append("MenuId", this.MenuId);
    UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
    UploadFile.append("RouterLink", '');
    var url = 'api/Values/GetPinned';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data.MSG == "EXISTS") {
        debugger
        this.PinId = data.PinId;
        this.Pinned = true;
      }
      else if (data.MSG == "NOT EXISTS") {
        this.Pinned = false;
      }
    });
  }
}

