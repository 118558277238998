<p-tabView (onChange)="handleChange($event)">
  <p-tabPanel header="Request Type" [selected]="MenuId== 1">
    <p-table #dt [columns]="cols" [value]="RequestTypeData" datakey="RequestType" editMode="row" [resizableColumns]="true" columnResizeMode="expand" selectionMode="multiple">
      <ng-template pTemplate="caption">
        <!--<div class="row">
    <div class="col-lg-6" style="text-align:justify">
      <button type="button" class="btn btn-primary" (click)="OpenAddForm()">Add New</button>
      <i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top"
         aria-label="" (click)="dt.exportCSV()" style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:8px;"></i>
    </div>
    <div class="col-lg-6" style="margin-bottom: 0;">
      <i class="fa fa-search" style="margin:6px 6px 0 0"></i>
      <input type="text" class="global_search" pInputText size="70" placeholder="Global Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">

    </div>
  </div>-->
        <div class="row">
          <div class="col-lg-6" style="text-align:justify">
            <button type="button" class="btn" routerLink="/Admin/Main"><img class="tableheadericons" src="../../../../assets/img/icons/back.png" />Back</button>&nbsp;
            <button type="button" class="btn" (click)="OpenAddForm()"><img class="tableheadericons" src="../../../../assets/img/icons/new.png" /> New</button> &nbsp;
            <button type="button" class="btn" pSortableColumn="RequestType"><img class="tableheadericons" src="../../../../assets/img/icons/sort.png" /> <p-sortIcon hidden field="RequestType"></p-sortIcon>Sort</button>
            <button type="button" style="" class="btn" *ngIf="!Pinned" (click)="Pined()"><img class="tableheadericons" src="https://img.icons8.com/dotty/80/000000/pin3.png" /> Pin</button>
            <button type="button" style="" class="btn" *ngIf="Pinned" (click)="UnPin()"><img class="tableheadericons" src="https://img.icons8.com/dusk/64/000000/pin3.png" /> Un Pin</button>
            <!--<i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top"
  aria-label="" (click)="dt.exportCSV()" style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:8px;"></i>-->
          </div>
          <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
            <!--<i class="fa fa-search" style="margin:4px 4px 0 0"></i>-->
            {{dt.filteredValue?dt.filteredValue.length:dt.totalRecords}} of {{dt.totalRecords}}
            <input type="text" class="global_search" pInputText size="50" placeholder="Filter By.." (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
            <div class="btn-group">
              <button type="button" class="btn dropdown-toggle no-caret bg-transparent border-0 box-shadow-none" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i style="font-size: 18px" class="fa fa-ellipsis-v"></i>
              </button>
              <div class="dropdown-menu dropdown-menu-right box-shadow rounded-0">
                <a class="dropdown-item" href="javascript:void(0);" (click)="refresh()"><i class="fa fa-refresh"></i> Refresh</a>
                <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-cloud-upload"></i> Import</a>
                <a class="dropdown-item" href="javascript:void(0);" (click)="dt.exportCSV()"><i class="fa fa-file-excel-o"></i> Export</a>
                <a class="dropdown-item" href="javascript:void(0);" (click)="onPrint()"><i class="fa fa-print"></i> Print</a>
                <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-history"></i> History</a>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th class="stateicon"></th>
          <th class="stateicon"></th>
          <th style="width:55px">
            <div class="p-d-flex p-jc-between p-ai-center">
              ID
              <p-columnFilter type="text" field="Id" display="menu"></p-columnFilter>
            </div>
          </th>
          <th pResizableColumn>
            <div class="p-d-flex p-jc-between p-ai-center">
              Request Type
              <p-columnFilter type="text" field="RequestType" display="menu"></p-columnFilter>
            </div>
          </th>
          <th pResizableColumn>
            <div class="p-d-flex p-jc-between p-ai-center">
              Created Date
              <p-columnFilter type="date" field="CreatedDate" display="menu"></p-columnFilter>
            </div>
          </th>
          <th pResizableColumn>
            <div class="p-d-flex p-jc-between p-ai-center">
              Created By
              <p-columnFilter type="text" field="CreateBy" display="menu"></p-columnFilter>
            </div>
          </th>
          <th pResizableColumn>
            <div class="p-d-flex p-jc-between p-ai-center">
              Modified Date
              <p-columnFilter type="date" field="ModifiedDate" display="menu"></p-columnFilter>
            </div>
          </th>
          <th pResizableColumn>
            <div class="p-d-flex p-jc-between p-ai-center">
              Modified By
              <p-columnFilter type="text" field="ModifiBy" display="menu"></p-columnFilter>
            </div>
          </th>
        </tr>
        
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-editing="editing" let-rowIndex="rowIndex">
        <tr [pEditableRow]="rowData">

          <td class="stateicon" pTooltip="Delete">
            <img src="../../../../assets//img/Info/delete.png" (click)="onDelete(rowData)" style="font-size: 15px;color:red" aria-hidden="true">
          </td>
          <td class="stateicon">
            <img pTooltip="Active" *ngIf="rowData.Status==true" src="../../../../assets//img/Info/check.png" (click)="changeStatus(rowData,false)" />
            <img pTooltip="Deactive" *ngIf="rowData.Status==false" src="../../../../assets//img/Info/closed.png" (click)="changeStatus(rowData,true)" />
          </td>
          <td>{{rowData.Id}}</td>
          <td style="width:15px !important" class="rowEditCursor" pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText [(ngModel)]="rowData.RequestType" (change)="TextBoxChange(rowData,RequestType)" />
              </ng-template>
              <ng-template pTemplate="output">
                {{rowData.RequestType || 0}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            {{rowData.CreatedDate | date:'d/MMM/yyyy H:m'}}
          </td>
          <td>
            {{rowData.CreateBy}}
          </td>
          <td>
            {{rowData.ModifedDate | date:'dd/MMM/yyyy H:m'}}
          </td>
          <td>
            {{rowData.ModifiBy}}
          </td>
        </tr>
      </ng-template>
      <!--<button type="button" class="btn btn-primary" (click)="OpenAddForm()" style="margin-top: 35%;">Add</button>-->

    </p-table>
    <!-- #region Popup for view orders-->
    <div class="modal" id="myModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header state modal-primary">
            <h5 style="text-align:left;" class="modal-title" id="modal-primary-label">{{ModelTitle}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" style="color:white">×</span></button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-lg-3 col-md-3 col-sm-12 col-xs-12">RequestType</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" [(ngModel)]="RequestType" name="RequestType" placeholder="Enter RequestType">
              </div>
              <button type="button" class="btn btn-primary" (click)="AddRequestType()">Add</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- #endregion -->
  </p-tabPanel>
  <p-tabPanel header="Request Method" [selected]="MenuId== 2">
    <p-table #dt1 [columns]="cols" [value]="RequestMethodData" datakey="RequestMethod" editMode="row" [resizableColumns]="true" columnResizeMode="expand" selectionMode="multiple">
      <ng-template pTemplate="caption">
        <!--<div class="row">
    <div class="col-lg-6" style="text-align:justify">
      <button type="button" class="btn btn-primary" (click)="OpenRequestMethodAddForm()">Add New</button>
      <i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top"
         aria-label="" (click)="dt.exportCSV()" style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:8px;"></i>
    </div>
    <div class="col-lg-6" style="margin-bottom: 0;">
      <i class="fa fa-search" style="margin:6px 6px 0 0"></i>
      <input type="text" class="global_search" pInputText size="70" placeholder="Global Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">

    </div>
  </div>-->
        <div class="row">
          <div class="col-lg-6" style="text-align:justify">
            <button type="button" class="btn" routerLink="/Admin/Main"><img class="tableheadericons" src="../../../../assets/img/icons/back.png" />Back</button>&nbsp;
            <button type="button" class="btn" (click)="OpenRequestMethodAddForm()"><img class="tableheadericons" src="../../../../assets/img/icons/new.png" />New</button> &nbsp;
            <button type="button" class="btn" pSortableColumn="RequestMethod"><img class="tableheadericons" src="../../../../assets/img/icons/sort.png" /><p-sortIcon hidden field="RequestMethod"></p-sortIcon>Sort</button>
            <button type="button" style="" class="btn" *ngIf="!Pinned" (click)="Pined()"><img class="tableheadericons" src="https://img.icons8.com/dotty/80/000000/pin3.png" /> Pin</button>
            <button type="button" style="" class="btn" *ngIf="Pinned" (click)="UnPin()"><img class="tableheadericons" src="https://img.icons8.com/dusk/64/000000/pin3.png" /> Un Pin</button>
            <!--<i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top"
  aria-label="" (click)="dt.exportCSV()" style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:8px;"></i>-->
          </div>
          <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
            <!--<i class="fa fa-search" style="margin:4px 4px 0 0"></i>-->
            {{dt1.filteredValue?dt1.filteredValue.length:dt1.totalRecords}} of {{dt1.totalRecords}}
            <input type="text" class="global_search" pInputText size="50" placeholder="Filter By..." (input)="dt1.filterGlobal($event.target.value, 'contains')" style="width:auto">
            <div class="btn-group">
              <button type="button" class="btn dropdown-toggle no-caret bg-transparent border-0 box-shadow-none" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i style="font-size: 18px" class="fa fa-ellipsis-v"></i>
              </button>
              <div class="dropdown-menu dropdown-menu-right box-shadow rounded-0">
                <a class="dropdown-item" href="javascript:void(0);"(click)="refresh()"><i class="fa fa-refresh"></i> Refresh</a>
                <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-cloud-upload"></i> Import</a>
                <a class="dropdown-item" href="javascript:void(0);" (click)="dt.exportCSV()"><i class="fa fa-file-excel-o"></i> Export</a>
                <a class="dropdown-item" href="javascript:void(0);" (click)="onPrint()"><i class="fa fa-print"></i> Print</a>
                <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-history"></i> History</a>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th class="stateicon"></th>
          <th class="stateicon"></th>
          <th style="width:55px">
            <div class="p-d-flex p-jc-between p-ai-center">
              ID
              <p-columnFilter type="text" field="Id" display="menu"></p-columnFilter>
            </div>
          </th>
          <th pResizableColumn>
            <div class="p-d-flex p-jc-between p-ai-center">
              Request Method
              <p-columnFilter type="text" field="RequestMethod" display="menu"></p-columnFilter>
            </div>
          </th>
          <th pResizableColumn>
            <div class="p-d-flex p-jc-between p-ai-center">
              Created Date
              <p-columnFilter type="date" field="CreatedDate" display="menu"></p-columnFilter>
            </div>
          </th>
          <th pResizableColumn>
            <div class="p-d-flex p-jc-between p-ai-center">
              Created By
              <p-columnFilter type="text" field="CreateBy" display="menu"></p-columnFilter>
            </div>
          </th>
          <th pResizableColumn>
            <div class="p-d-flex p-jc-between p-ai-center">
              Modified Date
              <p-columnFilter type="date" field="ModifiedDate" display="menu"></p-columnFilter>
            </div>
          </th>
          <th pResizableColumn>
            <div class="p-d-flex p-jc-between p-ai-center">
              Modified By
              <p-columnFilter type="text" field="ModifiBy" display="menu"></p-columnFilter>
            </div>
          </th>
        </tr>        
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-editing="editing" let-rowIndex="rowIndex">
        <tr [pEditableRow]="rowData">

          <td class="stateicon" pTooltip="Delete">
            <img src="../../../../assets//img/Info/delete.png" (click)="onDelete1(rowData)" style="font-size: 15px;color:red" aria-hidden="true">
          </td>
          <td class="stateicon">
            <img pTooltip="Active" *ngIf="rowData.Status==true" src="../../../../assets//img/Info/check.png" (click)="changeStatus1(rowData,false)" />
            <img pTooltip="Deactive" *ngIf="rowData.Status==false" src="../../../../assets//img/Info/closed.png" (click)="changeStatus1(rowData,true)" />
          </td>
          <td>{{rowData.Id}}</td>
          <td style="width:15px !important" class="rowEditCursor" pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText [(ngModel)]="rowData.RequestMethod" (change)="TextBoxChange(rowData,RequestMethod)" />
              </ng-template>
              <ng-template pTemplate="output">
                {{rowData.RequestMethod || 0}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            {{rowData.CreatedDate | date:'dd/MMM/yyyy H:m'}}
          </td>
          <td>
            {{rowData.CreateBy}}
          </td>
          <td>
            {{rowData.ModifiedDate | date:'dd/MMM/yyyy H:m'}}
          </td>
          <td>
            {{rowData.ModifiBy}}
          </td>
        </tr>
      </ng-template>
      <!--<button type="button" class="btn btn-primary" (click)="OpenAddForm()" style="margin-top: 35%;">Add</button>-->

    </p-table>
    <!-- #region Popup for view orders-->
    <div class="modal" id="myModalMethod">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header state modal-primary">
            <h5 style="text-align:left;" class="modal-title" id="modal-primary-label">{{ModelMethodTitle}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" style="color:white">×</span></button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-lg-3 col-md-3 col-sm-12 col-xs-12">RequestMethod</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" [(ngModel)]="RequestMethod" name="RequestMethod" placeholder="Enter RequestMethod">
              </div>
              <button type="button" class="btn btn-primary" (click)="AddRequestMethod()">Add</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- #endregion -->
    </p-tabPanel>
</p-tabView>

    <style>
      .components {
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 25px;
        margin-top: 16px;
        font-weight: bold;
      }

      .back {
        background: white !important;
        height: 20px !important;
        padding: 1px 7px 7px 7px !important;
        font-size: 13px !important;
      }

      .app.combined .breadcrumb {
        padding: 0.5rem 1rem;
        display: none !important;
      }

      ._card {
        border: 0.5px solid #dedede;
        padding: 15px;
        border-radius: 4px;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
      }

      .head_txt {
        font-size: 12px;
        letter-spacing: 0.2px;
        line-height: 24px;
      }

      .form-control {
        display: block;
        width: 100%;
        height: 23px !important;
        padding: 3px;
        font-size: 13px;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }

      .top_head {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 5px;
        color: #000;
        letter-spacing: 0.5px;
      }

      .divRPimg {
        height: auto;
        padding: 10px 0;
        display: block;
        margin-right: 3%;
        border-radius: 3px;
        box-shadow: 0 4px 9px 0 rgba(67, 65, 79, 0.1);
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
      }
    </style>
