import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
//import { GeneralService } from '../../pages/Services/general.service';
import { GeneralService } from '../../Services/general.service'
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { formatDate } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { PrimeNGConfig } from 'primeng/api';
import { FilterMatchMode } from 'primeng/api';
import Swal from 'sweetalert2';
declare var $;
@Component({
  selector: 'app-objective-management',
  templateUrl: './objective-management.component.html',
  styleUrls: ['./objective-management.component.scss'],
  providers: [DatePipe]
})
export class ObjectiveManagementComponent implements OnInit {
  selected: any;
  Groups: any;
  ObjectiveData: any;
  Objectives: string;
  Status: any;
  LoginDetails: any;
  ModelTitle: string;
  templateoriginal: any;
  template: any;
  flag: any;
  ObjectName: any;
  cols: any;
  addNew: boolean;
  editForm: boolean;
  activitytype: any;
  Id: any;
  public form: FormGroup;
  Grid: boolean = true;
  myDate = new Date();
  todaydate: any;
  Description: any;
  constructor(public config: PrimeNGConfig, public router: Router, public toaster: ToastrService, public fb: FormBuilder, public generalService: GeneralService, private datePipe: DatePipe) {
    //this.myDate = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    this.config.filterMatchModeOptions = {
      text: [
        FilterMatchMode.STARTS_WITH,
        FilterMatchMode.CONTAINS,
        FilterMatchMode.NOT_CONTAINS,
        FilterMatchMode.ENDS_WITH,
        FilterMatchMode.EQUALS,
        FilterMatchMode.NOT_EQUALS,
        FilterMatchMode.LESS_THAN,
        FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
        FilterMatchMode.GREATER_THAN,
        FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
      ],
      numeric: [
        FilterMatchMode.EQUALS,
        FilterMatchMode.NOT_EQUALS,
        FilterMatchMode.LESS_THAN,
        FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
        FilterMatchMode.GREATER_THAN,
        FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
      ],
      date: [
        FilterMatchMode.BEFORE,
        FilterMatchMode.AFTER,
      ]
    }


    this.todaydate = formatDate(this.myDate, 'yyyy-MM-dd hh:mm:ss', 'en-US', '+0530');
    this.LoginDetails = JSON.parse(localStorage.getItem('LoginDetails'))
    this.form = this.fb.group({
      activitytype: ['', Validators.required]
    });

  }

  ngOnInit(): void {
    this.getObjectives();
    this.getGroupDetails();
  }
  //=======print====//
  onPrint() {
    window.print();
  }
  getObjectives() {
    var url = "api/Master/GetObjectiveDetails";
    this.generalService.GetData(url).then((data: any) => {
      debugger
      if (data != null) {
        var obj = Object.keys(data[0])
        this.cols = [
          { field: 'Id', header: 'Id', width: '150px' },
          { field: 'Objective', header: 'TaskType', width: '150px' },
          { field: 'Status', header: 'Status', width: '150px' },
          { field: 'CreatedDate', header: 'CreatedDate', width: '150px' },
          { field: 'CreateBy', header: 'CreatedBy', width: '150px' },
          { field: 'ModifiedDate', header: 'ModifiedDate', width: '150px' },
          { field: 'ModifiBy', header: 'ModifiedBy', width: '150px' },
        ]
        data.forEach(
          dateType => (dateType.CreatedDate = new Date(dateType.CreatedDate))
        );
        data.forEach(
          dateType => (dateType.ModifiedDate = new Date(dateType.ModifiedDate))
        );
        this.ObjectiveData = data;
        this.templateoriginal = data;
      }
    }, err => {
      // this.spinner.hide();
      this.generalService.ShowAlert('ERROR', 'Something Went wrong', 'error');
    });
  }

  getGroupDetails() {

    var url = "api/Master/GetGroupDetails";
    this.generalService.GetData(url).then((data: any) => {
      if (data != null) {
        var obj = Object.keys(data[0])
        this.Groups = data;
      }
    }, err => {
      // this.spinner.hide();
      this.generalService.ShowAlert('ERROR', 'Something Went wrong', 'error');
    });
  }

  OpenAddForm() {

    this.ModelTitle = "Add TaskType";
    $('#myModal').modal('show');
    this.Grid = false;
    this.ObjectName = "";
    this.Description = "";
    this.flag = "1";//for adding
    //this.addNew = true;
    //this.editForm = false;

  }

  TextBoxChange(item, key) {

    let index: number = this.ObjectiveData.findIndex(t => t.Id == item.Id);
    let index1: number = this.templateoriginal.findIndex(t => t.Id == item.Id);
    if (index > -1) {
      this.ObjectiveData[index][key] = item[key];
      this.ObjectiveData = this.ObjectiveData[index].TaskType;
      this.Id = item.Id;
    }
    if (index1 > -1) {
      this.templateoriginal[index][key] = item[key];
      this.templateoriginal[index]['ModifiedBy'] = this.LoginDetails.logindetails.Id;
      this.templateoriginal[index]['ModifiedDate'] = this.todaydate;
    }      
    var UploadFile = new FormData();
    UploadFile.append("Objectives", JSON.stringify(this.templateoriginal));
    var url = 'api/Values/UpdateObjectives';
    this.generalService.PostData(url, UploadFile).then((data: any) => {

      this.ObjectiveData = '';
      if (data != null) {
        this.toaster.success('Objective has been updated successfully', 'Success', {
          timeOut: 2000,
        });
        this.backform();
        this.getObjectives();
      }
    });
  }

  AddObjective(Objective,selected,Descrition) {
    debugger
    var arr = {
      Objective: Objective,
      GroupId: selected,
      Description: Descrition,
      CreatedBy: this.LoginDetails.logindetails.Id
    }

    this.flag = '1';
    var UploadFile = new FormData();
    UploadFile.append("Objectives", JSON.stringify(arr));
    var url = 'api/Values/AddObjectives';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data != null) {
        $('#myModal').modal('hide');
        this.getObjectives();
        this.toaster.success('Objectives has been added successfully', 'Success', {
          timeOut: 2000,
        });
        this.backform();
      }
    });
  }
 
  onDelete(rowData) {
    Swal.fire({
      title: 'Are you sure to Delete  ?',
      //text: "",
      //type: 'warning',
      html: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      debugger
      if (result.value) {
        let Array = [];
        Array.push({
           Id: rowData.Id,
        })
        var UploadFile = new FormData();
        UploadFile.append("Objectives", JSON.stringify(Array));
        var url = 'api/Values/DeleteObjectives';
        this.generalService.PostData(url, UploadFile).then((data: any) => {

          if (data == "success") {
            this.getObjectives();
            this.generalService.ShowAlert('SUCCESS', 'Objective has been Deleted successfully', 'success');
            this.backform();
          }
          else {
            this.generalService.ShowAlert('WARNING', 'You Can not Delete this record because of it is having relation with anather table', 'warning');
            this.backform();
          }
        });
      }
    })
  }

  changeStatus(rowData, status) {
    this.Status = status;

    this.flag = "4";

    let Array = [];
    Array.push({
      Id: rowData.Id, Status: this.Status, ModifiedBy: this.LoginDetails.logindetails.Id, ModifiedDate: this.todaydate
    })
    this.Id = rowData.Id
    var UploadFile = new FormData();
    UploadFile.append("ObjectId", this.Id);
    UploadFile.append("Status", this.Status);
    var url = 'api/Values/ActiveandDeactiveonObjectives';
    this.generalService.PostData(url, UploadFile).then((data: any) => {

      if (data != null) {
        this.getObjectives();
        if (this.Status == true) {
          this.toaster.success('Status Activated Successfully', 'Success', {
            timeOut: 2000,
          });
        }
        else {
          this.toaster.warning('Status Dectivated Successfully', 'Success', {
            timeOut: 2000,
          });
        }
      }
    });
  }

  backform() {
    this.Grid = true;
  }
  refresh() {
    location.reload();
  }
}
