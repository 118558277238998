import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
//import { GeneralService } from '../../pages/Services/general.service';
import { GeneralService } from '../../Services/general.service'
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { formatDate } from '@angular/common';
declare var $;
@Component({
  selector: 'app-company-type-master',
  templateUrl: './company-type-master.component.html',
  styleUrls: ['./company-type-master.component.scss'],
  providers: [DatePipe]
})
export class CompanyTypeMasterComponent implements OnInit {
  Status: any;
  LoginDetails: any;
  ModelTitle: string;
  templateoriginal: any;
  template: any;
  flag: any;
  CompanyType: any;
  cols: any;
  addNew: boolean;
  editForm: boolean;
  activitytype: any;
  Id: any;
  public form: FormGroup;
  Grid: boolean = true;
  myDate = new Date();
  todaydate: any;
  constructor(public router: Router, public fb: FormBuilder, public generalService: GeneralService, private datePipe: DatePipe) {
    //this.myDate = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    this.todaydate = formatDate(this.myDate, 'yyyy-MM-dd hh:mm:ss a', 'en-US', '+0530');
    this.LoginDetails = JSON.parse(localStorage.getItem('LoginDetails'))
    this.form = this.fb.group({
      activitytype: ['', Validators.required]
    });

  }

  ngOnInit(): void {
    this.getCompanyType();
  }
  getCompanyType() {
    
    var url = "api/Master/GetCompanyType";
    this.generalService.GetData(url).then((data: any) => {
      
      console.log(this.LoginDetails)
      if (data != null) {
        var obj = Object.keys(data[0])
        this.cols = [
          { field: 'Id', header: 'Id', width: '150px' },
          { field: 'CompanyType', header: 'CompanyType', width: '150px' },
          { field: 'CreatedDate', header: 'CreatedDate', width: '150px' },
          { field: 'CreateBy', header: 'CreatedBy', width: '150px' },
          { field: 'ModifiedDate', header: 'ModifiedDate', width: '150px' },
          { field: 'ModifiBy', header: 'ModifiedBy', width: '150px' },
        ]
        this.CompanyType = data;
        this.templateoriginal = data;
      }
    }, err => {
      // this.spinner.hide();
      this.generalService.ShowAlert('ERROR', 'Something Went wrong', 'error');
    });
  }

  OpenAddForm() {
    
    this.ModelTitle = "Add CompanyType";
    $('#myModal').modal('show');
    this.Grid = false;
    this.CompanyType = "";
    this.flag = "1";//for adding
    //this.addNew = true;
    //this.editForm = false;

  }

  TextBoxChange(item, key) {
    
    let index: number = this.CompanyType.findIndex(t => t.Id == item.Id);
    let index1: number = this.templateoriginal.findIndex(t => t.Id == item.Id);
    if (index > -1) {
      this.CompanyType[index][key] = item[key];
      this.CompanyType = this.CompanyType[index].CompanyType;
      this.Id = item.Id;
    }
    if (index1 > -1) {
      this.templateoriginal[index][key] = item[key];
      this.templateoriginal[index]['ModifiedBy'] = this.LoginDetails.logindetails.Id;
      this.templateoriginal[index]['ModifiedDate'] = this.todaydate;
    }
    // this.onRowUpadte();
    this.flag = "2"//for Update        
    var UploadFile = new FormData();
    UploadFile.append("CompanyType", JSON.stringify(this.templateoriginal));
    UploadFile.append("flag", this.flag);
    var url = 'api/Master/AddUpdateCompanyTypeMaster';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      
      if (data != null) {
        this.getCompanyType();
        this.generalService.ShowAlert('SUCCESS', 'AddressType has been Updated successfully', 'success');
        this.backform();
      }
    });
  }


  AddCompanyType() {
    
    let Array = [];
    Array.push({
      CompanyType: this.CompanyType, CreatedBy: this.LoginDetails.logindetails.Id
    })
    var UploadFile = new FormData();
    UploadFile.append("CompanyType", JSON.stringify(Array));
    UploadFile.append("flag", this.flag);
    var url = 'api/Master/AddUpdateCompanyTypeMaster';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      
      if (data != null) {
        this.getCompanyType();
        this.generalService.ShowAlert('SUCCESS', 'CompanyType has been added successfully', 'success');
        this.backform();
      }
    });
  }

  onDelete(rowData) {
    this.flag = "3";
    
    let Array = [];
    Array.push({
      CompanyType: rowData.AddressCategory, Id: rowData.Id,
    })
    var UploadFile = new FormData();
    UploadFile.append("CompanyType", JSON.stringify(Array));
    UploadFile.append("flag", this.flag);
    var url = 'api/Master/AddUpdateCompanyTypeMaster';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      
      if (data == "success") {
        this.getCompanyType();
        this.generalService.ShowAlert('SUCCESS', 'CompanyType has been Deleted successfully', 'success');
        this.backform();
      }
      else {
        this.generalService.ShowAlert('WARNING', 'You Can not Delete this record because of it is having relation with anather table', 'warning');
        this.backform();
      }
    });
  }
  changeStatus(rowData, status) {
    this.Status = status;

    this.flag = "4";
    
    let Array = [];
    Array.push({
      Id: rowData.Id, Status: this.Status, ModifiedBy: this.LoginDetails.logindetails.Id, ModifiedDate: this.todaydate
    })
    var UploadFile = new FormData();
    UploadFile.append("CompanyType", JSON.stringify(Array));
    UploadFile.append("flag", this.flag);
    var url = 'api/Master/AddUpdateCompanyTypeMaster';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      
      if (data != null) {
        this.getCompanyType();
      }
    });
  }

  backform() {
    ;
    this.Grid = true;
  }
}
