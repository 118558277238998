import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules  } from '@angular/router'; 
import { PagesComponent } from './pages/pages.component';
import { BlankComponent } from './pages/blank/blank.component';
//import { SearchComponent } from './pages/search/search.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { HomeComponent } from './pages/home/home.component';
import { PageHeadComponent } from './pages/page-head/page-head.component';
import { PageFooterComponent } from './pages/page-footer/page-footer.component';
import { PagelayoutComponent } from './pages/pagelayout/pagelayout.component';
import { CommingsoonComponent } from './pages/commingsoon/commingsoon.component';

import { ComponentComponent } from './pages/masters/component/component.component'
import { ActivitytypeComponent } from './pages/masters/activitytype/activitytype.component'
import { AddressCategoryComponent } from './pages/masters/address-category/address-category.component'
import { AddressTypeMasterComponent } from './pages/masters/address-type-master/address-type-master.component'
import { CampanyStatusMasterComponent } from './pages/masters/campany-status-master/campany-status-master.component';
import { CompanyTypeMasterComponent } from './pages/masters/company-type-master/company-type-master.component';
import { FindingMasterComponent } from './pages/masters/finding-master/finding-master.component';
import { FindingStatusMasterComponent } from './pages/masters/finding-status-master/finding-status-master.component';
import { ProjectStatusComponent } from './pages/masters/project-status/project-status.component';
import { RequestMethodMasterComponent } from './pages/masters/request-method-master/request-method-master.component';
import { RequestTypeMasterComponent } from './pages/masters/request-type-master/request-type-master.component';
import { TaskTypeMasterComponent } from './pages/masters/task-type-master/task-type-master.component';
import { WorkabilityTypeComponent } from './pages/masters/workability-type/workability-type.component';
import { AssignmentsComponent } from './pages/assignments/assignments.component';
import { PerformanceComponent } from './pages/performance/performance.component';
import { ObjectiveManagementComponent } from './pages/masters/objective-management/objective-management.component';
import { CategoryManagementComponent } from './pages/masters/category-management/category-management.component'
import { TemplateManagementComponent } from './pages/masters/template-management/template-management.component'
import { ReportsComponent } from './pages/masters/reports/reports.component'
import { ReportMasterComponent } from './pages/report-master/report-master.component'

import { ReportingComponent        } from './pages/reporting/reporting.component';
import { QualityAssuranceComponent } from './pages/quality-assurance/quality-assurance.component';
import { QualityAuditsComponent     } from './pages/quality-audits/quality-audits.component';
import { ToolsComponent             } from './pages/tools/tools.component';
import { FormsComponent             } from './pages/forms/forms.component';
import { ReportsAndQueriesComponent } from './pages/reports-and-queries/reports-and-queries.component'
import { AdminPageComponent } from './pages/admin-page/admin-page.component'
import { GroupsComponent } from './pages/masters/groups/groups.component'
import { InspectionMasterComponent } from './pages/masters/inspection-master/inspection-master.component'
import { NewProjectComponent } from './pages/masters/new-project/new-project.component'


export const routes: Routes = [
      
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'Home',
    component: PagelayoutComponent,
    children: [
      { path: 'Home', component: HomeComponent}
    ]
  },
  {
    path: '', 
    component: PagesComponent,
    children: [
      { path: '', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), data: { breadcrumb: 'Dashboard' } },
      { path: 'Admin', loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule), data: { breadcrumb: 'Admin' } },
      { path: 'Inspection', loadChildren: () => import('./pages/QC/qc.module').then(m => m.QCModule), data: { breadcrumb: 'QC' } },
      

      { path: 'Component', component: ComponentComponent, data: {breadcrumb:'Component'} },
      { path: 'Activitytype', component: ActivitytypeComponent, data: { breadcrumb: 'Activity type' } },
      { path: 'AddressCategory', component: AddressCategoryComponent, data: { breadcrumb: 'Address Category' } },
      { path: 'AddressManagment/Details/:Id', component: AddressTypeMasterComponent, data: { breadcrumb: 'Address Managment' } },
      { path: 'CompanyManagement/Details/:Id', component: CampanyStatusMasterComponent, data: { breadcrumb: 'Company Management' } },
      { path: 'CompanyType', component: CompanyTypeMasterComponent, data: { breadcrumb: 'Company Type' } },
      { path: 'FindingManagment/Details/:Id', component: FindingMasterComponent, data: { breadcrumb: 'Finding Managment' } },
      { path: 'FindingStatus', component: FindingStatusMasterComponent, data: { breadcrumb: 'Finding Status' } },
      { path: 'Projects/Details/:Id', component: ProjectStatusComponent, data: { breadcrumb: 'Project Management' } },
      { path: 'NewProject', component: NewProjectComponent, data: {breadcrumb:'NewProject'} },
      { path: 'RequestMethod', component: RequestMethodMasterComponent, data: { breadcrumb: 'Request Method' } },
      { path: 'RequestManagement/Details/:Id', component: RequestTypeMasterComponent, data: { breadcrumb: 'Request Management' } },
      { path: 'TaskType', component: TaskTypeMasterComponent, data: { breadcrumb: 'Task Type' } },
      { path: 'WorkabilityType', component: WorkabilityTypeComponent, data: { breadcrumb: 'Workability Type' } },
      { path: 'Reports', component: ReportsComponent, data: {breadcrumb:'Reports'}},
      { path: 'Inspection Items/Details/:Id', component: GroupsComponent, data: { breadcrumb: 'Inspection Items' } },
      { path: 'Commingsoon', component: CommingsoonComponent, data: { breadcrumb: 'Commingsoon' } },
     { path: 'blank', component: BlankComponent, data: { breadcrumb: 'Blank page' } },
      //{ path: 'search', component: SearchComponent, data: { breadcrumb: 'Search' } }
      { path: 'Assignments', component: AssignmentsComponent , pathMatch: 'full', data: { breadcrumb: 'Assignments' } },
      { path: 'Performance', component: PerformanceComponent, pathMatch: 'full', data: { breadcrumb: 'Performance' } },
      { path: 'Objectives', component: ObjectiveManagementComponent, data: { breadcrumb: 'Objectives' } },
      { path: 'Category', component: CategoryManagementComponent, data: { breadcrumb: 'Category' } },
      { path: 'Inspection Templates', component: TemplateManagementComponent, data: { breadcrumb: 'Inspection Templates' } },
      { path: 'Inspections', component: InspectionMasterComponent, data: {breadcrumb:'All Inspections'} },
      { path: 'Reporting', component: ReportingComponent, data: { breadcrumb: 'Reporting' } },
      { path: 'ReportMaster/:Id', component: ReportMasterComponent, data: {breadcrumb:'ReportMaster'}},
      { path: 'QualityAssurance', component: QualityAssuranceComponent, data: { breadcrumb: 'Quality Assurance' } },
      { path: 'QualityAudits', component: QualityAuditsComponent, data: { breadcrumb: 'Quality Audits' } },
      { path: 'Tools', component: ToolsComponent, data: { breadcrumb: 'Tools' } },
      { path: 'Forms', component: FormsComponent, data: { breadcrumb: 'Forms' } },
      { path: 'ReportsAndQueries', component: ReportsAndQueriesComponent, data: { breadcrumb: 'Reports And Queries' } },
      { path: 'AdminPage', component: AdminPageComponent , data: { breadcrumb: 'Admin' } },

    ]
  },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
  { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
  { path: '**', component: NotFoundComponent }
]; 

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,  // <- comment this line for activate lazy load
    useHash: true
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
