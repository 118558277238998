

<div class="row">
  <p-table #dt [columns]="cols" [value]="inspectionData" [scrollable]="true" [style]="{width:'600%'}" datakey="inspectionData" editMode="row" selectionMode="multiple" [resizableColumns]="true" columnResizeMode="expand" style="width:100%;">
    <ng-template pTemplate="caption">
      <!--<div class="row">
      <div class="col-lg-6" style="text-align:justify">
        <button type="button" class="btn btn-primary" (click)="OpenAddForm()">Add New</button>
        <i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top"
           aria-label="" (click)="dt.exportCSV()" style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:8px;"></i>
      </div>
      <div class="col-lg-6" style="margin-bottom: 0;">
        <i class="fa fa-search" style="margin:6px 6px 0 0"></i>
        <input type="text" class="global_search" pInputText size="70" placeholder="Global Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">

      </div>
    </div>-->
      <div class="row">
        <div class="col-lg-6" style="text-align:justify">
          <button type="button" class="btn" routerLink="/Admin/Main"><img class="tableheadericons" src="../../../../assets/img/icons/back.png" />Back</button>&nbsp;
          <button type="button" class="btn" (click)="NewInspectionForm()"><img class="tableheadericons" src="../../../../assets/img/icons/new.png" />New</button> &nbsp;
          <button type="button" class="btn" pSortableColumn="RequestMethod"><img class="tableheadericons" src="../../../../assets/img/icons/sort.png" /> <p-sortIcon hidden field="ActivityType"></p-sortIcon>Sort</button>
          <button type="button" style="" class="btn" *ngIf="!Pinned" (click)="Pined()"><img class="tableheadericons" src="https://img.icons8.com/dotty/80/000000/pin3.png" /> Pin</button>
          <button type="button" style="" class="btn" *ngIf="Pinned" (click)="UnPin()"><img class="tableheadericons" src="https://img.icons8.com/dusk/64/000000/pin3.png" /> Un Pin</button>

          <!--<i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top"
  aria-label="" (click)="dt.exportCSV()" style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:8px;"></i>-->
        </div>
        <div class="col-lg-6" style="text-align: right;margin-bottom: 0;">
          <!--<i class="fa fa-search" style="margin:4px 4px 0 0"></i>-->
          {{dt.filteredValue?dt.filteredValue.length:dt.totalRecords}} of {{dt.totalRecords}}
          <input type="text" class="global_search" pInputText size="50" placeholder="Filter By..." (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
          <div class="btn-group">
            <button type="button" class="btn dropdown-toggle no-caret bg-transparent border-0 box-shadow-none" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i style="font-size: 18px" class="fa fa-ellipsis-v"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-right box-shadow rounded-0">
              <a class="dropdown-item" href="javascript:void(0);" (click)="refresh()"><i class="fa fa-refresh"></i> Refresh</a>
              <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-cloud-upload"></i> Import</a>
              <a class="dropdown-item" href="javascript:void(0);" (click)="dt.exportCSV()"><i class="fa fa-file-excel-o"></i> Export</a>
              <a class="dropdown-item" href="javascript:void(0);" (click)="onPrint()"><i class="fa fa-print"></i> Print</a>
              <a class="dropdown-item" href="javascript:void(0);"><i class="fa fa-history"></i> History</a>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col style="width:36px">
        <col style="width:36px">
        <col style="width:36px">
        <col style="width:150px">
        <col style="width:100px">

        <!--<col style="width:125px">
  <col style="width:125px">-->
        <col style="width:250px">
        <col style="width:250px">
        <col style="width:125px">
        <col style="width:120px">
        <!--<col style="width:120px">-->
        <col style="width:200px">
        <!--<col style="width:130px">-->
        <col style="width:180px">
        <col style="width:180px">
        <col style="width:185px">
        <col style="width:185px">
        <col style="width:185px">
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th class="stateicon"></th>
        <th class="stateicon"></th>
        <!--<th pResizableColumn>
    <div class="p-d-flex p-jc-between p-ai-center">
      Request Method
      <p-columnFilter type="text" field="RequestMethod" display="menu"></p-columnFilter>
    </div>
  </th>
  <th pResizableColumn>
    <div class="p-d-flex p-jc-between p-ai-center">
      Request Type
      <p-columnFilter type="text" field="RequestType" display="menu"></p-columnFilter>
    </div>
  </th>-->

        <th pResizableColumn>

          <div class="p-d-flex p-jc-between p-ai-center">
            Id
            <p-columnFilter type="text" field="Activity" display="menu"></p-columnFilter>
          </div>
        </th>
        <th pResizableColumn>
          <div class="p-d-flex p-jc-between p-ai-center">
            ProjectName
            <p-columnFilter type="text" field="Activity" display="menu"></p-columnFilter>
          </div>
        </th>
        <th pResizableColumn>
          <div class="p-d-flex p-jc-between p-ai-center">
            ProjectCode
            <p-columnFilter type="text" field="ProjectCode" display="menu"></p-columnFilter>
          </div>
        </th>
        <th pResizableColumn>
          <div class="p-d-flex p-jc-between p-ai-center">
            Activity
            <p-columnFilter type="text" field="Activity" display="menu"></p-columnFilter>
          </div>
        </th>
        <th pResizableColumn>
          <div class="p-d-flex p-jc-between p-ai-center">
            Task
            <p-columnFilter type="text" field="Task" display="menu"></p-columnFilter>
          </div>
        </th>
        <th pResizableColumn>
          <div class="p-d-flex p-jc-between p-ai-center">
            Template Name
            <p-columnFilter type="text" field="TemplateName" display="menu"></p-columnFilter>
          </div>
        </th>
        <th pResizableColumn>
          <div class="p-d-flex p-jc-between p-ai-center">
            TemplateType
            <p-columnFilter type="text" field="TemplateType" display="menu"></p-columnFilter>
          </div>
        </th>
        <!--<th pResizableColumn>
    <div class="p-d-flex p-jc-between p-ai-center">
      Task
      <p-columnFilter type="text" field="Task" display="menu"></p-columnFilter>
    </div>
  </th>-->
        <th pResizableColumn>
          <div class="p-d-flex p-jc-between p-ai-center">
            Requestor
            <p-columnFilter type="text" field="Requestor" display="menu"></p-columnFilter>
          </div>
        </th>
        <th pResizableColumn>
          <div class="p-d-flex p-jc-between p-ai-center">
            SubProject
            <p-columnFilter type="text" field="SubProject" display="menu"></p-columnFilter>
          </div>
        </th>

        <th pResizableColumn>
          <div class="p-d-flex p-jc-between p-ai-center">
            Created Date
            <p-columnFilter type="date" field="CreatedDate" display="menu"></p-columnFilter>
          </div>
        </th>
        <th pResizableColumn>
          <div class="p-d-flex p-jc-between p-ai-center">
            Created By
            <p-columnFilter type="text" field="CreateBy" display="menu"></p-columnFilter>
          </div>
        </th>
        <th pResizableColumn>
          <div class="p-d-flex p-jc-between p-ai-center">
            Modified Date
            <p-columnFilter type="date" field="ModifiedDate" display="menu"></p-columnFilter>
          </div>
        </th>
        <th pResizableColumn>
          <div class="p-d-flex p-jc-between p-ai-center">
            Modified By
            <p-columnFilter type="text" field="ModifiBy" display="menu"></p-columnFilter>
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-editing="editing" let-rowIndex="rowIndex">
      <tr>
        <td class="stateicon" pTooltip="Delete">
          <img src="../../../../assets//img/Info/delete.png" (click)="onDelete(rowData)" style="font-size: 15px;align-content:center; color:red" aria-hidden="true">
        </td>
        <td class="stateicon">
          <img pTooltip="Active" *ngIf="rowData.Status==true" src="../../../../assets//img/Info/check.png" (click)="changeStatus(rowData,false)" />
          <img pTooltip="Deactive" *ngIf="rowData.Status==false" src="../../../../assets//img/Info/closed.png" (click)="changeStatus(rowData,true)" />
        </td>
        <!--<td style="width:15px !important">
    {{rowData.RequestMethod}}
  </td>
  <td>
    {{rowData.RequestType ||0}}
  </td>-->


        <td class="rowEditCursor"  (click)="UpdateInspectionForm(rowData)" style="cursor:pointer" [pTooltip]="rowData.Id">
          {{rowData.Id }}
        </td>
        <td class="rowEditCursor"  (click)="UpdateInspectionForm(rowData)" style="cursor:pointer" [pTooltip]="rowData.Project">
          {{rowData.Project }}
        </td>
        <td  (click)="UpdateInspectionForm(rowData)" style="cursor:pointer" [pTooltip]="rowData.ProjectCode">
          {{rowData.ProjectCode }}
        </td>
        



        <td [pTooltip]="rowData.Activity">
          {{rowData.Activity }}
        </td>
        <td [pTooltip]="rowData.Task">
          {{rowData.Task }}
        </td>
        <td [pTooltip]="rowData.TemplateName">
          {{rowData.TemplateName ||0
          }}
        </td>
        <td [pTooltip]="rowData.TemplateType">
          {{rowData.TemplateType ||0}}
        </td>
        <!--<td>
    {{rowData.Finding ||0}}
  </td>-->
        <td [pTooltip]="rowData.Requestor">
          {{rowData.Requestor ||0}}
        </td>
        <!--<td>
    {{rowData.inspector ||0}}
  </td>-->
        <td [pTooltip]="rowData.SubProject">
          {{rowData.SubProject}}
        </td>
        <td [pTooltip]="rowData.CreatedDate">
          {{rowData.CreatedDate | date:'dd/MMM/yyyy H:m'}}
        </td>
        <td [pTooltip]="rowData.CreateBy">
          {{rowData.CreateBy}}
        </td>
        <td [pTooltip]="rowData.ModifiedDate">
          {{rowData.ModifiedDate | date:'dd/MMM/yyyy H:m'}}
        </td>
        <td [pTooltip]="rowData.ModifiBy">
          {{rowData.ModifiBy}}
        </td>
      </tr>
    </ng-template>
    <!--<button type="button" class="btn btn-primary" (click)="OpenAddForm()" style="margin-top: 35%;">Add</button>-->

  </p-table>

</div>

<style>
  .components {
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 25px;
    margin-top: 16px;
    font-weight: bold;
  }

  .back {
    background: white !important;
    height: 20px !important;
    padding: 1px 7px 7px 7px !important;
    font-size: 13px !important;
  }

  .app.combined .breadcrumb {
    padding: 0.5rem 1rem;
    display: none !important;
  }

  ._card {
    border: 0.5px solid #dedede;
    padding: 15px;
    border-radius: 4px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  }

  .head_txt {
    font-size: 12px;
    letter-spacing: 0.2px;
    line-height: 24px;
  }

  .form-control {
    display: block;
    width: 100%;
    height: 23px !important;
    padding: 3px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .top_head {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 5px;
    color: #000;
    letter-spacing: 0.5px;
  }

  .divRPimg {
    height: auto;
    padding: 10px 0;
    display: block;
    margin-right: 3%;
    border-radius: 3px;
    box-shadow: 0 4px 9px 0 rgba(67, 65, 79, 0.1);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
</style>
