import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {DropdownModule} from 'primeng/dropdown';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import {ButtonModule} from 'primeng/button';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { TooltipModule } from 'primeng/tooltip';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
//import { AgmCoreModule } from '@agm/core';
import { CalendarModule, DateAdapter } from 'angular-calendar';
// import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import * as tslib_1 from 'tslib';
import * as date_fns_2 from 'date-fns'; 
function adapterFactory() {
  return tslib_1.__assign(tslib_1.__assign({}),date_fns_2);
}
import { ToastModule } from 'primeng/toast';
import { ToastrModule } from 'ngx-toastr';
import { PipesModule } from './theme/pipes/pipes.module';

import { AppRoutingModule } from './app.routing';
import { AppSettings } from './app.settings';

import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
import { HeaderComponent } from './theme/components/header/header.component';
import { FooterComponent } from './theme/components/footer/footer.component';
import { SidebarComponent } from './theme/components/sidebar/sidebar.component';
import { VerticalMenuComponent } from './theme/components/menu/vertical-menu/vertical-menu.component';
import { HorizontalMenuComponent } from './theme/components/menu/horizontal-menu/horizontal-menu.component';
import { BreadcrumbComponent } from './theme/components/breadcrumb/breadcrumb.component';
import { BackTopComponent } from './theme/components/back-top/back-top.component';
import { FullScreenComponent } from './theme/components/fullscreen/fullscreen.component';
import { ApplicationsComponent } from './theme/components/applications/applications.component';
import { MessagesComponent } from './theme/components/messages/messages.component';
import { UserMenuComponent } from './theme/components/user-menu/user-menu.component';
import { FlagsMenuComponent } from './theme/components/flags-menu/flags-menu.component';
import { SideChatComponent } from './theme/components/side-chat/side-chat.component';
import { FavoritesComponent } from './theme/components/favorites/favorites.component';
import { BlankComponent } from './pages/blank/blank.component';
//import { SearchComponent } from './pages/search/search.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HomeComponent } from './pages/home/home.component';
import { PageHeadComponent } from './pages/page-head/page-head.component';
import { PageFooterComponent } from './pages/page-footer/page-footer.component';
import { PagelayoutComponent } from './pages/pagelayout/pagelayout.component';
import { HttpClientModule } from '@angular/common/http';
import { CommingsoonComponent } from './pages/commingsoon/commingsoon.component';
import { TableModule } from 'primeng/table';
import { TreeTableModule } from 'primeng/treetable';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ComponentComponent } from './pages/masters/component/component.component'
import { ActivitytypeComponent } from './pages/masters/activitytype/activitytype.component';
import { AddressCategoryComponent } from './pages/masters/address-category/address-category.component';
import { AddressTypeMasterComponent } from './pages/masters/address-type-master/address-type-master.component';
import { CampanyStatusMasterComponent } from './pages/masters/campany-status-master/campany-status-master.component';
import { CompanyTypeMasterComponent } from './pages/masters/company-type-master/company-type-master.component';
import { FindingMasterComponent } from './pages/masters/finding-master/finding-master.component';
import { FindingStatusMasterComponent } from './pages/masters/finding-status-master/finding-status-master.component';
import { ProjectStatusComponent } from './pages/masters/project-status/project-status.component';
import { RequestMethodMasterComponent } from './pages/masters/request-method-master/request-method-master.component';
import { RequestTypeMasterComponent } from './pages/masters/request-type-master/request-type-master.component';
import { TaskTypeMasterComponent } from './pages/masters/task-type-master/task-type-master.component';
import { WorkabilityTypeComponent } from './pages/masters/workability-type/workability-type.component';
import { ObjectiveManagementComponent } from './pages/masters/objective-management/objective-management.component'
import { ReportsComponent } from './pages/masters/reports/reports.component'
import { ReportMasterComponent } from './pages/report-master/report-master.component'
//import { ActivitiesComponent } from './pages/activities/activities.component';
//import { ActivitiesComponent } from './pages/activities/activities.component'
import { TabViewModule } from 'primeng/tabview';
import { AssignmentsComponent } from './pages/assignments/assignments.component';
import { PerformanceComponent } from './pages/performance/performance.component';
import { CategoryManagementComponent } from './pages/masters/category-management/category-management.component';
import { TemplateManagementComponent } from './pages/masters/template-management/template-management.component';
import { ReportingComponent } from './pages/reporting/reporting.component';
import { QualityAssuranceComponent } from './pages/quality-assurance/quality-assurance.component';
import { QualityAuditsComponent } from './pages/quality-audits/quality-audits.component';
import { ToolsComponent } from './pages/tools/tools.component';
import { FormsComponent } from './pages/forms/forms.component';
import { ReportsAndQueriesComponent } from './pages/reports-and-queries/reports-and-queries.component';
import { AdminPageComponent } from './pages/admin-page/admin-page.component'
import { GroupsComponent } from './pages/masters/groups/groups.component'
import { InspectionMasterComponent } from './pages/masters/inspection-master/inspection-master.component'
import { NewProjectComponent } from './pages/masters/new-project/new-project.component'
import { DatePipe } from '@angular/common';
@NgModule({  
  imports: [
    BrowserModule, TreeTableModule,
    NgxChartsModule,
    BrowserAnimationsModule,
    FormsModule,
    AutoCompleteModule,
    PerfectScrollbarModule,
    TooltipModule,
    NgbModule,
TabViewModule,
    DropdownModule,
    ButtonModule,
HttpClientModule,
    MultiselectDropdownModule,
    //AgmCoreModule.forRoot({
    //  apiKey: 'AIzaSyA1rF9bttCxRmsNdZYjW7FzIoyrul5jb-s'
    //}),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    ToastrModule.forRoot(), 
    PipesModule,
    AppRoutingModule, TableModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  declarations: [
    AppComponent,
    PagesComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    BreadcrumbComponent,
    BackTopComponent,
    FullScreenComponent,
    ApplicationsComponent,
    MessagesComponent,
    UserMenuComponent,
    FlagsMenuComponent,
    SideChatComponent,
    FavoritesComponent,
    BlankComponent,
    //SearchComponent,
    NotFoundComponent,
    HomeComponent,
    PageHeadComponent,
    PageFooterComponent,
    PagelayoutComponent, CommingsoonComponent,
    /*Master Table Modules*/
    ComponentComponent,
    ActivitytypeComponent,
    AddressCategoryComponent, AddressTypeMasterComponent, CampanyStatusMasterComponent, CompanyTypeMasterComponent, FindingMasterComponent, FindingStatusMasterComponent, ProjectStatusComponent, RequestMethodMasterComponent, RequestTypeMasterComponent, TaskTypeMasterComponent, WorkabilityTypeComponent,
    ReportsComponent,
    ReportMasterComponent,
    AssignmentsComponent, PerformanceComponent,
    ObjectiveManagementComponent, TemplateManagementComponent,
    CategoryManagementComponent,
    ReportingComponent,
    QualityAssuranceComponent,
    QualityAuditsComponent,
    ToolsComponent,
    FormsComponent,
    ReportsAndQueriesComponent,
    AdminPageComponent,
    GroupsComponent,
    InspectionMasterComponent,
    NewProjectComponent
    //ActivitiesComponent,
   // ActivitiesComponent
  ],
  providers: [ 
    AppSettings, DatePipe,
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { } 
