import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
//import { GeneralService } from '../../pages/Services/general.service';
import { GeneralService } from '../../Services/general.service'
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { formatDate } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { PrimeNGConfig } from 'primeng/api';
import { FilterMatchMode } from 'primeng/api';
import Swal from 'sweetalert2';
declare var $;
@Component({
  selector: 'app-component',
  templateUrl: './component.component.html',
  styleUrls: ['./component.component.scss'],
  providers: [DatePipe]
})
export class ComponentComponent implements OnInit {
  Componentdata: any;
  selected: any;
  ComponentName: any; 
  Objective: any;
  Status: any;
  LoginDetails: any;
  ModelTitle: string;
  templateoriginal: any;
  template: any;
  flag: any;
  Component: any;
  cols: any;
  ActivityTypes1: any;
  addNew: boolean;
  editForm: boolean;
  activitytype: any;
  Id: any;
  form: FormGroup;
  Grid: boolean = true;
  myDate = new Date();
  todaydate: any;
  constructor(public config: PrimeNGConfig,public router: Router,public toaster:ToastrService,private fb: FormBuilder, public generalService: GeneralService) {
    this.config.filterMatchModeOptions = {
      text: [
        FilterMatchMode.STARTS_WITH,
        FilterMatchMode.CONTAINS,
        FilterMatchMode.NOT_CONTAINS,
        FilterMatchMode.ENDS_WITH,
        FilterMatchMode.EQUALS,
        FilterMatchMode.NOT_EQUALS,
        FilterMatchMode.LESS_THAN,
        FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
        FilterMatchMode.GREATER_THAN,
        FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
      ],
      numeric: [
        FilterMatchMode.EQUALS,
        FilterMatchMode.NOT_EQUALS,
        FilterMatchMode.LESS_THAN,
        FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
        FilterMatchMode.GREATER_THAN,
        FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
      ],
      date: [
        FilterMatchMode.BEFORE,
        FilterMatchMode.AFTER,
      ]
    }
    this.todaydate = formatDate(this.myDate, 'yyyy-MM-dd hh:mm:ss a', 'en-US', '+0530');
    this.LoginDetails = JSON.parse(localStorage.getItem('LoginDetails'))    
    this.form = this.fb.group({
      ObjectiveValue: ['0']
    })
  }

  ngOnInit(): void {
    this.getActivityTypes();
    this.getObjectiveDetails();  

  }
  getActivityTypes() {
    
    var url = "api/Master/GetComponenteDetails";
    this.generalService.GetData(url).then((data: any) => {
      
      if (data != null) {
        var obj = Object.keys(data[0])
        this.cols = [
          { field: 'Id', header: 'Id', width: '150px' },
          { field: 'Status', header: 'Status', width: '150px' },
          { field: 'Component', header: 'Component', width: '150px' },
          { field: 'CreatedDate', header: 'CreatedDate', width: '150px' },
          { field: 'CreateBy', header: 'CreatedBy', width: '150px' },
          { field: 'ModifiedDate', header: 'ModifiedDate', width: '150px' },
          { field: 'ModifiBy', header: 'ModifiedBy', width: '150px' },
        ] 
        data.forEach(
          dateType => (dateType.CreatedDate = new Date(dateType.CreatedDate))
        );
        data.forEach(
          dateType => (dateType.ModifiedDate = new Date(dateType.ModifiedDate))
        );
        this.Componentdata = data;
        this.templateoriginal = data;
      }
    }, err => {
      // this.spinner.hide();
      this.generalService.ShowAlert('ERROR', 'Something Went wrong', 'error');
    });
  }

  getObjectiveDetails() {

    var url = "api/Master/GetObjectiveDetails";
    this.generalService.GetData(url).then((data: any) => {
      console.log(data);
      if (data != null) {
        var obj = Object.keys(data[0])       
        this.Objective = data;
      }
    }, err => {
      // this.spinner.hide();
      this.generalService.ShowAlert('ERROR', 'Something Went wrong', 'error');
    });
  }

  OpenAddForm() {

    this.ModelTitle = "Add Component";
    $('#myModal').modal('show');
    this.Grid = false;
    this.Component = "";
    this.flag = "1";//for adding
    //this.addNew = true;
    //this.editForm = false;

  }
  //=======print====//
  onPrint() {
    window.print();
  }

  TextBoxChange(item, key) {
    debugger
    let index: number = this.Componentdata.findIndex(t => t.Id == item.Id);
    let index1: number = this.templateoriginal.findIndex(t => t.Id == item.Id);
    if (index > -1) {
      this.Componentdata[index][key] = item[key];
      this.Componentdata = this.Componentdata[index].ActivityType;
      this.Id = item.Id;
    }
    if (index1 > -1) {
      this.templateoriginal[index][key] = item[key];
      this.templateoriginal[index]['ModifiedBy'] = this.LoginDetails.logindetails.Id;
      this.templateoriginal[index]['ModifiedDate'] = this.todaydate;
    }
    // this.onRowUpadte();
    this.flag = "2"//for Update        
    var UploadFile = new FormData();
    UploadFile.append("Component", JSON.stringify(this.templateoriginal));
    UploadFile.append("flag", this.flag);
    var url = 'api/Master/UpdateDeleteComponent';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data != null) {
        this.getActivityTypes();
        this.toaster.success('Component has been updated successfully', 'Success', {
          timeOut: 2000,
        });
        this.backform();
      }
    });
  }


 

  onDelete(rowData) {
    Swal.fire({
      title: 'Are you sure to Delete  ?',
      //text: "",
      //type: 'warning',
      html: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      debugger
      if (result.value) {
        this.flag = "3";
        let Array = [];
        Array.push({
          Component: rowData.Component, Id: rowData.Id,
        })
        var UploadFile = new FormData();
        UploadFile.append("Component", JSON.stringify(Array));
        UploadFile.append("flag", this.flag);
        var url = 'api/Master/UpdateDeleteComponent';
        this.generalService.PostData(url, UploadFile).then((data: any) => {

          if (data == "success") {
            this.getActivityTypes();
            this.generalService.ShowAlert('SUCCESS', 'Component has been Deleted successfully', 'success');
            this.backform();
          }
          else {
            this.generalService.ShowAlert('WARNING', 'You Can not Delete this record because of it is having relation with anather table', 'warning');
            this.backform();
          }
        });
      }
    })
  }
  changeStatus(rowData, status) {
    this.Status = status;

    this.flag = "4";
    
    let Array = [];
    Array.push({
      Id: rowData.Id, Status: this.Status, ModifiedBy: this.LoginDetails.logindetails.Id, ModifiedDate: this.todaydate
    })
    var UploadFile = new FormData();
    UploadFile.append("Component", JSON.stringify(Array));
    UploadFile.append("flag", this.flag);
    var url = 'api/Master/UpdateDeleteComponent';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      
      if (data != null) {
        this.getActivityTypes();
        if (this.Status == true) {
          this.toaster.success('Status Activated Successfully', 'Success', {
            timeOut: 2000,
          });
        }
        else {
          this.toaster.warning('Status Dectivated Successfully', 'Success', {
            timeOut: 2000,
          });
        }
      }
    });
  }

  backform() {
    this.Grid = true;
  }
  refresh() {
    location.reload();
  }

  AddComponent(selected, ComponentName) {
    debugger
      var arr = {
        Component: ComponentName,
        ObjectiveId: selected,
        CreatedBy: this.LoginDetails.logindetails.Id
      }
     
    this.flag = '1';
    var UploadFile = new FormData();
    UploadFile.append("Component", JSON.stringify(arr));
    UploadFile.append("flag", this.flag);
    var url = 'api/Master/UpdateDeleteComponent';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data != null) {
        $('#myModal').modal('hide');
        this.getActivityTypes();
        this.toaster.success('Component has been added successfully', 'Success', {
          timeOut: 2000,
        });
        this.backform();
      }
    });
  }
}
