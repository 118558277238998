///// <reference path="../services/general.service.ts" />
import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../Services/general.service'


import { Menu } from '../../theme/components/menu/menu.model';
import { MenuService } from '../../theme/components/menu/menu.service';

import { Settings } from '../../app.settings.model';
import { AppSettings } from '../../app.settings';
import { ToastrService, GlobalConfig } from 'ngx-toastr';

@Component({
  selector: 'app-performance',
  templateUrl: './performance.component.html',
  styleUrls: ['./performance.component.scss'],
  providers: [MenuService]
})
export class PerformanceComponent implements OnInit {
  PinId: any;
  MenuId: any;
  homeInspectorLength: any;

  homeInspector: any;
  completedInspector: any;
  returnedInspector: any;
  cols: any;
  cols1: any;
  cols2: any;
  arr: any;
 
  public menus: Array<Menu>;
  public settings: Settings;
  LoginDetails: any;
  Pinned: boolean;
  options: GlobalConfig;
  constructor(private generalService: GeneralService,
    public appSettings: AppSettings, public menuService: MenuService,
    public toastrService: ToastrService) {
    this.options = this.toastrService.toastrConfig;
    debugger
   
    this.LoginDetails = JSON.parse(localStorage.getItem('LoginDetails'));
    this.settings = this.appSettings.settings;
    this.MenuId = localStorage.getItem('MenuId')
    this.AddRecents();

    this.GetPinned();


    
    
  }

  ngOnInit(): void {
    //var SMenus = this.generalService.MainSideMenusAry
    //debugger
    //this.menuService.RefreshMenu(SMenus, this.settings.theme.menu);;
    //debugger
    this.cols = [
      { field: 'Id', header: 'Name', width: '50px' },
      { field: 'CreatedDate', header: 'Start Date', width: '70px' },
      { field: 'EndDate', header: 'Finish Date', width: '80px' },
      { field: 'EarliestStartDate', header: 'Earliest Start Date', width: '80px' },
      { field: 'EstimatedHours', header: 'Effort (Hours)', width: '70px' },
      { field: 'SpentHours', header: 'Effort Completed (Hours)', width: '80px' },
      { field: 'PercentageOfCompletion', header: '% Complete', width: '60px' },
    ]
    this.cols1 = [
      { field: 'Id', header: 'Name', width: '50px' },
      { field: 'CreatedDate', header: 'Start Date', width: '70px' },
      { field: 'EndDate', header: 'Finish Date', width: '80px' },
      { field: 'EarliestStartDate', header: 'Earliest Start Date', width: '80px' },
      { field: 'EstimatedHours', header: 'Effort (Hours)', width: '70px' },
      { field: 'SpentHours', header: 'Effort Completed (Hours)', width: '80px' },
      { field: 'PercentageOfCompletion', header: '% Complete', width: '60px' },
    ]
    this.cols2 = [
      { field: 'Id', header: 'Name', width: '50px' },
      { field: 'CreatedDate', header: 'Start Date', width: '70px' },
      { field: 'EndDate', header: 'Finish Date', width: '80px' },
      { field: 'EarliestStartDate', header: 'Earliest Start Date', width: '80px' },
      { field: 'EstimatedHours', header: 'Effort (Hours)', width: '70px' },
      { field: 'SpentHours', header: 'Effort Completed (Hours)', width: '80px' },
      { field: 'PercentageOfCompletion', header: '% Complete', width: '60px' },
    ]
    this.getHomeInspector();
    this.getCompletedInspector();
    this.getReturnedInspector();
  }
  getHomeInspector() {

    var UploadFile = new FormData();
    UploadFile.append('StartDate', '');
    UploadFile.append('EndDate', '');
    UploadFile.append('InspectorId', this.LoginDetails.logindetails.Id);
    UploadFile.append('Flag', '1');
    var url = 'api/Values/GetInspectorInspections';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      this.homeInspector = data;
      this.homeInspector.forEach(hrs => (hrs.SpentHours = (hrs.SpentHours / 60).toFixed(2)));
      this.homeInspector.forEach(hrs => (hrs.EstimatedHours = (hrs.EstimatedHours / 60).toFixed(2)));
      debugger
      this.homeInspectorLength = this.homeInspector.length;
    });
  }
  getCompletedInspector() {

    var UploadFile = new FormData();
    UploadFile.append('StartDate', '');
    UploadFile.append('EndDate', '');
    UploadFile.append('InspectorId', this.LoginDetails.logindetails.Id);
    UploadFile.append('Flag', '2');
    var url = 'api/Values/GetInspectorInspections';
    this.generalService.PostData(url, UploadFile).then((data: any) => {

      this.completedInspector = data;
      this.completedInspector.forEach(hrs => (hrs.SpentHours = (hrs.SpentHours / 60).toFixed(2)));
      this.completedInspector.forEach(hrs => (hrs.EstimatedHours = (hrs.EstimatedHours / 60).toFixed(2)));
    });
  }
  getReturnedInspector() {

    var UploadFile = new FormData();
    UploadFile.append('StartDate', '');
    UploadFile.append('EndDate', '');
    UploadFile.append('InspectorId', this.LoginDetails.logindetails.Id);
    UploadFile.append('Flag', '3');
    var url = 'api/Values/GetInspectorInspections';
    this.generalService.PostData(url, UploadFile).then((data: any) => {

      this.returnedInspector = data;
      this.returnedInspector.forEach(hrs => (hrs.SpentHours = (hrs.SpentHours / 60).toFixed(2)));
      this.returnedInspector.forEach(hrs => (hrs.EstimatedHours = (hrs.EstimatedHours / 60).toFixed(2)));
    });
  }




  //===========================For Recent and pinned================//

  AddRecents() {
    debugger
    let Array = [];
    Array.push({
      MenuId: this.MenuId, UserId: this.LoginDetails.logindetails.Id
    })
    var UploadFile = new FormData();
    UploadFile.append("Recent", JSON.stringify(Array));
    var url = 'api/Values/AddRecent';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data == "SUCCESS") {
        debugger
        var UploadFile = new FormData();
        UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
        UploadFile.append("flag", '1');
        var url = 'api/Values/GetSideMenusWithRecentPinned';
        this.generalService.PostData(url, UploadFile).then((data1: any) => {
          debugger
          var Details = this.generalService.SIdeMenus(data1);
          this.menuService.RefreshMenu(Details, this.settings.theme.menu);
          debugger
        });
      }

    });
  }


  Pined() {
    debugger
    this.Pinned = true;

    let Array = [];
    Array.push({
      MenuId: this.MenuId, UserId: this.LoginDetails.logindetails.Id
    })
    var UploadFile = new FormData();
    UploadFile.append("Pinned", JSON.stringify(Array));
    var url = 'api/Values/AddPinned';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data == "SUCCESS") {
        debugger
        this.GetPinned();
        this.toastrService.clear();
        this.toastrService.success('Pinned..!', '');
        var UploadFile = new FormData();
        UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
        //UploadFile.append("flag", '1');
        var url = 'api/Values/GetSideMenusWithRecentPinned';
        this.generalService.PostData(url, UploadFile).then((data1: any) => {
          debugger
          var Details = this.generalService.SIdeMenus(data1);
          this.menuService.RefreshMenu(Details, this.settings.theme.menu);
          debugger
        });

      }

    });
  }


  UnPin() {
    debugger
    this.Pinned = false;
    var UploadFile = new FormData();
    UploadFile.append("PinId", this.PinId);
    UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
    var url = 'api/Values/DeletePinned';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data == "SUCCESS") {
        debugger
        this.toastrService.clear();
        this.toastrService.info('Un Pinned..!', '');
        var UploadFile = new FormData();
        UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
        var url = 'api/Values/GetSideMenusWithRecentPinned';
        this.generalService.PostData(url, UploadFile).then((data1: any) => {
          debugger
          var Details = this.generalService.SIdeMenus(data1);
          this.menuService.RefreshMenu(Details, this.settings.theme.menu);
          debugger
        });
      }
    });
  }

  GetPinned() {
    debugger
    var UploadFile = new FormData();
    UploadFile.append("MenuId", this.MenuId);
    UploadFile.append("UserId", this.LoginDetails.logindetails.Id);
    UploadFile.append("RouterLink", '');
    var url = 'api/Values/GetPinned';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data.MSG == "EXISTS") {
        debugger
        this.PinId = data.PinId;
        this.Pinned = true;
      }
      else if (data.MSG == "NOT EXISTS") {
        this.Pinned = false;
      }
    });
  }


  //===========================For Recent and pinned ends================//

}
