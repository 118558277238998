

<nav class="navbar box-shadow app-header" [class.fixed-top]="settings.theme.navbarIsFixed" [class.logo-visible]="showInfoContent">

  <div class="d-flex flex-column w-100">
    <div id="info-content" class="panel-collapse collapse">     
      <!--<a class="close" data-toggle="collapse" href="#info-content" (click)="showInfoContent = !showInfoContent" [class.show]="showInfoContent">
          <span aria-hidden="true">&times;</span>
      </a>-->
      <div class="row align-items-center">
        <!--<div class="col-md-5">
            <div class="d-flex justify-content-center align-items-center pt-md-0 pt-3 info">
                <img src="assets/img/users/user.jpg" class="rounded-circle user-img">
                <div class="d-flex flex-column pl-3">
                    <h5 class="mb-0">Emilio Verdines <i class="fa fa-check-circle fs-13 text-success ml-2"></i></h5>
                    <span>Project menecer</span>
                    <div class="pt-3">
                        <a routerLink="/profile" class="btn btn-success mr-1">Profile</a>
                        <a routerLink="/profile/user-info" class="btn btn-gray">Edit</a>
                    </div>
                </div>
            </div>
        </div>-->
        <div class="col-md-7"> 
          <div class="row py-4">





            <!--<div class="col-lg-7 col-sm-8 col-12 left-border">
                <div class="info w-280p mx-auto text-center">
                    <form>
                        <div class="form-group">
                            <input type="text" class="form-control form-control-sm" placeholder="Recipients">
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control form-control-sm" placeholder="Subject">
                        </div>
                        <div class="form-group">
                            <textarea class="form-control form-control-sm" placeholder="Message" rows="3"></textarea>
                        </div>
                        <button type="submit" class="btn btn-sm btn-gray"><i class="fa fa-paper-plane mr-2"></i>Send</button>
                    </form>
                </div>
            </div>-->
            <!--<div class="col-lg-5 col-sm-4 left-border d-none d-sm-block">
                <div class="info w-100p mx-auto">
                    <div class="custom-control custom-checkbox mb-1">
                        <input type="checkbox" class="custom-control-input" id="checkboxNotifications" checked>
                        <label class="custom-control-label" for="checkboxNotifications">Notifications</label>
                    </div>
                    <div class="custom-control custom-checkbox mb-1">
                        <input type="checkbox" class="custom-control-input" id="checkboxTasks" checked>
                        <label class="custom-control-label" for="checkboxTasks">Tasks</label>
                    </div>
                    <div class="custom-control custom-checkbox mb-1">
                        <input type="checkbox" class="custom-control-input" id="checkboxEvents">
                        <label class="custom-control-label" for="checkboxEvents">Events</label>
                    </div>
                    <div class="custom-control custom-checkbox mb-1">
                        <input type="checkbox" class="custom-control-input" id="checkboxDownloads" checked>
                        <label class="custom-control-label" for="checkboxDownloads">Downloads</label>
                    </div>
                    <div class="custom-control custom-checkbox mb-1">
                        <input type="checkbox" class="custom-control-input" id="checkboxMessages" checked>
                        <label class="custom-control-label" for="checkboxMessages">Messages </label>
                    </div>
                    <div class="custom-control custom-checkbox mb-1">
                        <input type="checkbox" class="custom-control-input" id="checkboxUpdates">
                        <label class="custom-control-label" for="checkboxUpdates">Updates</label>
                    </div>
                    <div class="custom-control custom-checkbox mb-1">
                        <input type="checkbox" class="custom-control-input" id="checkboxSettings" checked>
                        <label class="custom-control-label" for="checkboxSettings">Settings</label>
                    </div>
                </div>
            </div>-->
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-start align-items-center top-navbar">
      <a class="logo">
        <img src="../../../../assets/img/icons/OriginalTransparent.png" style="    width: 100% !important;" />
        </a>
        <!--<a class="logo">
        <span class="start">i2i </span><span class="ng" style="color: #ffffff;
    font-size: 18px;
    letter-spacing: 2px;">CMP </span>
      </a>-->

        <span class="navbar-text menu-icon transition ml-3 mr-3" [ngClass]="{'open': settings.theme.showMenu}"
              (click)="settings.theme.showMenu = !settings.theme.showMenu">
          <i class="fa fa-bars" style="color:#fff !important" aria-hidden="true"></i>
          <!--<div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>-->
        </span>
        <span class="d-none d-sm-inline-block">
          <app-applications></app-applications>
        </span>
        <ol class="breadcrumb" *ngIf="router.url != '/'">

          <li *ngIf="router.url != '/'" class="breadcrumb-item">
            <a *ngIf="RoleId==8" routerLink="/Admin/Main" (click)="closeSubMenus()"><i class="fa fa-home mr-2"></i></a>
            <a *ngIf="RoleId==3" routerLink="/Inspection/HomeInspector" (click)="closeSubMenus()"><i class="fa fa-home mr-2"></i></a>
            <a *ngIf="RoleId==5" routerLink="/Inspection/HomeModerator" (click)="closeSubMenus()"><i class="fa fa-home mr-2"></i></a>
          </li>
          <li *ngFor="let breadcrumb of breadcrumbs; let i = index;" class="breadcrumb-item">
            <a [hidden]="i == (breadcrumbs.length - 1)" [routerLink]="[breadcrumb.url]"> {{breadcrumb.name}}</a>
            <span [hidden]="i != (breadcrumbs.length - 1)"><i *ngIf="router.url == '/'" class="fa fa-home mr-2"></i><b> {{breadcrumb.name}} >> {{ID}}</b></span>
          </li>
        </ol>

        <!--<div class="ml-2 mr-2 d-none d-sm-block">
         <a id="info-content-icon" data-toggle="collapse" href="#info-content" (click)="showInfoContent = !showInfoContent" class="d-flex" [class.show]="showInfoContent">
            <div [@showInfo]="showInfoContent" class="triangle"></div>
        </a>
    </div>-->
        <!--<app-favorites class="d-none d-lg-inline-block"></app-favorites>-->

        <div class="top-rigth-icons ml-auto">
          <!--<span class="d-none d-sm-inline-block">
          <app-flags-menu></app-flags-menu>
      </span>-->
          <!--<ol class="breadcrumb" *ngIf="router.url != '/'">
        <li *ngIf="router.url != '/'" class="breadcrumb-item">
          <a routerLink="/" (click)="closeSubMenus()"><i class="fa fa-home mr-2"></i>Home</a>
        </li>
        <li *ngFor="let breadcrumb of breadcrumbs; let i = index;" class="breadcrumb-item">
          <a [hidden]="i == (breadcrumbs.length - 1)" [routerLink]="[breadcrumb.url]">{{breadcrumb.name}}</a>
          <span [hidden]="i != (breadcrumbs.length - 1)"><i *ngIf="router.url == '/'" class="fa fa-home mr-2"></i><b>{{breadcrumb.name}}</b></span>
        </li>
      </ol>-->
          <form class="app-search d-none d-md-inline-block ml-3">

            <i class="fa fa-search"></i>
            <input type="text" placeholder="Type to search..." class="form-control">
          </form>
          <span class="d-none d-sm-inline-block">
            <app-fullscreen></app-fullscreen>
          </span>
          <span class="d-none d-sm-inline-block">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 width="19" height="19"
                 viewBox="0 0 172 172"
                 style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#ffffff"><path d="M86,15.0472l-78.83333,70.9528h21.5v64.5h50.16667v-43h14.33333v43h50.16667v-64.5h21.5zM86,34.33561l43,38.7028v5.79492v57.33333h-21.5v-43h-43v43h-21.5v-63.12826z"></path></g></g></svg>
          </span>
          <!--<span class="d-none d-sm-inline-block">
        <app-applications></app-applications>
      </span>-->
          <span class="d-none d-sm-inline-block">
            <app-messages></app-messages>
          </span>
          <span class="d-none d-sm-inline-block">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 width="19" height="19"
                 viewBox="0 0 172 172"
                 style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#ffffff"><path d="M86,14.33333c-39.49552,0 -71.66667,32.17115 -71.66667,71.66667c0,39.49552 32.17115,71.66667 71.66667,71.66667c39.49552,0 71.66667,-32.17115 71.66667,-71.66667c0,-39.49552 -32.17115,-71.66667 -71.66667,-71.66667zM86,28.66667c31.74921,0 57.33333,25.58412 57.33333,57.33333c0,31.74921 -25.58412,57.33333 -57.33333,57.33333c-31.74921,0 -57.33333,-25.58412 -57.33333,-57.33333c0,-31.74921 25.58412,-57.33333 57.33333,-57.33333zM78.83333,50.16667v14.33333h14.33333v-14.33333zM78.83333,78.83333v43h14.33333v-43z"></path></g></g></svg>
          </span>

          <span class="d-none d-sm-inline-block" style="padding-left: 0.4rem;">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 width="19" height="19"
                 viewBox="0 0 172 172"
                 style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#ffffff"><path d="M86,14.33333c-39.49552,0 -71.66667,32.17115 -71.66667,71.66667c0,39.49552 32.17115,71.66667 71.66667,71.66667c39.49552,0 71.66667,-32.17115 71.66667,-71.66667c0,-39.49552 -32.17115,-71.66667 -71.66667,-71.66667zM86,28.66667c31.74921,0 57.33333,25.58412 57.33333,57.33333c0,31.74921 -25.58412,57.33333 -57.33333,57.33333c-31.74921,0 -57.33333,-25.58412 -57.33333,-57.33333c0,-31.74921 25.58412,-57.33333 57.33333,-57.33333zM86,43c-15.83833,0 -28.66667,12.82833 -28.66667,28.66667h14.33333c0,-7.88333 6.45,-14.33333 14.33333,-14.33333c7.88333,0 14.33333,6.45 14.33333,14.33333c0,14.33333 -21.5,16.9635 -21.5,35.83333h14.33333c0,-11.83217 21.5,-17.91667 21.5,-35.83333c0,-15.83833 -12.82833,-28.66667 -28.66667,-28.66667zM78.83333,114.66667v14.33333h14.33333v-14.33333z"></path></g></g></svg>
          </span>
          <span class="d-none d-sm-inline-block" style="padding-left: 0.4rem;">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 width="19" height="19"
                 viewBox="0 0 172 172"
                 style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#ffffff"><path d="M150.34603,-0.05599c-0.24809,0.00581 -0.49571,0.0245 -0.74186,0.05599c-1.97538,0.24425 -3.76023,1.29893 -4.92708,2.91146c0,0 -34.19097,47.25521 -87.34375,47.25521h-44.34375c-7.16667,0 -12.98958,5.82292 -12.98958,12.98958v31.35417c0,7.16667 5.82292,12.98958 12.98958,12.98958h44.34375c53.15278,0 87.34375,47.25521 87.34375,47.25521c1.82325,2.53999 5.0826,3.60501 8.05386,2.63167c2.97126,-0.97334 4.96872,-3.76043 4.93572,-6.88688v-57.33333c7.88333,0 14.33333,-6.45 14.33333,-14.33333c0,-7.88333 -6.45,-14.33333 -14.33333,-14.33333v-57.33333c0.01538,-1.93733 -0.75417,-3.79837 -2.13331,-5.15905c-1.37914,-1.36068 -3.25038,-2.10509 -5.18732,-2.06361zM143.33333,25.53125v106.60417c-14.67396,-14.82549 -41.3737,-36.27389 -78.83333,-38.74479v-29.11458c37.45963,-2.4709 64.15937,-23.9193 78.83333,-38.74479zM14.33333,114.66667v7.16667c8.6,17.2 14.33333,35.02708 14.33333,40.76042c0,5.01667 4.38958,9.40625 9.40625,9.40625h17.02083c7.88333,0 9.45104,-6.53958 8.73438,-9.40625c0,-3.58333 -2.15,-8.51042 -7.16667,-12.09375c-17.2,-12.18333 -16.57292,-35.83333 -16.57292,-35.83333z"></path></g></g></svg>
          </span>
          <!--<span class="d-none d-sm-inline-block pl-2 pr-2" (click)="settings.theme.showSideChat = !settings.theme.showSideChat">
          <i class="fa fa-commenting-o" aria-hidden="true"></i>
      </span>-->

          <span class="d-none d-sm-inline-block" style="color:#fff;">
            <app-user-menu></app-user-menu>{{LoggedinUserName}}
            ({{RoleName}})
          </span>
          <span class="pl-2 pr-2">
            <a routerLink="/login">
              <i class="fa fa-sign-out" style="color:#fff;" aria-hidden="true"></i>
            </a>
          </span>
        </div>

    </div>

    <div class="horizontal-menu-outer" *ngIf="settings.theme.menu == 'horizontal'">

        <app-horizontal-menu *ngIf="showHorizontalMenu"  [menuItems]="menuItems"></app-horizontal-menu>
        <app-vertical-menu *ngIf="!showHorizontalMenu"  [menuItems]="menuItems"></app-vertical-menu>
    </div>

  </div>
</nav>



<style>
  

</style>
